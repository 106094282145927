import { Center } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { ColorBox } from '$/components/core/Editor/ColorSelectionSidebar/ColorBox';
import { Material } from '$/services/usecases/materials';

interface Props {
  material: Material | null;
  boxSize?: string;
}

export const ColorLayerImage: FC<Props> = ({ material, boxSize = '16px' }) => {
  if (material === null) {
    return (
      <Center
        layerStyle='ColorImage'
        boxSize={boxSize}
        minW={boxSize}
        border='1px solid'
        borderColor='border'
        borderRadius='2px'
        aspectRatio={1}
      >
        <Icon icon='subtract' width='60%' />
      </Center>
    );
  }

  return (
    <ColorBox
      layerStyle='ColorImage'
      materialColor={material.color}
      image={material.url({ width: '30', height: '30' })}
      border='1px solid'
      borderColor='border'
      borderRadius='2px'
      boxSize={boxSize}
    />
  );
};
