import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    background: 'background',
    zIndex: 1000,
    boxShadow: 'none',
    borderColor: 'border',
  },
  item: {
    background: 'background',
    _hover: {
      background: 'bodyBackground',
    },
    _active: {
      background: 'secondaryBackground',
    },
  },
});

export const Menu = defineMultiStyleConfig({ baseStyle });
