import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { ProjectOverviewItem } from '$/services/usecases/projects/mapper/editorProject';

type ProjectModalMode = 'deleteProject' | 'renameProject';

type ProjectModalProps = {
  isOpen: boolean;
  modalMode: ProjectModalMode;
  project: ProjectOverviewItem | null;
  onClose: () => void;
  onOpen: (mode: ProjectModalMode, project: ProjectOverviewItem) => void;
};

const store = create<ProjectModalProps>()((set) => ({
  isOpen: false,
  modalMode: 'deleteProject',
  project: null,
  onClose: () => {
    set({ isOpen: false });
  },
  onOpen: (mode, project) => set({ modalMode: mode, isOpen: true, project }),
}));

export const useProjectModal = createSelectorHooks(store);
