import { Button, Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Icon } from '$/components/common/Icon';
import { AttributeHeader } from '$/components/core/Editor/AttributeHeader';
import { useMaterialFullscreenStore } from '$/components/core/Editor/MaterialFullscreen/store/useMaterialFullscreenStore';
import { MaterialType } from '$/services/mapper/uses';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  initialMode: MaterialType;
}

export const FilterSettings: FC<Props> = ({ initialMode }) => {
  const { t } = useTranslation();

  const resetFilter = useCollectionStore.useResetFilter();

  const { filterCount } = useCollectionStore(
    useShallow((s) => ({ filterCount: s.filterGroups.length })),
  );

  const openFullscreenModal = () => {
    useMaterialFullscreenStore.setState({
      modalType: 'material',
      modalPayload: { materialType: initialMode },
      modalFrom: 'overview',
      isModalOpen: true,
    });
  };

  return (
    <Stack gap='3'>
      <AttributeHeader
        label={t('inspirationEditor.attributes.filterSettings')}
        icon={<Icon w='14px' icon='filter' />}
        description='MISSING TRANSLATION'
      />

      <Stack>
        <Button
          h='8'
          fontSize='xs'
          fontWeight='normal'
          borderRadius='4px'
          onClick={openFullscreenModal}
          variant='black'
        >
          {t('general_editor.searchCollections')}
        </Button>

        <Button
          h='8'
          fontSize='xs'
          fontWeight='normal'
          borderRadius='4px'
          isDisabled={filterCount === 0}
          onClick={resetFilter}
          variant='secondary'
        >
          {t('general_editor.resetFilter')}
        </Button>
      </Stack>
    </Stack>
  );
};
