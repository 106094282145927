import { HStack, Skeleton, Stack } from '@chakra-ui/react';
import { FC } from 'react';

import { randomPlaceholder } from '$/utils/stringUtils';

interface Props {
  id: number;
}

export const MaterialListItemSkeleton: FC<Props> = ({ id }) => {
  return (
    <HStack>
      <Skeleton
        w='24px'
        h='24px'
        border='1px solid'
        borderColor='border'
        borderRadius='4px'
      />
      <Stack gap='2px'>
        <Skeleton w='fit-content' fontSize='sm' lineHeight='1.2'>
          {randomPlaceholder(id, 5, 10)}
        </Skeleton>
        <Skeleton fontSize='10px' lineHeight='1.2'>
          {randomPlaceholder(id, 10, 16)}
        </Skeleton>
      </Stack>
    </HStack>
  );
};
