import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { AppInsightsProvider } from '$/components/core/ApplicationInsights/AppInsightsProvider';
import { Fonts } from '$/components/core/Fonts';
import { LocalizationProvider } from '$/components/core/Localization/LocalizationProvider';
import { enablePiwik } from '$/logger';
import { router } from '$/routes';
import { queryClient } from '$/services/fetcher';
import { theme, config } from '$/theme';

import 'overlayscrollbars/overlayscrollbars.css';
import './theme/styles.css';

enablePiwik();

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <ColorModeScript initialColorMode={config.initialColorMode} />
    <AppInsightsProvider>
      <LocalizationProvider>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Fonts />
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ChakraProvider>
      </LocalizationProvider>
    </AppInsightsProvider>
  </StrictMode>,
);
