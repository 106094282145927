import {
  Button,
  Grid,
  HStack,
  VStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedSection } from '$/components/common/TrackedSection';
import { ColorBox } from '$/components/core/Collection/MaterialDetailsModal/components/ColorBox';
import { ColorBoxPreview } from '$/components/core/Collection/MaterialDetailsModal/components/ColorBoxPreview';
import { InspirationBox } from '$/components/core/Collection/MaterialDetailsModal/components/InspirationBox';
import { PreviewCard } from '$/components/core/Collection/MaterialDetailsModal/components/PreviewCard';
import { useMaterialDetailStore } from '$/components/core/Collection/MaterialDetailsModal/store/useMaterialDetailStore';
import { TypedToOptions } from '$/components/core/StyledLink';
import { useIsMobile } from '$/hooks/useIsMobile';
import { useTrackInteraction } from '$/hooks/useTrackInteraction';
import { trackContentImpression } from '$/logger';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { useMaterialStore } from '$/stores/useMaterialStore';
import { usePiwikStore } from '$/stores/usePiwikStore';

export const MaterialDetailsModal = () => {
  const { t } = useTranslation();

  const setActiveMaterial = useMaterialStore.useSetActiveMaterial();
  const id = useMaterialDetailStore.useId();
  const clearCollection = useMaterialDetailStore.useClearActive();
  const setDesign = usePiwikStore.useSetDesign();

  const modalSize = useBreakpointValue(
    {
      base: 'full',
      mobile: 'full',
      sm: 'xl',
    },
    { ssr: false },
  );
  const isMobile = useIsMobile();

  const { isOpen, onClose } = useDisclosure({ isOpen: id !== null });
  const [activePreview, setActivePreview] = useState(0);

  const { data } = useQuery(materialsQuery({ ids: id ? [id] : undefined }));

  const trackPreviewImpression = (previewIndex: number) => {
    if (previewIndex === 0) {
      trackContentImpression('CollectionOverlay', 'Preview', 'DesignMaterial');
    } else {
      trackContentImpression('CollectionOverlay', 'Preview', {
        roomSourceIndex: previewIndex,
      });
    }
  };

  const trackCloseButton = useTrackInteraction(
    'CollectionOverlay',
    'Buttons',
    'Close',
  );

  useEffect(() => {
    if (isOpen) {
      trackPreviewImpression(activePreview);
    }
  }, [isOpen, activePreview]);

  if (!data?.materials[0]) return null;

  const material = data.materials[0];

  const inspirations = material.type.includes('facade')
    ? [
        { label: 'Detail', id: '116973' },
        { label: 'Inspiration 1', id: '116913' },
        { label: 'Inspiration 2', id: '116893' },
      ]
    : [
        { label: 'Detail', id: '97622' },
        { label: 'Inspiration 1', id: '41622' },
        { label: 'Inspiration 2', id: '79672' },
      ];

  const handlePiwikDesignState = () => {
    setDesign(
      location.pathname as TypedToOptions,
      material.type[0],
      material.info,
    );
  };

  const handleApplyInspiration = () => {
    setActiveMaterial(material.id);
    handlePiwikDesignState();
    clearCollection();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={clearCollection}
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent
        overflowY={isMobile ? 'auto' : 'initial'}
        w={isMobile ? '100vw' : '35rem'}
        maxW={isMobile ? '100vw' : '35rem'}
        h={isMobile ? '100vh' : 'auto'}
        maxH={isMobile ? '100vh' : 'auto'}
        m={isMobile ? '0' : 'auto'}
        borderRadius={isMobile ? '0' : 'md'}
      >
        <ModalHeader gap='2' display='grid' mx='5' mt='5' mb='3'>
          <Text color='neutralText' fontSize='md' textTransform='uppercase'>
            {material.producer}
          </Text>
          <Text fontSize='3xl'>{material.info}</Text>
        </ModalHeader>
        <ModalCloseButton
          m='8'
          onClick={() => {
            trackCloseButton();
            clearCollection();
          }}
        />
        <ModalBody>
          <Grid gap='8' p='5'>
            {activePreview === 0 && (
              <ColorBox material={material} borderRadius='20px' />
            )}

            {activePreview > 0 && (
              <TrackedSection
                contentInteraction='ClickPreview'
                contentName='CollectionOverlay'
                contentPiece={{ roomSourceIndex: activePreview }}
                contentTarget={{
                  uri: `${window.location.protocol}//${window.location.host}/inspirations/${inspirations[activePreview - 1].id}`,
                }}
              >
                <InspirationBox
                  id={inspirations[activePreview - 1].id}
                  materialId={material.id}
                  borderRadius='20px'
                  onButtonClickAction={handlePiwikDesignState}
                  showColorizeButton
                />
              </TrackedSection>
            )}

            <Grid
              gap='4'
              templateColumns={isMobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'}
            >
              <TrackedSection
                contentInteraction='ClickImage'
                contentName='CollectionOverlay'
                contentPiece='ImageRow'
                contentTarget='DesignMaterial'
              >
                <PreviewCard
                  active={activePreview === 0}
                  onClick={() => setActivePreview(0)}
                >
                  <ColorBoxPreview
                    boxColor={material.color}
                    image={material.url()}
                    borderRadius='4px'
                  />
                </PreviewCard>
              </TrackedSection>

              {inspirations.map(({ id, label }, i) => (
                <TrackedSection
                  key={id}
                  contentInteraction='ClickImage'
                  contentName='CollectionOverlay'
                  contentPiece='ImageRow'
                  contentTarget={{ roomSourceIndex: i + 1 }}
                >
                  <PreviewCard
                    label={label}
                    active={activePreview === i + 1}
                    onClick={() => setActivePreview(i + 1)}
                  >
                    <InspirationBox
                      id={id}
                      materialId={material.id}
                      onButtonClickAction={handlePiwikDesignState}
                    />
                  </PreviewCard>
                </TrackedSection>
              ))}
            </Grid>
          </Grid>

          {isMobile ? (
            <VStack w='100%' pr='5' pb='5' spacing='4'>
              <Button w='100%' color='lighterText' fontSize='sm' variant='text'>
                {t('dashboard.collection.matchingMaterials')}
              </Button>
              <TrackedSection
                contentInteraction='ClickButton'
                contentName='CollectionOverlay'
                contentPiece='Buttons'
                contentTarget='Inspiration'
              >
                <Link
                  to='/inspirations'
                  onClick={handleApplyInspiration}
                  style={{ width: '100%' }}
                >
                  <Button w='100%' fontSize='sm' variant='primary'>
                    {t('dashboard.collection.applyForInspiration')}
                  </Button>
                </Link>
              </TrackedSection>
            </VStack>
          ) : (
            <HStack justify='space-between' pr='5' pb='5'>
              <Button color='lighterText' fontSize='sm' variant='text'>
                {t('dashboard.collection.matchingMaterials')}
              </Button>
              <TrackedSection
                contentInteraction='ClickButton'
                contentName='CollectionOverlay'
                contentPiece='Buttons'
                contentTarget='Inspiration'
              >
                <Link to='/inspirations' onClick={handleApplyInspiration}>
                  <Button fontSize='sm' variant='primary'>
                    {t('dashboard.collection.applyForInspiration')}
                  </Button>
                </Link>
              </TrackedSection>
            </HStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
