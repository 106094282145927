import {
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Dispatch, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

export type ExportType = 'jpg' | 'png' | 'pdf' | 'tiff';

interface Props {
  exportType: ExportType;
  dispatch: Dispatch<{ exportType: ExportType }>;
}

export const ExportFileTypeSelection: FC<Props> = ({
  dispatch,
  exportType,
}) => {
  const { t } = useTranslation();

  return (
    <Stack as='section' gap={{ base: '4', mobile: '6' }}>
      <Text fontSize='sm' lineHeight='20px'>
        {t('inspirationEditor.chooseExportFormatDescription')}
      </Text>

      <RadioGroup
        onChange={(value) => dispatch({ exportType: value as ExportType })}
        value={exportType}
      >
        <Grid gap='2' templateColumns='repeat(2, 1fr)'>
          <GridItem layerStyle='ExportRadioWrapper' gridColumn={1} gridRow={1}>
            <Radio w='full' p='3' value='jpg'>
              <Text fontSize='sm' lineHeight='20px'>
                JPG
              </Text>
            </Radio>
          </GridItem>
          <GridItem layerStyle='ExportRadioWrapper' gridColumn={1} gridRow={2}>
            <Radio w='full' p='3' value='png'>
              <Text fontSize='sm' lineHeight='20px'>
                PNG
              </Text>
            </Radio>
          </GridItem>
          <GridItem layerStyle='ExportRadioWrapper' gridColumn={1} gridRow={3}>
            <Radio w='full' p='3' value='tiff'>
              <Text fontSize='sm' lineHeight='20px'>
                TIFF
              </Text>
            </Radio>
          </GridItem>

          <GridItem
            layerStyle='ExportRadioWrapper'
            p='3'
            cursor='pointer'
            onClick={() => dispatch({ exportType: 'pdf' })}
            rowSpan={3}
          >
            <Radio value='pdf'>
              <Text fontSize='sm' lineHeight='20px'>
                PDF
              </Text>
            </Radio>
            <Icon
              icon='pdf_preview'
              width='20'
              height='auto'
              marginLeft='4'
              marginTop='2'
            />
          </GridItem>
        </Grid>
      </RadioGroup>
    </Stack>
  );
};
