import { Heading, Stack, Text, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

interface Props {
  onClose: () => void;
}

export const ProfileModalChangePasswordSuccess: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Stack align='center' gap='10'>
      <Heading fontSize='3xl' textAlign='center'>
        {t('dashboard.authentication.changePassword.successTitle')}
      </Heading>
      <Stack gap='6'>
        <Icon icon='leaderboard' w='65px' h='65px' mx='auto' />
        <Text color='lighterText' fontSize='sm'>
          {t('dashboard.authentication.changePassword.successDescription')}
        </Text>
      </Stack>
      <Button onClick={onClose}>{t('general.close')}</Button>
    </Stack>
  );
};
