export const getContrastYIQ = (hexcolor: string) => {
  if (hexcolor === 'transparent') return 'black';
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

export const getColorBrightness = (hex: string) => {
  const [r, g, b] = hexToRgb(hex === 'transparent' ? '#ffffff' : hex);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const hexToRgb = (hex: string) => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return [r, g, b] as const;
};

export const rgbToHex = (r: number, g: number, b: number) => {
  const componentToHex = (component: number) => {
    const hex = component.toString(16).toUpperCase();
    return hex.length == 1 ? '0' + hex : hex;
  };

  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const rgbToHsb = (r: number, g: number, b: number): MultiColor => {
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  const delta = max - min;
  const saturation = max === 0 ? 0 : delta / max;

  let hue: number;
  if (delta === 0) {
    hue = 0;
  } else if (max === r) {
    hue = ((g - b) / delta) % 6;
  } else if (max === g) {
    hue = (b - r) / delta + 2;
  } else {
    hue = (r - g) / delta + 4;
  }

  hue = Math.round(hue * 60);
  if (hue < 0) hue += 360;

  return [hue, Math.round(saturation * 100), Math.round((max / 255) * 100)];
};

export const hsbToRgb = (h: number, s: number, b: number) => {
  const saturation = s / 100;
  const brightness = b / 100;

  const c = brightness * saturation;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = brightness - c;

  let r = 0,
    g = 0,
    bl = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
  } else if (120 <= h && h < 180) {
    g = c;
    bl = x;
  } else if (180 <= h && h < 240) {
    g = x;
    bl = c;
  } else if (240 <= h && h < 300) {
    r = x;
    bl = c;
  } else if (300 <= h && h < 360) {
    r = c;
    bl = x;
  }

  const red = Math.round((r + m) * 255);
  const green = Math.round((g + m) * 255);
  const blue = Math.round((bl + m) * 255);

  return [red, green, blue] as const;
};

export const isValidHex = (hex: string) => /^#[0-9A-F]{6}$/i.test(hex);

export const isValidRgb = (rgb: {
  r: number | string;
  g: number | string;
  b: number | string;
}) => {
  const r = Number(rgb.r);
  const g = Number(rgb.g);
  const b = Number(rgb.b);

  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    return false;
  }

  return r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255;
};

export const isValidHsb = (hsb: {
  h: number | string;
  s: number | string;
  b: number | string;
}) => {
  const h = Number(hsb.h);
  const s = Number(hsb.s);
  const b = Number(hsb.b);

  if (isNaN(h) || isNaN(s) || isNaN(b)) {
    return false;
  }

  return h >= 0 && h <= 360 && s >= 0 && s <= 100 && b >= 0 && b <= 100;
};

export type MultiColor = [number, number, number];
export type RGBA = { r: number; g: number; b: number; a: number };
