import { useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TooltipHeader } from '$/pages/DashboardPages/components/TooltipHeader';

export const CollectionHeader = () => {
  const { t } = useTranslation();

  const valueText = useBreakpointValue(
    {
      base: t('dashboard.collection.filter.collection'),
      md: t('dashboard.collection.exploreCollections'),
    },
    { ssr: false },
  );

  return (
    <TooltipHeader
      header={valueText ?? t('dashboard.collection.filter.collection')}
    />
  );
};
