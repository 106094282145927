import {
  Box,
  Collapse,
  Stack,
  StackProps,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { MaterialListItem } from '$/components/core/Collection/MaterialListItem';
import { AppliedColor } from '$/components/core/Editor/ColorSelectionSidebar/AppliedMaterial';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { Material } from '$/services/usecases/materials';

interface Props extends StackProps {
  material: Material;
}

export const CurrentMaterialSection: FC<Props> = ({ material, ...props }) => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack gap='3' {...props}>
      <Box onKeyDown={({ key }) => key === 'Enter' && onToggle()} tabIndex={0}>
        <FilterHeader
          header={t('inspirationEditor.attributes.usedColor')}
          isActive={isOpen}
          onClick={onToggle}
          prefix={<Icon icon='inspiration_marker' boxSize='4' />}
          isCollapsible
        />
      </Box>

      <Stack gap='0'>
        <Collapse in={isOpen}>
          <AppliedColor material={material} />
        </Collapse>

        <Box display={!isOpen ? 'block' : 'none'}>
          <MaterialListItem id={1} material={material} />
        </Box>
      </Stack>
    </Stack>
  );
};
