import { Input, InputProps } from '@chakra-ui/react';
import { FC, useEffect, useState, ChangeEvent, FocusEvent } from 'react';

interface Props extends Omit<InputProps, 'onChange'> {
  value: number;
  onChange: (value: number) => void;
  validate: (value: number) => boolean;
  transformer: (value: number) => number;
}

export const BrightnessInputElement: FC<Props> = ({
  value,
  onChange,
  validate,
  transformer,
  ...props
}) => {
  const [localValue, setLocalValue] = useState<number | ''>(value);
  const borderColor = !validate(localValue === '' ? NaN : localValue)
    ? 'danger.900'
    : 'border';

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedNumber = parseInt(e.currentTarget.value);

    if (isNaN(parsedNumber)) {
      setLocalValue('');
    } else {
      setLocalValue(parsedNumber);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    if (e.currentTarget.value === '') {
      setLocalValue(value);
    } else {
      onChange(transformer(parseInt(e.currentTarget.value)));
    }
  };

  return (
    <Input
      w='100px'
      minW='0'
      px='2'
      textAlign='center'
      bg='transparent'
      borderColor={borderColor}
      _hover={{}}
      _focusVisible={{}}
      outline='none'
      max={100}
      min={0}
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={(e) => e.stopPropagation()}
      type='number'
      value={localValue}
      {...props}
    />
  );
};
