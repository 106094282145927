import { FC } from 'react';

import { LoadingMaterialCard } from '$/components/core/Collection/LoadingMaterialCard';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { SectionHeadline } from '$/components/core/Collection/SectionHeadline';
import { FullscreenSectionRow } from '$/components/core/Editor/MaterialFullscreen/component/FullscreenSectionRow';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  materials: Material[];
  type: MaterialType;
  headline: string;
  isLoading?: boolean;
  onSelect: (material: Material) => void;
}

export const ReadOnlyFavoriteMaterialRow: FC<Props> = ({
  type,
  headline,
  materials,
  onSelect,
  isLoading = false,
}) => {
  if (!materials.length && !isLoading) return null;

  return (
    <SectionHeadline headline={headline}>
      <FullscreenSectionRow>
        {isLoading && repeat(4).map((id) => <LoadingMaterialCard key={id} />)}
        {materials.map((material) => (
          <MaterialCard
            key={material.id}
            editMode='none'
            material={material}
            materialType={type}
            onClick={() => onSelect(material)}
          />
        ))}
      </FullscreenSectionRow>
    </SectionHeadline>
  );
};
