import { queryOptions } from '@tanstack/react-query';

import { STANDARD_STALE_TIME } from '$/services/fetcher';
import { FilterType } from '$/services/mapper/uses';
import { FilterGroup, getFilterOptions } from '$/services/usecases/filter';
import { useGeneralStore } from '$/stores/useGeneralStore';

export const filterOptionsQuery = ({
  type,
  filter,
  hasUser,
}: {
  type?: FilterType;
  filter?: FilterGroup[];
  hasUser?: boolean;
  language?: string;
} = {}) =>
  queryOptions({
    queryKey: [
      'filter_options',
      type,
      filter,
      hasUser,
      useGeneralStore.getState().selectedLanguage,
    ],
    queryFn: ({ signal }) => getFilterOptions({ signal, type, filter }),
    staleTime: STANDARD_STALE_TIME,
  });
