import { HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';

interface Props {
  name: string;
}

export const ProListItem: FC<Props> = ({ name }) => {
  const { t } = useTranslation();
  const openAuthenticationModal = useAuthenticationStore.useOnModalOpen();
  const { hasPermission } = useAuthorization();

  if (hasPermission('View_All_Collections')) {
    return null;
  }

  return (
    <HStack
      justifyContent='space-between'
      gap='3'
      cursor='pointer'
      onClick={() => openAuthenticationModal('versionComparison')}
    >
      <Text fontSize='xs'>
        {t('general.more')} {name}
      </Text>
      <Text
        px='1'
        py='0.5'
        color='reverseText'
        fontSize='xs'
        fontWeight='bold'
        lineHeight='16px'
        bg='primaryButton.background'
        borderRadius='2px'
      >
        {t('roles.proLabel')}
      </Text>
    </HStack>
  );
};
