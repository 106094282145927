import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { RgbaColorPicker } from 'react-colorful';

interface Props {
  color: { r: number; g: number; b: number; a: number };
  setColor: (color: { r: number; g: number; b: number; a: number }) => void;
}

export const ColorPicker: FC<Props> = ({ color, setColor }) => {
  return (
    <Box
      sx={{
        '.react-colorful': {
          width: '100%',
          height: 'auto',
        },
        '.react-colorful__saturation': {
          borderRadius: '4px',
          aspectRatio: '1/1',
          height: 'auto',
        },
        '.react-colorful__saturation-pointer': {
          width: '8px',
          height: '8px',
        },
        '.react-colorful__hue': {
          borderRadius: '50px',
          height: '12px',
          marginTop: '15px',
          border: '1px solid',
          borderColor: 'border',
        },
        '.react-colorful__hue-pointer': {
          width: '16px',
          height: '16px',
          borderWidth: '1px',
        },
        '.react-colorful__alpha': {
          borderRadius: '50px',
          height: '12px',
          marginTop: '15px',
          border: '1px solid',
          borderColor: 'border',
          // TODO: REMOVE IF ALPHA IS SUPPORTED BY MODULE
          display: 'none',
        },
        '.react-colorful__alpha-pointer': {
          width: '16px',
          height: '16px',
          borderWidth: '1px',
        },
      }}
      w='full'
    >
      <RgbaColorPicker color={color} onChange={setColor} />
    </Box>
  );
};
