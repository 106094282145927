import { VStack, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { ProfileChangeEmailModal } from '$/components/core/Profile/ProfileChangeEmailModal';
import {
  ProfileFields,
  ProfileInputField,
} from '$/components/core/Profile/ProfileInputField';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { validateToken } from '$/services/usecases/authentication';
import { ProfileData, setName, updateEmail } from '$/services/usecases/profile';
import { emailFormatRegex } from '$/utils/regexUtils';

export const ProfileFormSection = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [newEmail, setNewEmail] = useState<string>('');

  const formMethods = useForm<ProfileData>({ mode: 'onChange' });
  const toast = useToast();
  const { cloudMessageTranslation } = useCloudMessageTranslation();

  const user = useAuthenticationStore.useUser();

  const [loading, setLoading] = useState(false);
  const [inEditMode, setInEditMode] = useState<ProfileFields | null>(null);

  if (!user) return null;

  const onChange = async (data: ProfileData) => {
    try {
      setLoading(true);

      if (
        data.firstName !== user.firstName ||
        data.lastName !== user.lastName
      ) {
        const response = await setName(data.firstName, data.lastName);
        if (response.isSuccessful) {
          toast(
            t('dashboard.authentication.profile.changeData.successTitle'),
            'success',
            t('dashboard.authentication.profile.changeData.successDescription'),
          );
          await validateToken(true);
        } else {
          formMethods.reset();
          toast(
            t('dashboard.authentication.profile.changeData.errorTitle'),
            'error',
            t('dashboard.authentication.profile.changeData.errorDescription'),
          );
        }
      }
      if (data.email !== user.email) {
        const response = await updateEmail(data.email);

        if (response.isSuccessful) {
          setNewEmail(data.email);
          onOpen();
        } else {
          formMethods.reset();
          toast(
            t('dashboard.authentication.profile.changeData.errorTitle'),
            'error',
            cloudMessageTranslation(response.response.message),
          );
        }
      }
    } catch (_) {
      toast(
        t('dashboard.authentication.profile.changeData.errorTitle'),
        'error',
        t('dashboard.authentication.profile.changeData.errorDescription'),
      );
      formMethods.reset();
    } finally {
      setLoading(false);
      setInEditMode(null);
    }
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <VStack
          as='form'
          w='full'
          onSubmit={formMethods.handleSubmit(onChange)}
        >
          <ProfileInputField
            accessor='firstName'
            label={t('dashboard.authentication.firstNameLabel')}
            inEditMode={inEditMode === 'firstName'}
            value={user?.firstName ?? ''}
            setActive={setInEditMode}
            locked={inEditMode !== null}
            isLoading={inEditMode === 'firstName' && loading}
            trackedProfileInputField='EditName'
          />
          <ProfileInputField
            accessor='lastName'
            label={t('dashboard.authentication.lastNameLabel')}
            inEditMode={inEditMode === 'lastName'}
            value={user?.lastName ?? ''}
            setActive={setInEditMode}
            locked={inEditMode !== null}
            isLoading={inEditMode === 'lastName' && loading}
            trackedProfileInputField='EditName'
          />
          <ProfileInputField
            accessor='email'
            label={t('dashboard.authentication.profile.emailLabel')}
            inEditMode={inEditMode === 'email'}
            value={user?.email ?? ''}
            setActive={setInEditMode}
            locked={inEditMode !== null}
            isLoading={inEditMode === 'email' && loading}
            registerOptions={{
              required: t('dashboard.authentication.errorMessages.isRequired'),
              pattern: {
                value: emailFormatRegex,
                message: t(
                  'dashboard.authentication.errorMessages.wrongFormat',
                ),
              },
            }}
            trackedProfileInputField='EditMail'
          />
        </VStack>
      </FormProvider>
      <ProfileChangeEmailModal
        newEmail={newEmail}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          formMethods.reset();
        }}
      />
    </>
  );
};
