import { Icon } from '$/components/common/Icon';

type ObjectType = 'Todo' | 'Bitmap';

export const getEditorObjectIcon = (type: ObjectType) => {
  switch (type) {
    case 'Bitmap':
      return <Icon icon='brush' fill='editor.icon' boxSize='3' />;
    default:
      return <Icon icon='vector' fill='editor.icon' boxSize='3' />;
  }
};
