import {
  Box,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { Icon } from '$/components/common/Icon';
import { scrollable } from '$/components/common/Scrollbar';
import { BrandMenu } from '$/components/core/BrandMenu';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { StyledLink } from '$/components/core/StyledLink';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props {
  projectName?: string;
  children: ReactNode;
}

export const LayerSidebar: FC<StackProps & Props> = ({
  projectName,
  children,
  ...rest
}) => {
  const { t } = useTranslation();

  const perspectiveFullscreen =
    useInspirationEditorStore.usePerspectiveFullscreen();

  const isFullscreen = useEditorStore.useIsFullScreen();

  return (
    <Box
      layerStyle='EditorFloatingSidebar'
      pos='fixed'
      top='0'
      bottom='0'
      left={perspectiveFullscreen || isFullscreen ? '-18rem' : 0}
      w='56'
      m='4'
      transition='left 0.5s ease'
      {...scrollable({ x: 'hidden', y: 'scroll' })}
    >
      <Stack gap='0' h='full' fontSize='sm' {...rest}>
        <Box px='4' pt='1' pb='2'>
          <BrandMenu />
        </Box>

        <SidebarDivider />

        <HStack w='full' px='4' py='2.5'>
          <StyledLink link={{ to: '/' }}>
            <IconButton
              minW='0'
              h='auto'
              minH='0'
              aria-label={t('editor.backToHome')}
              icon={<Icon icon='home' fill='editor.icon' boxSize='22px' />}
              variant='text'
            />
          </StyledLink>

          <Show
            when={projectName}
            fallback={() => <Skeleton>424242424242</Skeleton>}
          >
            <Tooltip
              bg='background'
              hasArrow
              label={projectName}
              placement='top'
            >
              <Text
                fontSize='xs'
                fontWeight='bold'
                textOverflow='ellipsis'
                isTruncated
              >
                {projectName}
              </Text>
            </Tooltip>
          </Show>
        </HStack>

        <SidebarDivider />

        {children}
      </Stack>
    </Box>
  );
};
