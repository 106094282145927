import {
  Center,
  FormLabel,
  Image,
  Input,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { TrackedSection } from '$/components/common/TrackedSection';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { validateToken } from '$/services/usecases/authentication';
import { uploadProfilePicture } from '$/services/usecases/profile';
import { getInitials } from '$/utils/stringUtils';

const allowedExtensions = ['jpg', 'jpeg', 'png'];

export const ProfileImage = () => {
  const { t } = useTranslation();

  const toast = useToast();
  const { cloudMessageTranslation } = useCloudMessageTranslation();

  const user = useAuthenticationStore.useUser();
  const profileImageHash = useAuthenticationStore.useProfileImageHash();
  const setProfileImageHash = useAuthenticationStore.useSetProfileImageHash();

  const [loading, setLoading] = useState(false);

  if (!user) return null;

  const onImageChange = async (file: File | undefined) => {
    if (!file) return;

    const extension = file.name.split('.').pop()?.toLowerCase();
    if (!extension || !allowedExtensions.includes(extension)) {
      return;
    }

    try {
      setLoading(true);
      const response = await uploadProfilePicture(file);
      if (response.isSuccessful) {
        toast(
          t('dashboard.authentication.profile.upload.successTitle'),
          'success',
          t('dashboard.authentication.profile.upload.successDescription'),
        );
        await validateToken(true);
      } else {
        toast(
          t('dashboard.authentication.profile.upload.errorTitle'),
          'error',
          cloudMessageTranslation(response.response.message),
        );
      }
    } catch (_) {
      toast(
        t('dashboard.authentication.profile.upload.errorTitle'),
        'error',
        cloudMessageTranslation('genericError'),
      );
    }

    setProfileImageHash(Date.now());
    setLoading(false);
  };

  return (
    <FormLabel pos='relative' cursor='pointer' htmlFor='file'>
      <TrackedSection
        contentInteraction='ClickButton'
        contentName='CloudAccount'
        contentPiece='ManageAccount'
        contentTarget='Picture'
      >
        <Input
          display='none'
          accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
          id='file'
          onChange={(e) => onImageChange(e.target.files?.[0])}
          type='file'
        />
      </TrackedSection>

      <Icon
        icon='edit_pen'
        position='absolute'
        top='6px'
        right='6px'
        bg='background'
        w='24px'
        h='24px'
        p='1'
        borderRadius='4px'
      />

      {!loading && (
        <Image
          w='120px'
          h='120px'
          borderRadius='4px'
          fallback={
            <Center
              w='120px'
              h='120px'
              bg='activeBackground'
              borderRadius='4px'
            >
              <Text color='ultralightText' fontSize='40px' fontWeight='bold'>
                {getInitials(user.firstName, user.lastName)}
              </Text>
            </Center>
          }
          fallbackStrategy='onError'
          src={`${user.profileImageUrl}?${profileImageHash}`}
        />
      )}

      {loading && <Skeleton w='120px' h='120px' borderRadius='4px' />}
    </FormLabel>
  );
};
