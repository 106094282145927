import {
  AbsoluteCenter,
  Box,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, MouseEvent } from 'react';

import { outlined } from '$/components/common/Accessibility';
import { useNonFreeAction } from '$/hooks/useNonFreeAction';
import { DeleteButton } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/DeleteButton';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { endpointFactory } from '$/services/endpoints';

interface ImageURLProps {
  variantButtonIndex: number;
}

export const VariantCard: FC<ImageURLProps> = ({ variantButtonIndex }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const scene = useInspirationEditorStore.useScene();
  const sceneVariantLabelOrder =
    useInspirationEditorStore.useSceneVariantLabelOrder();
  const activeSceneVariantIndex =
    useInspirationEditorStore.useActiveSceneVariantIndex();
  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();
  const setActiveSceneVariantIndex =
    useInspirationEditorStore.useSetActiveSceneVariantIndex();
  const isActive = activeSceneVariantIndex === variantButtonIndex;

  const handleRightClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onToggle();
  };

  const handleNonFreeAction = useNonFreeAction();

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box
          pos='relative'
          h='inherit'
          borderRadius='4px'
          {...outlined({ visible: isActive })}
        >
          <AbsoluteCenter userSelect='none' pointerEvents='none'>
            <Text color='reverseText' fontSize='xl' fontWeight='bold'>
              {sceneVariantLabelOrder[variantButtonIndex]}
            </Text>
          </AbsoluteCenter>

          <Image
            border='2px solid'
            borderColor='editor.borderVariants'
            borderRadius='4px'
            cursor='pointer'
            alt={scene?.id}
            aspectRatio='3/2'
            onClick={() =>
              handleNonFreeAction(() =>
                setActiveSceneVariantIndex(variantButtonIndex),
              )
            }
            onContextMenu={handleRightClick}
            src={endpointFactory.sceneImageUrl({ id: scene?.id ?? '0' })}
          />
        </Box>
      </PopoverTrigger>

      {sceneLayersVariants.length > 1 && (
        <PopoverContent w='fit-content' border='0px solid' borderColor='border'>
          <PopoverArrow />
          <DeleteButton buttonIndex={variantButtonIndex} onClose={onClose} />
        </PopoverContent>
      )}
    </Popover>
  );
};
