import { Await } from '@tanstack/react-router';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionHeadline } from '$/components/core/Collection/SectionHeadline';
import { useMaterialGridMaxItems } from '$/hooks/useMaterialGridMaxItems';
import { DashboardSectionRow } from '$/pages/DashboardPages/components/DashboardSectionRow';
import { InspirationRow } from '$/pages/DashboardPages/pages/Welcome/components/InspirationSection/components/InspirationRow';
import { LoadingInspirationCard } from '$/pages/DashboardPages/pages/Welcome/components/InspirationSection/components/LoadingInspirationCard';
import { DashboardRoute } from '$/routes/DashboardRoute';
import { repeat } from '$/utils/arrayUtils';

export const InspirationSection = () => {
  const { t } = useTranslation();

  const { scenes } = DashboardRoute.useLoaderData();
  const itemLimit = useMaterialGridMaxItems();

  return (
    <SectionHeadline
      headline={t('dashboard.welcome.inspirationText')}
      showAllUrl='/inspirations'
    >
      <DashboardSectionRow>
        <Suspense
          fallback={repeat(itemLimit).map((i) => (
            <LoadingInspirationCard key={i} />
          ))}
        >
          <Await promise={scenes}>
            {(scenes) => <InspirationRow scenes={scenes} />}
          </Await>
        </Suspense>
      </DashboardSectionRow>
    </SectionHeadline>
  );
};
