import { Stack } from '@chakra-ui/react';
import { t } from 'i18next';
import { FC } from 'react';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { ReadOnlyFavoriteFolderRow } from '$/components/core/Collection/Favorites/ReadOnlyFavoriteFolderRow';
import { MaterialRow } from '$/components/core/Collection/MaterialRow';
import { FullscreenSectionRow } from '$/components/core/Editor/MaterialFullscreen/component/FullscreenSectionRow';
import { useMaterialFullscreenStore } from '$/components/core/Editor/MaterialFullscreen/store/useMaterialFullscreenStore';
import { queryClient } from '$/services/fetcher';
import { FilterType, MaterialType } from '$/services/mapper/uses';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { Material } from '$/services/usecases/materials';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  onSelect: (material: Material) => void;
  materialTypes?: MaterialType[];
}

export const FullscreenOverviewModal: FC<Props> = ({
  materialTypes,
  onSelect,
}) => {
  const { hasPermission } = useAuthorization();
  const setModalType = useMaterialFullscreenStore.useSetModalType();

  const showMore = (type: FilterType) => {
    setModalType('material', 'overview', {
      materialType: type as MaterialType,
    });

    useCollectionStore.setState({ filter: undefined, activeType: type });

    const { filterGroups } = useCollectionStore.getState();
    void queryClient
      .ensureQueryData(filterOptionsQuery({ type, filter: filterGroups }))
      .then((filter) => useCollectionStore.setState({ filter }));
  };

  return (
    <Stack gap='6'>
      <ReadOnlyFavoriteFolderRow
        onShowMoreClick={() => setModalType('favorites', 'overview')}
        onFolderClick={(folder) =>
          setModalType('favorites', 'overview', { folder })
        }
        cardLimit={4}
      />

      {(!materialTypes || materialTypes?.includes('uni')) && (
        <MaterialRow
          type='uni'
          onShowMoreClick={() => showMore('uni')}
          headline={t('dashboard.collection.search.colorTones')}
          RowWrapper={FullscreenSectionRow}
          hideFavoriteButtons
          cardLimit={4}
          minCardAmount={4}
          onSelect={onSelect}
        />
      )}
      {(!materialTypes || materialTypes?.includes('wall')) && (
        <MaterialRow
          type='wall'
          onShowMoreClick={
            hasPermission('View_All_Collections')
              ? () => showMore('wall')
              : undefined
          }
          headline={t('dashboard.collection.search.wallCoverings')}
          RowWrapper={FullscreenSectionRow}
          hideFavoriteButtons
          cardLimit={4}
          minCardAmount={4}
          onSelect={onSelect}
        />
      )}
      {(!materialTypes || materialTypes?.includes('floor')) && (
        <MaterialRow
          type='floor'
          onShowMoreClick={
            hasPermission('View_All_Collections')
              ? () => showMore('floor')
              : undefined
          }
          headline={t('dashboard.collection.search.floorCoverings')}
          RowWrapper={FullscreenSectionRow}
          hideFavoriteButtons
          cardLimit={4}
          minCardAmount={4}
          onSelect={onSelect}
        />
      )}
      {(!materialTypes || materialTypes?.includes('facade')) && (
        <MaterialRow
          type='facade'
          onShowMoreClick={
            hasPermission('View_All_Collections')
              ? () => showMore('facade')
              : undefined
          }
          headline={t('dashboard.collection.search.facades')}
          RowWrapper={FullscreenSectionRow}
          hideFavoriteButtons
          cardLimit={4}
          minCardAmount={4}
          onSelect={onSelect}
        />
      )}
    </Stack>
  );
};
