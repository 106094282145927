import {
  Flex,
  FlexProps,
  ResponsiveValue,
  ScaleFade,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';

import { MaterialCardContextMenu } from '$/components/core/Collection/MaterialCard/MaterialCardContextMenu';
import { Material } from '$/services/usecases/materials';
import { getColorBrightness } from '$/utils/colorUtils';

interface Props {
  material: Material;
  pageSize: number;
  setActiveMaterial: (material: Material) => void;
  height: ResponsiveValue<string>;
  activeMaterialId?: string;
  borderRadius?: string;
  isHovered?: boolean;
  hideFavoriteButton?: boolean;
}

export const FanPresentationItem: FC<Props & FlexProps> = ({
  material,
  pageSize,
  setActiveMaterial,
  height,
  activeMaterialId,
  borderRadius,
  isHovered = false,
  hideFavoriteButton = false,
  ...props
}) => {
  const isActive = activeMaterialId === material.uniqueKey;

  return (
    <Flex
      key={material.id}
      pos='relative'
      align='flex-end'
      justify='center'
      w='full'
      h={height}
      bg={material.url({ width: '300', height: '300' }) ?? material.color}
      border='1px solid'
      borderColor={isActive ? 'text' : 'border'}
      borderRadius={pageSize === 1 ? borderRadius : '0'}
      _first={{
        borderRadius: `${borderRadius} 0 0 ${borderRadius}`,
      }}
      _last={{
        borderRadius: `0 ${borderRadius} ${borderRadius} 0`,
      }}
      _notLast={{
        borderRightColor: isActive ? 'text' : 'transparent',
      }}
      cursor='pointer'
      transition='width 0.2s linear'
      css={{
        ':only-child': {
          borderRadius: borderRadius,
        },
      }}
      onClick={() => setActiveMaterial(material)}
      {...props}
    >
      <ScaleFade
        delay={{
          enter: 0.1,
          exit: 0,
        }}
        in={isHovered}
        initialScale={0.7}
      >
        <Text
          mb='2'
          color={
            getColorBrightness(material.color) > 180
              ? 'neutral.1000'
              : 'neutral.50'
          }
          fontSize='sm'
        >
          {material.info}
        </Text>
      </ScaleFade>
      {isHovered && !hideFavoriteButton && (
        <MaterialCardContextMenu
          editMode='favorite'
          material={material}
          materialType={'uni'}
          bg='bodyBackground'
          top='8px'
          right='8px'
        />
      )}
    </Flex>
  );
};
