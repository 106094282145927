import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MultiFilter } from '$/components/core/Collection/CollectionFilter/components/MultiFilter';
import { ProMenuItem } from '$/components/core/Collection/CollectionFilter/components/ProMenuItem';
import { FilterDropdown } from '$/components/core/Collection/CollectionFilter/FilterDropdown';
import { FilterGroupOption } from '$/services/usecases/filter';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  filter: FilterGroupOption;
}

export const MultiFilterDropdown: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();

  const active = filter
    ? useCollectionStore.getState().getActiveInCategory(filter?.searchName)
    : [];

  const getTitle = () => {
    if (active.length === 0) return t('inspirationEditor.filter.all');
    if (active.length === 1) return active[0].displayName;
    return t('inspirationEditor.filter.nFilterActive', {
      amount: active.length,
    });
  };

  return (
    <FilterDropdown
      title={getTitle()}
      bg='bodyBackground'
      border='1px solid'
      borderColor='border'
      textAlign='start'
      h='6'
      w='full'
      borderRadius='4'
      color='lighterText'
      px='3'
      fontSize='xs'
    >
      {(onMenuClose) => (
        <>
          <MultiFilter
            onMenuClose={onMenuClose}
            type={filter.searchName}
            activeOptions={filter.items}
            hideActions
            compactMode
          />
          <ProMenuItem
            name={filter.displayNameKey != null ? t(filter.displayNameKey) : ''}
          />
        </>
      )}
    </FilterDropdown>
  );
};
