import {
  Box,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useRouterState } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { TrackedSection } from '$/components/common/TrackedSection';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { trackContentImpression } from '$/logger';
import { signOut } from '$/services/usecases/authentication';
import { useProfileStore } from '$/stores/useProfileStore';

export const AccountButton = () => {
  const { t } = useTranslation();

  const { hasPermission } = useAuthorization();

  const { pathname } = useRouterState().location;

  const user = useAuthenticationStore.useUser();
  const onOpenAuthenticationModal = useAuthenticationStore.useOnModalOpen();
  const profileImageHash = useAuthenticationStore.useProfileImageHash();

  const onOpenProfileModal = useProfileStore.useOnModalOpen();

  const handleMenuOpen = () => {
    trackContentImpression('CloudAccount', 'Account', 'Open');
  };

  if (!hasPermission('General_User_Profile')) {
    return (
      <Button
        h='auto'
        px={{ base: '2', sm: '10' }}
        fontSize='sm'
        borderRadius='0'
        onClick={() => onOpenAuthenticationModal('register')}
        rightIcon={
          <Icon icon='account' w='6' h='6' mr={{ base: '0.5rem', sm: '0' }} />
        }
        variant='black'
      >
        <Box display={{ base: 'none', sm: 'inline-block' }}>
          {t('dashboard.header.createAccount')}
        </Box>
      </Button>
    );
  }

  return (
    <Menu onOpen={handleMenuOpen}>
      <MenuButton
        as={GhostButton}
        h='auto'
        fontSize='sm'
        bg={pathname === '/profile' ? 'activeBackground' : 'transparent'}
        borderRadius='0'
        rightIcon={
          <Image
            boxSize='6'
            borderRadius='full'
            fallback={<Icon icon='account' boxSize='6' />}
            fallbackStrategy='onError'
            src={`${user?.profileImageUrl}?${profileImageHash}`}
          />
        }
      >
        {user?.firstName}
      </MenuButton>
      <MenuList fontSize='sm' border='1px' borderColor='border'>
        <TrackedSection
          contentInteraction='ClickButton'
          contentName='CloudAccount'
          contentPiece='Account'
          contentTarget='Manage'
        >
          <MenuItem onClick={onOpenProfileModal}>
            {t('dashboard.header.manageAccount')}
          </MenuItem>
        </TrackedSection>
        <TrackedSection
          contentInteraction='ClickButton'
          contentName='CloudAccount'
          contentPiece='Account'
          contentTarget='Logout'
        >
          <MenuItem onClick={signOut}>{t('dashboard.header.signOut')}</MenuItem>
        </TrackedSection>
      </MenuList>
    </Menu>
  );
};
