import {
  HStack,
  Slider,
  SliderTrack,
  SliderThumb,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { FC, WheelEventHandler, useEffect, useState } from 'react';

interface Props {
  initialValue: number;
  minValue: number;
  maxValue: number;
  onChange: (value: number) => void;
  delay?: number;
}

export const SliderInput: FC<Props> = ({
  initialValue,
  maxValue,
  minValue,
  onChange,
  delay = 500,
}) => {
  const [internalSize, setInternalSize] = useState(initialValue);

  useEffect(() => {
    let timeoutId: number | null = null;

    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
    timeoutId = window.setTimeout(() => onChange(internalSize), delay);

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
    // prevent unnecessary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalSize]);

  useEffect(() => {
    setInternalSize(initialValue);
  }, [initialValue]);

  const onScroll: WheelEventHandler = (event) => {
    const scrollSteps = event.shiftKey ? 10 : 1;
    if (event.deltaY > 0) {
      setInternalSize(Math.max(internalSize - scrollSteps, minValue));
    } else if (event.deltaY < 0) {
      setInternalSize(Math.min(internalSize + scrollSteps, maxValue));
    }
  };

  return (
    <HStack gap='4' w='full'>
      <Slider
        focusThumbOnChange={false}
        max={maxValue}
        min={minValue}
        onChange={(value) => setInternalSize(value)}
        onWheel={onScroll}
        value={internalSize}
      >
        <SliderTrack
          h='10px'
          bg='background'
          border='1px solid'
          borderColor='border'
          borderRadius='full'
        />
        <SliderThumb
          boxSize='4'
          bg='background'
          border='1px solid'
          borderColor='border'
          shadow='none'
        />
      </Slider>
      <NumberInput
        maxW='12'
        borderColor='border'
        borderRadius='4px'
        max={maxValue}
        min={minValue}
        onChange={(_, value) =>
          setInternalSize(isNaN(value) ? minValue : value)
        }
        size='sm'
        step={1}
        value={internalSize}
      >
        <NumberInputField px='2' textAlign='center' borderRadius='4px' />
      </NumberInput>
    </HStack>
  );
};
