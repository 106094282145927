export const outlined = (payload: {
  visible?: boolean;
  showOnFocus?: boolean;
}) => {
  const lightShadow =
    '#F4F4F4 0px 0px 0px 1px, #222222 0px 0px 0px 3px, rgba(0, 0, 0, 0) 0px 0px 0px 0px';

  const darkShadow =
    '#222222 0px 0px 0px 1px, #F4F4F4 0px 0px 0px 3px, rgba(0, 0, 0, 0) 0px 0px 0px 0px';

  return {
    boxShadow: payload.visible ? lightShadow : 'none',
    ...(payload.showOnFocus ? { _focus: { boxShadow: lightShadow } } : {}),
    _dark: {
      boxShadow: payload.visible ? darkShadow : 'none',
      ...(payload.showOnFocus ? { _focus: { boxShadow: darkShadow } } : {}),
    },
  };
};
