import { Stack, StackProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { scrollable } from '$/components/common/Scrollbar';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props {
  children: ReactNode;
}

export const AttributeSidebar: FC<StackProps & Props> = ({
  children,
  ...rest
}) => {
  const perspectiveFullscreen =
    useInspirationEditorStore.usePerspectiveFullscreen();
  const isEditorFullscreen = useEditorStore.useIsFullScreen();

  return (
    <Stack
      layerStyle='EditorFloatingSidebar'
      pos='fixed'
      top='0'
      right={perspectiveFullscreen || isEditorFullscreen ? '-20rem' : '0'}
      bottom='0'
      gap='4'
      w='56'
      m='4'
      transition='right 0.5s ease'
      {...scrollable({ x: 'hidden', y: 'scroll' })}
      {...rest}
    >
      {children}
    </Stack>
  );
};
