import { Image, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterButton } from '$/pages/DashboardPages/pages/Inspiration/components/FilterButton';
import { useInspirationStore } from '$/pages/DashboardPages/pages/Inspiration/stores/useInspirationStore';
import { Category } from '$/services/usecases/scenes';

interface Props {
  category: Category;
  imageUrl: string;
}

export const FilterCard: FC<Props> = ({ category, imageUrl }) => {
  const { t } = useTranslation();
  const activeCategory = useInspirationStore.useActiveCategory();
  const selectCategory = useInspirationStore.useSelectCategory();

  const selectFilter = () => selectCategory(category);
  const isActiveFilter = activeCategory?.id === category.id;

  const translationKey = `dashboard.inspiration.scenes.${category.id}`;

  return (
    <FilterButton
      isActive={isActiveFilter}
      onClick={selectFilter}
      minWidth='15rem'
      border={{ base: 'solid 1px', md: 'none' }}
      borderColor={{ base: 'border', md: 'none' }}
      borderRadius={{ base: 'full', md: '6px' }}
      minW={{ base: 'fit-content', md: '15rem' }}
      pr={{ base: '2', md: '0' }}
      gap={{ base: '2', md: '4' }}
    >
      <Image
        layerStyle='FilterImage'
        w={{ base: '8', md: '16' }}
        borderRadius={{ base: 'full', md: '6px' }}
        objectFit='cover'
        alt={category.name}
        src={imageUrl}
      />

      <Text
        fontSize={{ base: 'sm', md: 'md' }}
        fontWeight={{ base: 'normal', md: 'bold' }}
      >
        {t(translationKey)}
      </Text>
    </FilterButton>
  );
};
