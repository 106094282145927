import { useTranslation } from 'react-i18next';

import de from '$/translations/de.json';

const cloudMessages = de.translation.dashboard.authentication.cloudMessage;

export const cloudMessageOptions = Object.keys(cloudMessages) as [
  keyof typeof cloudMessages,
];

export const useCloudMessageTranslation = () => {
  const { t } = useTranslation();

  return {
    cloudMessageTranslation: (message: keyof typeof cloudMessages) =>
      t(`dashboard.authentication.cloudMessage.${message}`),
  };
};
