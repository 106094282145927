import { VStack, HStack, Button, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const BooleanOperations = () => {
  const { t } = useTranslation();
  const { selectBooleanAction } = useEditorActions();
  const activeComponentIndex = useEditorStore.useActiveComponentIndex();
  const activeLayerIndex = useEditorStore.useActiveLayerIndex();
  const components = useEditorStore.useComponents();

  if (activeComponentIndex == null || activeLayerIndex == null) {
    return null;
  }

  const activeLayer = components[activeComponentIndex].layers[activeLayerIndex];
  return (
    <VStack w='full' px='4'>
      <Box ml='-2'>
        <SectionTitle
          title={t('editor.booleanOperations')}
          icon='boolean_union'
        />
      </Box>
      <HStack justify='stretch' w='full'>
        <Button
          w='full'
          h='8'
          isActive={activeLayer.booleanMode === 'union'}
          onClick={() => selectBooleanAction('union')}
          variant='tertiary'
        >
          <Icon boxSize='16px' icon='boolean_union' />
        </Button>
        <Button
          w='full'
          h='8'
          isActive={activeLayer.booleanMode === 'substract'}
          onClick={() => selectBooleanAction('substract')}
          variant='tertiary'
        >
          <Icon boxSize='16px' icon='boolean_subtract' />
        </Button>
        <Button
          w='full'
          h='8'
          isActive={activeLayer.booleanMode === 'exclude'}
          onClick={() => selectBooleanAction('exclude')}
          variant='tertiary'
        >
          <Icon boxSize='16px' icon='boolean_exclude' />
        </Button>
      </HStack>
    </VStack>
  );
};
