import {
  VStack,
  HStack,
  PinInput,
  PinInputField,
  Button,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { FC, useState } from 'react';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useKeyPress } from '$/hooks/useKeyPress';
import { useToast } from '$/hooks/useToast';
import { updateEmail } from '$/services/usecases/profile';

interface Props {
  newEmail: string;
  onClose: () => void;
  isOpen: boolean;
}

export const ProfileChangeEmailModal: FC<Props> = ({
  newEmail,
  onClose,
  isOpen,
}) => {
  const [otp, setOtp] = useState<string>('');
  const toast = useToast();
  const setUser = useAuthenticationStore.useSetUser();
  const { cloudMessageTranslation } = useCloudMessageTranslation();

  const onSubmit = async () => {
    if (otp.length < 6) return;
    const { isSuccessful, response } = await updateEmail(newEmail, otp);
    if (isSuccessful && response.payload?.user != null) {
      setUser(response.payload.user);
      toast(
        t('dashboard.authentication.changeEmail.successToastHeader'),
        'success',
        cloudMessageTranslation(response.message),
      );
      onCloseModal();
    } else {
      toast(
        t('dashboard.authentication.verifyEmail.errorToastHeader'),
        'error',
        t('dashboard.authentication.verifyEmail.errorToastDescription'),
      );
    }
  };

  useKeyPress(({ key }) => {
    if (key === 'Enter' && otp.length === 6) {
      void onSubmit();
    }
  });

  const onCloseModal = () => {
    setOtp('');
    onClose();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent maxW='500px' p='4' bg='background'>
        <ModalHeader>
          {t('dashboard.authentication.changeEmail.modalHeader')}
        </ModalHeader>
        <ModalCloseButton top='0' right='0' mt='8' mr='10' />
        <ModalBody>
          <VStack gap='8'>
            <Text fontSize='sm'>
              {t('dashboard.authentication.verifyEmail.description')}
            </Text>
            <HStack>
              <PinInput onChange={setOtp} value={otp}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
            <HStack gap='4'>
              <Button isDisabled={otp.length < 6} onClick={onSubmit}>
                {t('dashboard.authentication.verifyEmail.submitButton')}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
