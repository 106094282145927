import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Text,
  HStack,
  Button,
} from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';

interface LoginToSaveModalContentProps {
  onClose: () => void;
}

export const LoginToSaveModalContent: FC<LoginToSaveModalContentProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setAuthenticationModalType = useAuthenticationStore.useSetModalType();

  return (
    <ModalContent
      maxW='321.6px'
      maxH='184px'
      bg='background'
      borderRadius='12px'
    >
      <ModalHeader pt='4' pl='4' fontSize='lg'>
        {t('dashboard.authentication.loginToSave.label')}
      </ModalHeader>
      <ModalCloseButton top='0' right='0' mt='1.5' mr='1.5' />
      <ModalBody px='4'>
        <VStack align='left' gap={0}>
          <Text m={0} p={0} fontSize='sm' lineHeight='20px'>
            {t('dashboard.authentication.loginToSave.part1')}
          </Text>
          <Text m={0} p={0} fontSize='sm' lineHeight='20px'>
            {t('dashboard.authentication.loginToSave.part2')}
          </Text>
          <HStack gap={2} w='100%' pt='6' pb='2'>
            <Button
              flex={1}
              maxW='117px'
              px='0'
              fontSize='sm'
              onClick={async () => {
                onClose();
                await navigate({ to: '/' });
              }}
              variant='text'
            >
              {t('dashboard.authentication.loginToSave.close')}
            </Button>
            <Button
              flex={1}
              maxW='163px'
              fontSize='sm'
              onClick={() => setAuthenticationModalType('login')}
            >
              {t('dashboard.authentication.loginToSave.login')}
            </Button>
          </HStack>
        </VStack>
      </ModalBody>
    </ModalContent>
  );
};
