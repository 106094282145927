import { useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TooltipHeader } from '$/pages/DashboardPages/components/TooltipHeader';

export const SubCollectionFacadeHeader = () => {
  const { t } = useTranslation();

  const exploreText = useBreakpointValue(
    {
      base: t('dashboard.collection.search.facades'),
      md: t('dashboard.collection.exploreFacades'),
    },
    { ssr: false },
  );

  return (
    <TooltipHeader
      header={exploreText ?? t('dashboard.collection.filter.collection')}
    />
  );
};
