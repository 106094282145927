import { Box, Collapse, Stack, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { BrightnessFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/BrightnessFilter';
import { ColorFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/ColorFilter';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { useCollectionStore } from '$/stores/useCollectionStore';

export const MoreSelectionFilter = () => {
  const { t } = useTranslation();

  const activeType = useCollectionStore.useActiveType();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack gap='0' pt='3'>
      <Box onKeyDown={({ key }) => key === 'Enter' && onToggle()} tabIndex={0}>
        <Box px='4'>
          <FilterHeader
            header={t('general_editor.filterLogic.moreFilter')}
            isActive={isOpen}
            onClick={onToggle}
            prefix={<Icon icon='filter' boxSize='4' />}
            isCollapsible
          />
        </Box>
      </Box>

      <Stack gap='0'>
        <SidebarDivider mt='3' />

        <Collapse in={isOpen} startingHeight={1}>
          <Stack gap='0' pt='3'>
            <ColorFilter px='4' />
            <SidebarDivider mt='4 !important' />
            {activeType === 'uni' && (
              <>
                <BrightnessFilter px='4' pt='1' />
                <SidebarDivider mt='4 !important' />
              </>
            )}
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
};
