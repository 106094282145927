import { VStack, Text, Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { FavoriteFolderSection } from '$/components/core/Editor/ColorSelectionSidebar/Favorites/FavoriteFolderSection';
import { SidebarBackButton } from '$/components/core/Editor/ColorSelectionSidebar/SidebarBackButton';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { useGroupedFavorites } from '$/pages/DashboardPages/pages/Favorite/hooks/useGroupedFavorites';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { DEFAULTFOLDERNAME, useFavoriteStore } from '$/stores/useFavoriteStore';

interface Props {
  materialTypes?: MaterialType[];
  activeMaterialId?: string;
  onClickItem: (material: Material) => void;
  drawerMode?: boolean;
}

export const FavoriteFolderView: FC<Props> = ({
  materialTypes,
  activeMaterialId,
  onClickItem,
  drawerMode = false,
}) => {
  const { t } = useTranslation();

  const activeFolderId = useFavoriteStore.useActiveFolderId();
  const folders = useFavoriteStore.useFavoriteFolders();

  const setActiveFolderId = useFavoriteStore.useSetActiveFolderId();

  const {
    colorFavorites,
    floorFavorites,
    wallFavorites,
    facadeFavorites,
    isLoading,
    allFavorites,
  } = useGroupedFavorites(activeFolderId ?? DEFAULTFOLDERNAME, materialTypes);

  const activeFolder = folders.find(
    (folder) => folder.id === activeFolderId,
  ) ?? { id: DEFAULTFOLDERNAME, name: t('favorites.generalStorage') };

  const label = drawerMode
    ? t('inspirationEditor.attributes.favorites')
    : `${t('inspirationEditor.attributes.favorites')}: ${activeFolder.name}`;

  return (
    <VStack
      alignItems='flex-start'
      gap='0'
      w='full'
      px={drawerMode ? '3' : undefined}
      pt={drawerMode ? '4' : undefined}
    >
      <SidebarBackButton
        onClick={() => setActiveFolderId(null)}
        label={label}
        drawerMode={drawerMode}
      />

      {!drawerMode && <SidebarDivider />}

      <Show
        when={allFavorites.length > 0 || isLoading}
        fallback={() => (
          <Stack
            mx='4'
            mt='4'
            p='3'
            bg='card'
            border='1px solid'
            borderColor='border'
            borderRadius='8px'
          >
            <Text color='lighterText' fontSize='xs' textAlign='center'>
              {t('favorites.empty.noMatchingFavorites')}
            </Text>
          </Stack>
        )}
      >
        <FavoriteFolderSection
          favorites={colorFavorites}
          title={`${colorFavorites.length} ${t('dashboard.welcome.collection.colorTones')}`}
          isLoading={isLoading}
          activeMaterialId={activeMaterialId}
          onClickItem={onClickItem}
        />

        <FavoriteFolderSection
          favorites={wallFavorites}
          title={`${wallFavorites.length} ${t('dashboard.welcome.collection.wallCoverings')}`}
          isLoading={isLoading}
          additionalHideCondition={
            !materialTypes || !materialTypes?.includes('wall')
          }
          activeMaterialId={activeMaterialId}
          onClickItem={onClickItem}
        />

        <FavoriteFolderSection
          favorites={floorFavorites}
          title={`${floorFavorites.length} ${t('dashboard.welcome.collection.floorCoverings')}`}
          isLoading={isLoading}
          additionalHideCondition={
            !materialTypes || !materialTypes?.includes('floor')
          }
          activeMaterialId={activeMaterialId}
          onClickItem={onClickItem}
        />
        <FavoriteFolderSection
          favorites={facadeFavorites}
          title={`${facadeFavorites.length} ${t('dashboard.welcome.collection.facades')}`}
          isLoading={isLoading}
          additionalHideCondition={
            !materialTypes || !materialTypes?.includes('facade')
          }
          activeMaterialId={activeMaterialId}
          onClickItem={onClickItem}
        />
      </Show>
    </VStack>
  );
};
