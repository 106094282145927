import { object, string, array, z } from 'zod';

export const AllCategoriesSchema = object({
  category: array(string()),
}).transform((data) =>
  data.category.map((category) => ({
    id: category,
    name: category,
  })),
);

export type Category = z.infer<typeof AllCategoriesSchema>[number];
