import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProMenuItem } from '$/components/core/Collection/CollectionFilter/components/ProMenuItem';
import { SingleFilter } from '$/components/core/Collection/CollectionFilter/components/SingleFilter';
import { FilterDropdown } from '$/components/core/Collection/CollectionFilter/FilterDropdown';
import { FilterGroupOption } from '$/services/usecases/filter';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  filter: FilterGroupOption;
}

export const SingleFilterDropdown: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();

  const active = filter
    ? useCollectionStore.getState().getActiveInCategory(filter?.searchName)
    : [];

  const title =
    active.length > 0
      ? active[0].displayName
      : t('inspirationEditor.filter.all');

  return (
    <FilterDropdown
      title={title}
      bg='bodyBackground'
      border='1px solid'
      borderColor='border'
      textAlign='start'
      h='6'
      w='full'
      borderRadius='4'
      color='lighterText'
      px='3'
      fontSize='xs'
    >
      {(onMenuClose) => (
        <>
          <SingleFilter
            onMenuClose={onMenuClose}
            options={filter.items ?? []}
            itemWidth='190px'
            compactMode
          />
          <ProMenuItem
            name={filter.displayNameKey != null ? t(filter.displayNameKey) : ''}
          />
        </>
      )}
    </FilterDropdown>
  );
};
