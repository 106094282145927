import { Box, HStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getEditorObjectIcon } from '$/pages/EditorPage/utils/getObjectItem';

interface Props {
  isActive: boolean;
}

export const ComponentBitmapItem: FC<Props> = ({ isActive }) => {
  const { t } = useTranslation();
  return (
    <HStack
      as='span'
      gap='1'
      h='10'
      pl='7'
      bg={isActive ? 'secondaryBackground' : 'transparent'}
    >
      {getEditorObjectIcon('Bitmap')}

      <Box
        as='span'
        pl='1'
        color='editor.objectText'
        fontSize='xs'
        userSelect='none'
      >
        {t('editor.bitmapLayer')}
      </Box>
    </HStack>
  );
};
