import { HStack, IconButton } from '@chakra-ui/react';
import { FC, useEffect } from 'react';

import { Icon } from '$/components/common/Icon';
import { FilterType } from '$/services/mapper/uses';
import {
  CollectionViewOptions,
  useCollectionStore,
} from '$/stores/useCollectionStore';

interface Props {
  filterType: FilterType;
}

export const CollectionViewOptionSelector: FC<Props> = ({ filterType }) => {
  const activeViewMode = useCollectionStore.useSelectedViewMode();
  const setViewMode = useCollectionStore.useSetSelectedViewMode();
  const filter = useCollectionStore.useFilterGroups();

  const hasCollectionFilter =
    filterType === 'uni' &&
    filter.filter((option) => option.type === 'collection').length > 0;

  useEffect(() => {
    if (!hasCollectionFilter && activeViewMode === 'fan') {
      setViewMode('grid');
    }
  }, [activeViewMode, hasCollectionFilter, setViewMode]);

  type Option = {
    name: CollectionViewOptions;
    isVisible: boolean;
  };

  const options: Option[] = [
    {
      name: 'grid',
      isVisible: true,
    },
    {
      name: 'fan',
      isVisible: hasCollectionFilter,
    },
    {
      name: 'list',
      isVisible: true,
    },
  ];

  return (
    <HStack gap='3'>
      {options.map((option) =>
        option.isVisible ? (
          <IconButton
            key={option.name}
            minW='0'
            minH='0'
            p='0'
            aria-label={option.name}
            icon={
              <Icon
                icon={option.name}
                height='24px'
                fill={activeViewMode === option.name ? 'text' : 'lighterText'}
                opacity={activeViewMode === option.name ? 1 : 0.3}
              />
            }
            onClick={() => setViewMode(option.name)}
            variant='text'
          />
        ) : undefined,
      )}
    </HStack>
  );
};
