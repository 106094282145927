import {
  ModalContent,
  ModalBody,
  VStack,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

interface RegisterSuccessModalContentProps {
  onClose: () => void;
}

export const RegisterSuccessModalContent: FC<
  RegisterSuccessModalContentProps
> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <ModalContent maxW='500px' bg='background' borderRadius='12px'>
      <ModalBody mt='8' px='10' pb='10'>
        <VStack gap='8'>
          <Heading>
            {t('dashboard.authentication.createAccountSuccessfulTitle')}
          </Heading>
          <Icon boxSize='86px' icon='leaderboard' />
          <Text fontSize='sm'>
            {t('dashboard.authentication.createAccountSuccessfulDescription')}
          </Text>
          <Button onClick={onClose}>
            {t('dashboard.authentication.loginButton')}
          </Button>
        </VStack>
      </ModalBody>
    </ModalContent>
  );
};
