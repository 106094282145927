import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { MaterialType } from '$/services/mapper/uses';
import { FavoriteFolder } from '$/stores/useFavoriteStore';

type State = {
  isModalOpen: boolean;
  modalFrom: 'overview' | 'favorites';
  modalType: 'overview' | 'favorites' | 'material';
  modalPayload: {
    folder?: FavoriteFolder;
    materialType?: MaterialType;
  };
  query: string;
};

type Actions = {
  onModalClose: () => void;
  onModalOpen: () => void;
  setModalType: (
    modalType: State['modalType'],
    modalFrom: State['modalFrom'],
    modalPayload?: State['modalPayload'],
  ) => void;
  reset: () => void;
};

const initial: State = {
  isModalOpen: false,
  modalType: 'overview',
  modalFrom: 'overview',
  modalPayload: {},
  query: '',
};

const store = create<State & Actions>()((set, get) => ({
  ...initial,
  onModalClose: () => set({ isModalOpen: false }),
  onModalOpen: () => set({ isModalOpen: true }),
  setModalType: (modalType, modalFrom, modalPayload = {}) =>
    set({ modalType, modalPayload, modalFrom }),
  reset: () => set({ ...initial, isModalOpen: get().isModalOpen }),
}));

export const useMaterialFullscreenStore = createSelectorHooks(store);
