import {
  Menu,
  MenuItem,
  Flex,
  Text,
  DrawerHeader,
  DrawerBody,
  Box,
  HStack,
  Button,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ProMenuItem } from '$/components/core/Collection/CollectionFilter/components/ProMenuItem';
import { useSwipeHook } from '$/hooks/useSwipeHook';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  selectedSubFilter: string;
  closeFilterDrawer: () => void;
  setSubFilter: (filterSearchName: string | null) => void;
  closeOnSelect?: boolean;
}

export const MobileSubFilterPage: FC<Props> = ({
  selectedSubFilter,
  closeFilterDrawer,
  setSubFilter,
  closeOnSelect = true,
}) => {
  const { t } = useTranslation();
  const possibleFilter = useCollectionStore.useFilter();
  const filterGroups = useCollectionStore.useFilterGroups();
  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();
  const resetFilterGroup = useCollectionStore.useResetFilterGroup();
  const swipeEvents = useSwipeHook({
    onSwipeVertical: (direction) => direction === 'down' && closeFilterDrawer(),
  });

  const filter = [
    ...(possibleFilter?.general ?? []),
    ...(possibleFilter?.additional ?? []),
  ].find((x) => x.searchName === selectedSubFilter);

  if (!filter) {
    return undefined;
  }

  const filterGroup = filterGroups.find((x) => x.type === selectedSubFilter);

  const isFilterSelected = (intName: string) =>
    filterGroup?.type === selectedSubFilter && filterGroup?.option === intName;

  const isNoFilterSelected = () => !filterGroup;

  return (
    <Box>
      <DrawerHeader px='3' pt='5' pb='0' {...swipeEvents}>
        <HStack justify='space-between'>
          <Button
            leftIcon={
              <Icon
                transform={'rotate(180deg)'}
                icon='arrow_right'
                width='13px'
                mr='2px'
              />
            }
            onClick={() => {
              if (selectedSubFilter === 'collection') {
                if (closeOnSelect) closeFilterDrawer();
              }
              setSubFilter(null);
            }}
            variant='ghost'
          >
            <Text fontSize='md'>{t(filter.displayNameKey)}</Text>
          </Button>

          <Button
            h='36px'
            fontSize='sm'
            onClick={() => closeFilterDrawer()}
            variant='text'
          >
            {t('general.cancel')}
          </Button>
        </HStack>
      </DrawerHeader>

      <DrawerBody p='3'>
        <Menu>
          <MenuItem
            p='4'
            fontWeight='bold'
            bg={isNoFilterSelected() ? 'secondaryBackground' : 'background'}
            borderRadius='4px'
            onClick={async () => {
              await resetFilterGroup(
                filter?.searchName ? [filter.searchName] : [],
              );
              if (closeOnSelect) closeFilterDrawer();
              if (!closeOnSelect) setSubFilter(null);
            }}
          >
            <Flex align='center' justify='space-between' w='full'>
              <Text fontSize='sm'>{t('dashboard.collection.show')}</Text>
              <Text color='lighterText' fontSize='xs'>
                {filter?.items.reduce((acc, item) => acc + item.amount, 0)}
              </Text>
            </Flex>
          </MenuItem>
          {filter?.items.map((item) => {
            return (
              <MenuItem
                key={item.intName}
                px='3'
                py='4'
                fontWeight='bold'
                bg={
                  isFilterSelected(item.intName)
                    ? 'secondaryBackground'
                    : 'background'
                }
                borderRadius='4px'
                onClick={async () => {
                  await activateFilterGroup(
                    {
                      displayName: item.displayName,
                      type: item.searchName,
                      option: item.intName,
                      payload: item.payload,
                    },
                    false,
                  );
                  if (closeOnSelect) closeFilterDrawer();
                  if (!closeOnSelect) setSubFilter(null);
                }}
              >
                <Flex align='center' justify='space-between' w='full'>
                  <Text fontSize='sm'>{item.displayName}</Text>
                  <Text color='lighterText' fontSize='xs'>
                    {item.amount}
                  </Text>
                </Flex>
              </MenuItem>
            );
          })}
          <ProMenuItem name={t(filter.displayNameKey)} isMobile />
        </Menu>
      </DrawerBody>
    </Box>
  );
};
