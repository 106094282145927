import { TFunction } from 'i18next';

import { unsecureSeededIntRange } from '$/utils/numberUtils';
import {
  containsLowercase,
  containsNumber,
  containsSpecialChar,
  containsUppercase,
} from '$/utils/regexUtils';

export const randomPlaceholder = (seed: number, from: number, to: number) => {
  return 'X'.repeat(unsecureSeededIntRange(seed, from, to));
};

export const getInitials = (firstName: string, lastName: string) => {
  return firstName.charAt(0) + lastName.charAt(0);
};

export const validatePassword = (
  password: string,
  t: TFunction<'translation', undefined>,
) => {
  const errors: string[] = [];

  if (password.length < 10) {
    errors.push(
      t('dashboard.authentication.errorMessages.minimumCharacters', {
        count: 10,
      }),
    );
  }

  if (!containsLowercase(password) || !containsUppercase(password)) {
    errors.push(t('dashboard.authentication.errorMessages.highAndLowCase'));
  }

  if (!containsSpecialChar(password)) {
    errors.push(
      t('dashboard.authentication.errorMessages.minimumSpecialCharacter'),
    );
  }

  if (!containsNumber(password)) {
    errors.push(t('dashboard.authentication.errorMessages.minimumNumber'));
  }

  return errors;
};

export const shortenText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;

  return `${text.slice(0, maxLength)}...`;
};

export const capitalizeSentence = (sentence: string) => {
  return sentence
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const removeSpecialCharacters = (str: string) => {
  return str.replace(/[^a-zA-Z0-9-_\s()]/g, '');
};
