import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type ProfileModals =
  | 'profile'
  | 'change_password'
  | 'change_password_success'
  | 'delete_account';

type State = {
  modalType: ProfileModals;
  isModalOpen: boolean;
};

type Action = {
  setModalType: (type: ProfileModals) => void;
  onModalOpen: () => void;
  onModalClose: () => void;
  reset: () => void;
};

const initial: State = {
  modalType: 'profile',
  isModalOpen: false,
};

export const store = create<State & Action>()(
  persist(
    (set) => ({
      ...initial,
      setModalType: (modalType) => set({ modalType }),
      onModalClose: () => set({ isModalOpen: false, modalType: 'profile' }),
      onModalOpen: () => set({ isModalOpen: true }),
      reset: () => set(initial),
    }),
    {
      name: 'profile-store',
      partialize: (state) => ({
        isModalOpen: state.isModalOpen,
        modalType: state.modalType,
      }),
    },
  ),
);

export const useProfileStore = createSelectorHooks(store);
