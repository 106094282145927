import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { MaterialType } from '$/services/mapper/uses';

export type State = {
  activeMaterialId: string | null;
  materialType: MaterialType | undefined;
  memorizedCheckedItems: Record<string, string[]>;
  menuLayer: MenuLayer | undefined;
};

export type MenuLayer = 'dashboard' | 'inspirationModal';

type Actions = {
  setActiveMaterial: (
    id: string | null,
    materialType?: MaterialType,
    menuLayer?: MenuLayer,
  ) => void;
  memorizeCheckedItems: (checkedItems: string[]) => void;
  getMemorizedCheckedItems: () => string[] | undefined;
  deleteMemorizedCheckedItems: () => void;
};

export const initial: State = {
  activeMaterialId: null,
  materialType: undefined,
  memorizedCheckedItems: {},
  menuLayer: undefined,
};

export const store = create<State & Actions>()((set, get) => ({
  ...initial,
  setActiveMaterial: (id, type, menuLayer) =>
    set((state) => ({
      ...state,
      activeMaterialId: id,
      materialType: type,
      menuLayer,
    })),

  memorizeCheckedItems: (checkedItems) => {
    const { activeMaterialId, materialType, memorizedCheckedItems } = get();

    if (activeMaterialId === null || materialType === undefined) return;

    set({
      memorizedCheckedItems: {
        ...memorizedCheckedItems,
        [`${activeMaterialId}_${materialType}`]: checkedItems,
      },
    });
  },

  getMemorizedCheckedItems: () => {
    const { activeMaterialId, materialType, memorizedCheckedItems } = get();

    if (activeMaterialId === null || materialType === undefined) {
      return undefined;
    }

    const key: string = `${activeMaterialId}_${materialType}`;
    return memorizedCheckedItems[key];
  },

  deleteMemorizedCheckedItems: () => {
    const { activeMaterialId, materialType, memorizedCheckedItems } = get();

    if (activeMaterialId === null || materialType === undefined) return;

    const key: string = `${activeMaterialId}_${materialType}`;
    const newMemorizedCheckedItems = { ...memorizedCheckedItems };
    delete newMemorizedCheckedItems[key];

    set({ memorizedCheckedItems: newMemorizedCheckedItems });
  },
}));

export const useMaterialContextMenuStore = createSelectorHooks(
  store,
) as typeof store & ZustandHookSelectors<State & Actions>;
