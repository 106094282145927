import { ModalHeader, ModalHeaderProps, Stack } from '@chakra-ui/react';
import { FC } from 'react';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { Icon } from '$/components/common/Icon';
import {
  PiwikContentName,
  PiwikContentPiece,
  PiwikContentTarget,
} from '$/utils/piwikUtils';

interface ModalHeaderWithBackButtonProps extends ModalHeaderProps {
  headerText?: string | null;
  backButtonText?: string | null;
  displayBackButton?: boolean | null;
  trackingContentName?: PiwikContentName;
  trackingContentPiece?: PiwikContentPiece;
  trackingContentTarget?: PiwikContentTarget;
  onBackButtonClick?: () => void | null;
}

export const ModalHeaderWithBackButton: FC<ModalHeaderWithBackButtonProps> = ({
  headerText,
  backButtonText,
  displayBackButton = false,
  trackingContentName,
  trackingContentPiece,
  trackingContentTarget,
  onBackButtonClick,
  px,
  pt,
  fontSize,
  ...props
}) => {
  if (displayBackButton) {
    return (
      <ModalHeader
        {...props}
        px={px ?? 10}
        pt={pt ?? 10}
        fontSize={fontSize ?? 'h7'}
      >
        <Stack pb='2'>
          <TrackedButton
            justifyContent='flex-start'
            gap='2'
            px='0'
            fontSize='sm'
            leftIcon={
              <Icon icon='arrow_right' w='14px' transform='rotate(180deg)' />
            }
            onClick={onBackButtonClick}
            variant='text'
            contentName={trackingContentName}
            contentPiece={trackingContentPiece}
            contentTarget={trackingContentTarget}
          >
            {backButtonText}
          </TrackedButton>
        </Stack>

        {headerText}
      </ModalHeader>
    );
  } else {
    return (
      <ModalHeader
        {...props}
        px={px ?? 10}
        pt={pt ?? 10}
        fontSize={fontSize ?? 'h7'}
      >
        {headerText}
      </ModalHeader>
    );
  }
};
