import {
  Box,
  DrawerHeader,
  HStack,
  Button,
  Text,
  DrawerBody,
  Stack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorBox } from '$/components/core/Editor/ColorSelectionSidebar/ColorBox';
import { useSwipeHook } from '$/hooks/useSwipeHook';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { materialsQuery } from '$/services/usecases/materials/queries';

interface Props {
  closeFilterDrawer: () => void;
  onItemSelect: (id: number) => void;
}

export const ComponentDisplayContent: FC<Props> = ({
  closeFilterDrawer,
  onItemSelect,
}) => {
  const { t } = useTranslation();

  const components = useEditorStore.useComponents();
  const activeComponentIndex = useEditorStore.useActiveComponentIndex();

  const swipeEvents = useSwipeHook({
    onSwipeVertical: (direction) => direction === 'down' && closeFilterDrawer(),
  });

  const materialIds = components
    .map((component) => component.materialId)
    .filter(Boolean);

  const { data } = useQuery(materialsQuery({ ids: materialIds }, true));
  const materials = data?.materials;

  return (
    <Box>
      <DrawerHeader px='3' pt='5' {...swipeEvents}>
        <HStack alignItems='baseline' justify='space-between'>
          <Text pl='3' fontSize='md'>
            {t('general_editor.layers')}
          </Text>
          <Button
            h='36px'
            fontSize='sm'
            onClick={() => closeFilterDrawer()}
            variant='text'
          >
            {t('general.cancel')}
          </Button>
        </HStack>
      </DrawerHeader>

      <DrawerBody px='3'>
        <Stack gap='3' px='1'>
          {materials &&
            components.map((component) => {
              const material = materials.find(
                (material) => material.id === component.materialId,
              );

              return (
                <HStack
                  key={component.index}
                  gap='2'
                  w='full'
                  p='2'
                  bg={
                    activeComponentIndex === component.index
                      ? 'secondaryBackground'
                      : undefined
                  }
                  borderRadius='4px'
                  onClick={() => onItemSelect(component.index)}
                >
                  <ColorBox
                    materialColor={material?.color}
                    image={material?.url()}
                    w='16px'
                    h='16px'
                  />
                  <Text fontSize='xs'>{component.name}</Text>
                </HStack>
              );
            })}
        </Stack>
      </DrawerBody>
    </Box>
  );
};
