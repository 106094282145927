import { Box, HStack } from '@chakra-ui/react';
import { FC } from 'react';

import { ActionGroup } from '$/components/core/Editor/ActionBar/components/ActionGroup';
import { HorizontalGroup } from '$/components/core/Editor/ActionBar/components/HorizontalGroup';
import { EditorActionGroup } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useKeyPress } from '$/hooks/useKeyPress';
import { EditorZoomSection } from '$/pages/EditorPage/components/ActionbarSection/components/EditorZoomSection';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { EditorComponent } from '$/services/usecases/editor/mapper/editorStatus';

interface Props {
  groups: EditorActionGroup[];
}

const filterGroups = (
  groups: EditorActionGroup[],
  component: EditorComponent | null,
) => {
  if (component == null || component?.mode !== 'bitmap') {
    return groups;
  }

  return groups
    .map((group) => ({
      ...group,
      actions: group.actions.filter((action) => action.isPolyOnly !== true),
    }))
    .filter((group) => group.actions.length > 0);
};

export const ActionBarSection: FC<Props> = ({ groups }) => {
  const activeComponentIndex = useEditorStore.useActiveComponentIndex();
  const components = useEditorStore.useComponents();
  const { selectAction } = useEditorActions();
  const isFullscreen = useEditorStore.useIsFullScreen();

  useKeyPress(
    ({ key }) =>
      key === 'Escape' && selectAction(ModuleSendSelectAction.SelectPoly),
  );

  const filteredGroups = filterGroups(
    groups,
    activeComponentIndex == null ? null : components[activeComponentIndex],
  );

  return (
    <HStack
      as='section'
      layerStyle='FloatingActionBar'
      top={isFullscreen ? '-50%' : '0'}
      px='16'
      transition='top 0.5s ease'
    >
      <HStack gap='0' h='full'>
        {filteredGroups.map(
          (group) =>
            !(activeComponentIndex === null && group.needComponent) && (
              <HStack key={group.id} gap='0' h='100%'>
                <Box display={{ base: 'none', xl: 'flex' }}>
                  <HorizontalGroup group={group} />
                </Box>

                <Box display={{ base: 'flex', xl: 'none' }} mr='2'>
                  <ActionGroup group={group} />
                </Box>

                <Box
                  w='2px'
                  h='40%'
                  mx='3'
                  bg='editor.actionBarDivider'
                  borderRadius='2px'
                />
              </HStack>
            ),
        )}

        <EditorZoomSection />
      </HStack>
    </HStack>
  );
};
