export type MaterialType = 'wall' | 'floor' | 'uni' | 'facade';

export type FilterType = 'wall' | 'floor' | 'all' | 'uni' | 'facade';

export const mapFilterTypeToMaterialTypes = (type: FilterType) => {
  return {
    uni: ['uni'],
    floor: ['floor'],
    wall: ['wall'],
    facade: ['facade'],
    all: ['uni', 'floor', 'wall'],
  }[type] as MaterialType[];
};

export const mapTypeToUses = (type: MaterialType) => {
  return { uni: '5', floor: '7', wall: '8', facade: '181' }[type];
};

export const mapUsesToType = (uses: string): MaterialType[] => {
  return uses
    .split(',')
    .filter((use) => ['5', '7', '8', '181'].includes(use))
    .map((use) => {
      return { '5': 'uni', '7': 'floor', '8': 'wall', '181': 'facade' }[
        use
      ] as MaterialType;
    });
};
