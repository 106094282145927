import { FC, useState } from 'react';

import { SingleMenuItem } from '$/components/core/Collection/CollectionFilter/components/SingleMenuItem';
import { FilterOption } from '$/services/usecases/filter/mapper/searchValues';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  options: FilterOption;
  onMenuClose: () => void;
  hideAmounts?: boolean;
  itemWidth?: string;
  compactMode?: boolean;
}

export const SingleFilter: FC<Props> = ({
  options,
  onMenuClose,
  hideAmounts,
  itemWidth = '16rem',
  compactMode = false,
}) => {
  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();
  const isFilterActive = useCollectionStore.useIsFilterActive();

  const [loadingItem, setLoadingItem] = useState('');

  return options.map((option) => {
    const activate = () =>
      activateFilterGroup({
        type: option.searchName,
        option: option.intName,
        displayName: option.displayName,
      });

    return (
      <SingleMenuItem
        key={option.intName}
        name={option.displayName}
        isLoading={loadingItem === option.intName}
        isActive={isFilterActive(option.searchName, option.intName)}
        amount={hideAmounts ? undefined : option.amount}
        onClick={async () => {
          setLoadingItem(option.intName);
          await activate().finally(() => setLoadingItem(''));
          onMenuClose();
        }}
        w={itemWidth}
        compactMode={compactMode}
      />
    );
  });
};
