import { FC, ReactElement } from 'react';

import { i18n } from '$/components/core/Localization/18n';
import de from '$/translations/de.json';
import en from '$/translations/en.json';

interface Props {
  children: ReactElement;
}

export const LocalizationProvider: FC<Props> = ({ children }) => {
  if (!i18n.isInitialized) {
    void i18n.init({
      debug: true,
      resources: { de, en },
      fallbackLng: 'de',
      detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'],
      },
    });
  }

  return children;
};
