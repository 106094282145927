import { SeverityLevel } from '@microsoft/applicationinsights-web';
import PiwikPro, {
  CustomEvent,
  ContentTracking,
} from '@piwikpro/react-piwik-pro';

import { getApplicationInsights } from '$/components/core/ApplicationInsights/utils/insights';
import { TypedToOptions } from '$/components/core/StyledLink';
import {
  PiwikContentInteraction,
  PiwikContentName,
  PiwikContentPiece,
  PiwikContentTarget,
  PiwikEventAction,
  PiwikEventCategory,
  mapPathnameToPage,
  parseContentPiece,
  parseContentTarget,
  setDataLayerValues,
} from '$/utils/piwikUtils';

export const setPageViewInformation = (
  pathname: string,
  projectCount: number | null = null,
) => {
  const { dimensions, pageTitle } = mapPathnameToPage(
    pathname as TypedToOptions,
  );

  document.title = `SPECTRUM | ${pageTitle}`;

  setDataLayerValues(dimensions, pathname, projectCount);
};

export const trackEvent = (
  category: PiwikEventCategory,
  action: PiwikEventAction,
  name?: string,
  value?: number,
) => {
  CustomEvent.trackEvent(category, action, name, value);
};

export const trackContentImpression = (
  contentName: PiwikContentName,
  contentPiece: PiwikContentPiece,
  contentTarget: PiwikContentTarget,
) => {
  ContentTracking.trackContentImpression(
    contentName,
    parseContentPiece(contentPiece),
    parseContentTarget(contentTarget),
  );
};

export const trackContentInteraction = (
  contentInteraction: PiwikContentInteraction,
  contentName: PiwikContentName,
  contentPiece: PiwikContentPiece,
  contentTarget: PiwikContentTarget,
) => {
  ContentTracking.trackContentInteraction(
    contentInteraction,
    contentName,
    parseContentPiece(contentPiece),
    parseContentTarget(contentTarget),
  );
};

export const logError = (error: unknown) => {
  getApplicationInsights()?.appInsights.trackException({
    exception: error as Error,
    severityLevel: SeverityLevel.Error,
  });

  console.error(error);
};

export const enablePiwik = () => {
  const id = import.meta.env.VITE_PIWIK_CONTAINER_ID;
  const url = import.meta.env.VITE_PIWIK_CONTAINER_URL;

  if (id == null || url == null) {
    console.warn('Visiting not recorded');
    return;
  }

  PiwikPro.initialize(id, url);
};
