import { useQuery } from '@tanstack/react-query';

import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { materialsQuery } from '$/services/usecases/materials/queries';

export const useActiveEditorMaterial = () => {
  const components = useEditorStore.useComponents();
  const activeComponentId = useEditorStore.useActiveComponentIndex();

  const activeComponent =
    activeComponentId == null ? null : components[activeComponentId];

  const { data } = useQuery(
    materialsQuery({
      ids: activeComponent?.materialId
        ? [activeComponent?.materialId]
        : undefined,
    }),
  );

  return data?.materials.length === 1 ? data.materials[0] : null;
};
