import { HStack, Skeleton, StackProps } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { randomPlaceholder } from '$/utils/stringUtils';

interface Props extends StackProps {
  layerId: number;
}

export const LoadingColorLayer: FC<Props> = ({ layerId, ...props }) => {
  return (
    <HStack cursor='pointer' {...props}>
      <HStack gap='1'>
        <Icon icon='other_objects' boxSize='4' color='lighterText' />
        <Skeleton layerStyle='ColorImage' boxSize='16px' borderRadius='2px' />
      </HStack>
      <Skeleton fontSize='12px' lineHeight='16px'>
        {randomPlaceholder(layerId, 3, 10)}
      </Skeleton>
    </HStack>
  );
};
