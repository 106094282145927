import { Tooltip } from '@chakra-ui/react';
import { FC } from 'react';

export interface ActionBarTooltipProps {
  children: JSX.Element;
  label: string;
}

export const ActionBarTooltip: FC<ActionBarTooltipProps> = ({
  children,
  label,
}) => {
  return (
    <Tooltip
      color='text'
      fontSize='xs'
      bg='background'
      border='1px solid'
      borderColor='border'
      borderRadius='2px'
      label={label}
    >
      {children}
    </Tooltip>
  );
};
