import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { NavbarLink } from '$/components/layouts/BaseDashboardLayout/components/NavbarLink';
import { useInspirationStore } from '$/pages/DashboardPages/pages/Inspiration/stores/useInspirationStore';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

interface Props {
  onClick?: VoidFunction;
}

export const NavbarLinks: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  const { hasPermission } = useAuthorization();

  const setActiveFolderId = useFavoriteStore.useSetActiveFolderId();
  const resetFilters = useCollectionStore.useResetFilter();
  const selectCategory = useInspirationStore.useSelectCategory();

  const onInspirationClick = () => {
    selectCategory(null);

    if (onClick) {
      onClick();
    }
  };

  const onFavoritesClick = async () => {
    setActiveFolderId(null);
    await resetFilters();

    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <NavbarLink
        icon={<Icon icon='home' w='18px' />}
        title={t('dashboard.navbar.welcomeLink')}
        to='/'
        onClick={onClick}
      />
      {hasPermission('General_Project_Page') && (
        <NavbarLink
          icon={<Icon icon='projects' w='24px' />}
          title={t('dashboard.navbar.projectsLink')}
          to='/projects'
          onClick={onClick}
        />
      )}
      <NavbarLink
        icon={<Icon icon='lightbulb' w='24px' h='24px' />}
        title={t('dashboard.navbar.inspirationLink')}
        to='/inspirations'
        onClick={onInspirationClick}
      />
      <NavbarLink
        icon={<Icon icon='collection' w='24px' h='20px' />}
        title={t('dashboard.navbar.collectionsLink')}
        to='/collections'
        onClick={onClick}
      />
      <NavbarLink
        icon={<Icon icon='like' w='24px' h='20px' />}
        title={t('dashboard.navbar.favoritesLink')}
        to='/favorites'
        onClick={onFavoritesClick}
      />
    </>
  );
};
