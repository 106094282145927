import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ActionCard } from '$/components/core/Projects/components/Cards/ActionCard';

export const UploadPictureCard = () => {
  const { t } = useTranslation();

  return (
    <Link to='/editor'>
      <ActionCard
        icon={<Icon icon='add_photo' h='8' w='8' />}
        headerText={t('dashboard.welcome.ownImage')}
        lowerText={t('dashboard.welcome.uploadAndCustomize')}
        background='bodyBackground'
        color='text'
        aspectRatio={{ base: '6/2', mobile: '3/2' }}
      />
    </Link>
  );
};
