import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { Icon } from '$/components/common/Icon';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { indexToLetter } from '$/utils/numberUtils';
interface DeleteButtonProps {
  buttonIndex: number;
  onClose: () => void;
}

export const DeleteButton: FC<DeleteButtonProps> = ({
  buttonIndex,
  onClose,
}) => {
  const { t } = useTranslation();
  const deleteSceneVariant = useInspirationEditorStore.useDeleteSceneVariant();
  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();

  const handleImageDelete = (index: number) => {
    deleteSceneVariant(index);
    onClose();
  };

  return (
    <TrackedButton
      h='32px'
      minH='0'
      fontSize='sm'
      fontWeight='medium'
      bg='editor.deletePopoverBg'
      isDisabled={sceneLayersVariants.length === 1}
      leftIcon={<Icon icon='trash_can' w='14px' />}
      onClick={() => handleImageDelete(buttonIndex)}
      variant='secondary'
      contentInteraction='ClickVariants'
      contentName='Variants'
      contentPiece='DeleteVariante'
      contentTarget={{
        targetName: indexToLetter(buttonIndex),
      }}
    >
      {t('editor.delete_variant')}
    </TrackedButton>
  );
};
