import { HStack, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { VisualizationBox } from '$/pages/EditorPage/components/AttributesSection/components/VisualizationBox';
import { quickColors } from '$/services/usecases/editor/quickColors';
import { materialsQuery } from '$/services/usecases/materials/queries';

export const VisualizationSection = () => {
  const { t } = useTranslation();

  const { data } = useQuery(
    materialsQuery({ ids: quickColors.flat() }, true, true),
  );

  return (
    <VStack align='flex-start' w='full' mb='1' px='4'>
      <SectionTitle icon='color_palette' title={t('editor.visualization')} />
      {quickColors.map((optionRow) => (
        <HStack key={optionRow.join('-')}>
          {optionRow.map((option) => {
            const material = data?.materials.find(
              (material) => material.uniqueKey === option,
            );

            return (
              <VisualizationBox
                key={option}
                materialId={material?.id}
                color={material?.color}
              />
            );
          })}
        </HStack>
      ))}
    </VStack>
  );
};
