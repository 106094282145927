import { Box, BoxProps, HStack, StackProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props extends StackProps {
  wrapper: BoxProps;
  children: ReactNode;
}

export const FloatingCard: FC<Props> = ({ wrapper, children, ...props }) => {
  return (
    <Box pos='absolute' w='full' {...wrapper}>
      <HStack
        overflow='hidden'
        w='full'
        bg='background'
        border='1px solid'
        borderColor='border'
        borderRadius='12px'
        {...props}
      >
        {children}
      </HStack>
    </Box>
  );
};
