import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { getApplicationInsights } from '$/components/core/ApplicationInsights/utils/insights';

export const AppInsightsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const instance = getApplicationInsights();

  if (!instance) {
    console.info('Application Insights not initialized');
    return <>{children}</>;
  }

  console.info('Application Insights initialized');

  return (
    <AppInsightsContext.Provider value={instance.reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};
