import { HStack, Stack, Box, ModalCloseButton } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ThemeSwitcher } from '$/components/common/ThemeSwitcher';
import { CollectionViewOptionSelector } from '$/components/core/Collection/CollectionViewOptionSelector';
import { MaterialListItem } from '$/components/core/Collection/MaterialListItem';
import { FilterTags } from '$/components/core/Collection/MaterialPresentation/DataSortingRow/components/FilterTags';
import { SearchInput } from '$/components/core/Collection/MaterialSearch/SearchInput';
import { AttributeHeader } from '$/components/core/Editor/AttributeHeader';
import { FullscreenBackLink } from '$/components/core/Editor/MaterialFullscreen/component/FullscreenBackLink';
import { useMaterialFullscreenStore } from '$/components/core/Editor/MaterialFullscreen/store/useMaterialFullscreenStore';
import { FilterType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';

interface Props {
  material?: Material | null;
}

export const FullscreenSearchHeader: FC<Props> = ({ material }) => {
  const { t } = useTranslation();

  const modalType = useMaterialFullscreenStore.useModalType();
  const modalPayload = useMaterialFullscreenStore.useModalPayload();
  const query = useMaterialFullscreenStore.useQuery();

  const favoriteTitle =
    modalPayload?.folder?.name ?? t('general_editor.favorites');
  const materialTitle = t(
    `general_editor.materialTypes.${modalPayload.materialType ?? 'uni'}`,
  );

  const title = modalType === 'favorites' ? favoriteTitle : materialTitle;

  const searchLabel = t('general_editor.search', {
    search:
      modalType === 'favorites' ? t('general_editor.favorites') : materialTitle,
  });

  return (
    <Stack as={Stack} gap='4' mb='6' px='8' pt='8'>
      <HStack justify='space-between' h='32px'>
        <FullscreenBackLink />
        <ModalCloseButton pos='static' />
      </HStack>

      <HStack justify='space-between' flexWrap='wrap'>
        <Box as='span' fontSize='h7' fontWeight='bold'>
          {title}
        </Box>
        <HStack gap='8'>
          <SearchInput
            searchLabel={searchLabel}
            h='12'
            w='60'
            query={query}
            setQuery={(query) => useMaterialFullscreenStore.setState({ query })}
          />
          <Box h='8'>
            <ThemeSwitcher />
          </Box>
        </HStack>
      </HStack>

      {material && modalType !== 'material' && (
        <>
          <AttributeHeader
            icon={<Icon w='4' h='4' icon='inspiration_marker' />}
            label={t('inspirationEditor.attributes.usedColor')}
          />
          <MaterialListItem material={material} />
        </>
      )}

      <HStack justify='flex-end' w='full'>
        {modalType === 'material' && (
          <CollectionViewOptionSelector
            filterType={modalPayload.materialType! as FilterType}
          />
        )}
      </HStack>

      <FilterTags />
    </Stack>
  );
};
