import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerContentProps,
} from '@chakra-ui/react';
import { FC } from 'react';

import { isSafari } from '$/utils/userAgent';

interface Props extends DrawerContentProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const MobileDrawer: FC<Props> = ({
  children,
  isOpen,
  onClose,
  ...rest
}) => {
  return (
    <Drawer
      // Drawer uses autoFocus to focus the first focusable element in the drawer, which creates bugs on ios
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={!isSafari()}
      isOpen={isOpen}
      onClose={onClose}
      placement='bottom'
      size='full'
    >
      <DrawerOverlay />
      <DrawerContent
        overflowY='auto'
        maxH='98dvh'
        bg='background'
        borderRadius='12px 12px 0 0'
        {...rest}
      >
        {children}
      </DrawerContent>
    </Drawer>
  );
};
