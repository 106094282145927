import { literal, number, string, union, z } from 'zod';

import { cloudMessageOptions } from '$/hooks/useCloudMessageTranslation';

export type GeneralError = {
  status: number;
  error_code?: number | undefined;
  msg?: string | undefined;
};

export type CloudErrorCode =
  | -1
  | 1001
  | 1002
  | 1003
  | 1004
  | 1005
  | 1006
  | 1007
  | 1008
  | 1009
  | 1010
  | 1011
  | 1012
  | 1013
  | 1014
  | 1015;

export const GeneralVeeuzeAuthResponse = {
  status: union([literal(1), literal(0)]),
  error_code: number().lte(1015).gte(1001).or(literal(-1)).optional(),
  msg: string().optional(),
};

export const parseErrorResult = <T>(
  data: GeneralError,
  properties: (data: unknown) => T,
) => {
  if (data.status === 0) {
    return {
      isSuccessful: false,
      error: {
        code: data.error_code as CloudErrorCode,
        message: data.msg,
      },
    } as const;
  }

  return {
    isSuccessful: true,
    error: null,
    ...properties(data),
  } as const;
};

export type Successful<T> = Extract<T, { isSuccessful: true }>;
export type Failed<T> = Extract<T, { isSuccessful: false }>;
export type ForcedElement<T> =
  T extends Array<infer U>
    ? Required<NonNullable<U>>
    : Required<NonNullable<T>>;

export const GeneralBackendResponseSchema = {
  message: z.enum(cloudMessageOptions),
};

export const GeneralBackendResponseObjectSchema = z.object({
  ...GeneralBackendResponseSchema,
});

export type GeneralBackendResponse = z.infer<
  typeof GeneralBackendResponseObjectSchema
>;
