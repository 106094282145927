import { Center } from '@chakra-ui/react';
import { FC, MouseEvent } from 'react';

import { Icon } from '$/components/common/Icon';
import { TrackedSection } from '$/components/common/TrackedSection';
import { useNonFreeAction } from '$/hooks/useNonFreeAction';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface AddVariantButtonProps {
  variantName: string;
}

export const AddVariantButton: FC<AddVariantButtonProps> = ({
  variantName,
}) => {
  const addSceneVariant = useInspirationEditorStore.useAddSceneVariant();
  const handleNonFreeAction = useNonFreeAction();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    addSceneVariant();
  };

  return (
    <TrackedSection
      contentInteraction='ClickVariants'
      contentName='Variants'
      contentPiece='NewVariante'
      contentTarget={{
        targetName: variantName,
      }}
    >
      <Center
        layerStyle='SceneImage'
        border='1px solid'
        borderColor='border'
        borderRadius='4px'
        cursor='pointer'
        aspectRatio='3/2'
        onClick={(event: MouseEvent<HTMLDivElement>) =>
          handleNonFreeAction(() => handleClick(event))
        }
      >
        <Icon icon='add_big' />
      </Center>
    </TrackedSection>
  );
};
