import { Box, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { MaterialListItem } from '$/components/core/Collection/MaterialListItem';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { SidebarDivider } from '$/pages/EditorPage/components/AttributesSection/components/SidebarDivider';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { materialsQuery } from '$/services/usecases/materials/queries';

export const CurrentColorSection = () => {
  const { t } = useTranslation();
  const activeComponentId = useEditorStore.useActiveComponentIndex();
  const components = useEditorStore.useComponents();
  const { selectComponent, selectAction } = useEditorActions();

  const activeComponent =
    activeComponentId == null ? null : components[activeComponentId];

  const { data } = useQuery(
    materialsQuery({
      ids: activeComponent?.materialId
        ? [activeComponent?.materialId]
        : undefined,
    }),
  );

  if (activeComponent?.materialId == null || data?.materials.length === 0)
    return;

  const material = data?.materials.length === 1 ? data.materials[0] : null;

  const onColorSelect = () => {
    if (activeComponentId == null) return;
    selectComponent(activeComponentId);
    selectAction(ModuleSendSelectAction.SelectPoly);
  };

  return (
    <>
      <VStack align='flex-start' gap='3' w='full' mt='0' mb='1' px='4'>
        <FilterHeader
          header={t('inspirationEditor.attributes.usedColor')}
          isActive={true}
          prefix={<Icon icon='inspiration_marker' w='17px' />}
        />
        <Box cursor='pointer' onClick={onColorSelect}>
          <MaterialListItem id={1} material={material} />
        </Box>
      </VStack>
      <SidebarDivider />
    </>
  );
};
