import { HStack, Stack, StackProps, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { MaterialListItemSkeleton } from '$/components/core/Collection/MaterialListItemSkeleton';
import { ColorBox } from '$/components/core/Editor/ColorSelectionSidebar/ColorBox';
import { Material } from '$/services/usecases/materials';
import { unsecureIntRange } from '$/utils/numberUtils';

interface Props {
  id?: number;
  material?: Material | null;
  showAsActive?: boolean;
  size?: 'sm' | 'md';
  onClick?: () => void;
}

export const MaterialListItem: FC<Props> = ({
  material,
  id = unsecureIntRange(0, 1000),
  showAsActive = false,
  size = 'sm',
  onClick,
}) => {
  if (!material) return <MaterialListItemSkeleton id={id} />;

  const clickableElementProps: StackProps =
    onClick != null ? { onClick, cursor: 'pointer', as: 'button' } : {};

  return (
    <HStack {...clickableElementProps} textAlign='start'>
      <ColorBox
        w='6'
        h='6'
        materialColor={material.color}
        image={material.url({ width: '30', height: '30' })}
        border='1px solid'
        borderColor={showAsActive ? 'text' : 'border'}
      />
      <Stack gap='2px'>
        <Text
          color='text'
          fontSize={size === 'md' ? 'md' : 'sm'}
          lineHeight='1.2'
        >
          {material.info}
        </Text>
        <Text
          color='lighterText'
          fontSize={size === 'md' ? 'sm' : 'xs'}
          lineHeight='1.2'
        >
          {material.collection}
        </Text>
      </Stack>
    </HStack>
  );
};
