import { Box, Center } from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { useControls, useTransformEffect } from 'react-zoom-pan-pinch';

import { useActionBarStore } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useIsMobile } from '$/hooks/useIsMobile';
import { useKeyPress } from '$/hooks/useKeyPress';
import { FullscreenButton } from '$/pages/InspirationEditorPage/components/ImageSection/components/FullscreenButton';
import { ImageMarkers } from '$/pages/InspirationEditorPage/components/ImageSection/components/ImageMarkers';
import { RenderedInspirationImage } from '$/pages/InspirationEditorPage/components/RenderedInspirationImage';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props {
  id: string;
}

export const ZoomableImage: FC<Props> = ({ id }) => {
  const isMobile = useIsMobile();

  const [currentZoom, setCurrentZoom] = useState(1);
  const setZoomControls = useActionBarStore.useSetZoomControls();
  const syncLibraryState = useActionBarStore.useSyncLibraryState();

  const { zoomIn, zoomOut, resetTransform, setTransform, centerView } =
    useControls();
  const activeViewIndex = useInspirationEditorStore.useActiveViewIndex();

  const [isImageLoading, setIsImageLoading] = useState(true);
  const [hasLoadedInitialImage, setHasLoadedInitialImage] = useState(false);

  const dragged = useRef(false);
  const wrapper = useRef<HTMLDivElement | null>(null);

  const togglePerspectiveFullscreen =
    useInspirationEditorStore.useTogglePerspectiveFullscreen();
  const perspectiveFullscreen =
    useInspirationEditorStore.usePerspectiveFullscreen();

  const activeSceneVariant = useInspirationEditorStore((state) =>
    state.getActiveSceneVariant(),
  );

  useKeyPress(({ key }) => {
    if (key === 'Escape') {
      perspectiveFullscreen && togglePerspectiveFullscreen();
    }
  });

  useTransformEffect(({ state, instance }) => {
    syncLibraryState(
      state.positionX,
      state.positionY,
      state.scale,
      instance.wrapperComponent,
    );
    setCurrentZoom(state.scale);
  });

  useEffect(() => {
    setZoomControls({
      zoomIn,
      zoomOut,
      resetZoom: resetTransform,
      setTransform,
    });
    setTimeout(() => centerView(isMobile ? 3 : 1, 0), 0);
    // We don't want the functions to be a direct dependency here. If we would add all of these,
    // we would create an infinite update loop, which is not what we want here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getImageResolution = (zoomLevel: number) => {
    if (zoomLevel >= 4) return { width: 3000, height: 3000 };

    if (zoomLevel >= 2) return { width: 2000, height: 2000 };

    return { width: 1500, height: 1500 };
  };

  useEffect(() => {
    if (!wrapper.current) return;

    const width = wrapper.current.offsetWidth;
    const height = wrapper.current.offsetHeight;
    const aspectRatio = width / height;

    const maxWindowWidth = window.innerWidth - 25;
    const maxWindowHeight = window.innerHeight - 25;

    if (perspectiveFullscreen) {
      if (maxWindowWidth / aspectRatio > maxWindowHeight) {
        centerView(maxWindowHeight / height);
      }

      if (maxWindowHeight * aspectRatio > maxWindowWidth) {
        centerView(maxWindowWidth / width);
      }
    }

    if (!perspectiveFullscreen) {
      useActionBarStore.getState().resetZoom();
    }
    // this lead to a lot of unnecessary updates which always resetted the zoom level
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perspectiveFullscreen]);

  return (
    <Center
      w='100vw'
      h='100dvh'
      onClick={() => {
        !dragged.current &&
          useInspirationEditorStore.setState({
            activeComponentId: null,
            hoveredComponentId: null,
          });
      }}
      onMouseDown={() => (dragged.current = false)}
      onMouseMove={() => (dragged.current = true)}
    >
      <Box
        pos='absolute'
        w='100vw'
        h='100dvh'
        onClick={() => perspectiveFullscreen && togglePerspectiveFullscreen()}
      />
      <Box ref={wrapper} pos='relative' rounded='lg'>
        <FullscreenButton />
        <ImageMarkers isImageLoading={!hasLoadedInitialImage} />
        <RenderedInspirationImage
          sceneId={id}
          alt={id}
          loading='lazy'
          pointerEvents='none'
          isImageLoading={isImageLoading}
          setIsImageLoading={setIsImageLoading}
          layers={activeSceneVariant}
          view={activeViewIndex}
          resolution={getImageResolution(currentZoom)}
          skipLoadingIndicator={true}
          onInitialLoad={() => setHasLoadedInitialImage(true)}
        />
      </Box>
    </Center>
  );
};
