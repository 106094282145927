export const userRoleDefinition = {
  free: {
    translation: 'roles.free' as const,
    mockProjects: 0,
    projectLimit: 0,
  },
  community: {
    translation: 'roles.community' as const,
    mockProjects: 3,
    projectLimit: 3,
  },
  standard: {
    translation: 'roles.standard' as const,
    mockProjects: 6,
    projectLimit: 8,
  },
  pro: {
    translation: 'roles.pro' as const,
    mockProjects: 8,
    projectLimit: 1000,
  },
};

export type UserRole = keyof typeof userRoleDefinition;
