import { z } from 'zod';

import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';

export const InspirationProjectLayerSchema = z.object({
  objectId: z.string(),
  materialUniqueKey: z.string().nullable(),
});

export const InspirationProjectSchema = z.object({
  id: z.string(),
  userId: z.string(),
  projectName: z.string(),
  isPublic: z.boolean(),
  type: z.literal('inspiration'),
  sceneId: z.string(),
  variants: InspirationProjectLayerSchema.array().array(),
});

export type InspirationProjectLayer = z.infer<
  typeof InspirationProjectLayerSchema
>;
export type InspirationProject = z.infer<typeof InspirationProjectSchema>;

export const UpsertInspirationProjectResponseSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: InspirationProjectSchema,
});

export const GetInspirationProjectResponseSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: InspirationProjectSchema.optional(),
});
