import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { AdditionalFilter } from '$/components/core/Collection/CollectionFilter/components/AdditionalFilter';
import { FilterDropdown } from '$/components/core/Collection/CollectionFilter/FilterDropdown';
import { FilterGroupOption } from '$/services/usecases/filter';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  filter: FilterGroupOption[];
}

export const AdditionalFilterDropdown: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();

  const { additionalGroups } = useCollectionStore(
    useShallow((s) => {
      const additional = s.filter?.additional.map((f) => f.searchName) ?? [];
      const additionalGroups = s.filterGroups.filter((f) =>
        additional.includes(f.type),
      );
      return { additionalGroups };
    }),
  );

  const getTitle = () => {
    if (additionalGroups.length === 0) return t('inspirationEditor.filter.all');
    if (additionalGroups.length === 1) return additionalGroups[0].displayName;
    return t('inspirationEditor.filter.nFilterActive', {
      amount: additionalGroups.length,
    });
  };

  return (
    <FilterDropdown
      title={getTitle()}
      bg='bodyBackground'
      border='1px solid'
      borderColor='border'
      textAlign='start'
      h='6'
      w='full'
      borderRadius='4'
      color='lighterText'
      px='3'
      fontSize='xs'
    >
      {(onMenuClose) => (
        <AdditionalFilter
          onMenuClose={onMenuClose}
          additionalFilter={filter}
          hideActions
          compactMode
        />
      )}
    </FilterDropdown>
  );
};
