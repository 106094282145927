import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const baseStyle = definePartsStyle({
  dialog: {
    bg: 'background',
    boxShadow: 'none',
    border: '1px solid',
    borderColor: 'border',
  },
  overlay: {
    bg: 'overlay',
  },
});

export const Modal = defineMultiStyleConfig({ baseStyle });
