import { Box, Heading, Stack } from '@chakra-ui/react';
import { FC } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { MaterialListItem } from '$/components/core/Collection/MaterialListItem';
import { MaterialListItemSkeleton } from '$/components/core/Collection/MaterialListItemSkeleton';
import { FavoriteItem } from '$/pages/DashboardPages/pages/Favorite';
import { Material } from '$/services/usecases/materials';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  title: string;
  favorites: FavoriteItem[];
  isLoading: boolean;
  activeMaterialId?: string;
  onClickItem: (material: Material) => void;
  additionalHideCondition?: boolean;
}

export const FavoriteFolderSection: FC<Props> = ({
  favorites,
  title,
  isLoading,
  onClickItem,
  activeMaterialId,
  additionalHideCondition = false,
}) => {
  if ((!isLoading && favorites.length === 0) || additionalHideCondition)
    return null;

  return (
    <Stack gap='4' px='4' pt='4'>
      <Heading as='h3' color='lighterText' fontSize='sm'>
        {title}
      </Heading>

      <Show
        when={!isLoading}
        fallback={() =>
          repeat(4).map((id) => <MaterialListItemSkeleton key={id} id={id} />)
        }
      >
        <Stack gap='3' ml='4'>
          {favorites.map((favorite, index) => (
            <Box
              key={favorite.material.id}
              as='button'
              onClick={() => onClickItem(favorite.material)}
            >
              <MaterialListItem
                id={index}
                material={favorite.material}
                showAsActive={activeMaterialId === favorite.material.id}
              />
            </Box>
          ))}
        </Stack>
      </Show>
    </Stack>
  );
};
