import { Box, Checkbox, FormLabel, Flex } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props {
  checked: boolean;
  onChange: (value: boolean) => void;
  knob?: ReactNode;
}

export const Switch: FC<Props> = ({ checked, onChange, knob }) => {
  return (
    <FormLabel
      h='full'
      m='0'
      p='4px'
      bg='themeToggle.background'
      border='1px'
      borderColor='border'
      borderRadius='full'
      cursor='pointer'
      aspectRatio='2/1'
      onKeyDown={(e) => {
        if (e.key === 'Enter') onChange(!checked);
      }}
      tabIndex={0}
    >
      <Checkbox
        display='none'
        checked={checked}
        onChange={(e) => onChange(e.currentTarget.checked)}
      />
      <Box pos='relative' w='full' h='full'>
        <Flex
          pos='absolute'
          left={checked ? '100%' : '0'}
          justify='center'
          // For some reason, aspect-ratio won't work in IPadOS browsers, so we need to set width and height manually
          w={{ base: '39%', mobile: '42%' }}
          h='full'
          p={{ base: '2px', mobile: '1' }}
          bg='themeToggle.knob'
          borderRadius='full'
          transform={checked ? 'translateX(-100%)' : undefined}
          transition='left 200ms ease, transform 200ms ease !important'
          aspectRatio='1/1'
        >
          {knob}
        </Flex>
      </Box>
    </FormLabel>
  );
};
