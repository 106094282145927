import { Box, Flex, FormLabel, MenuItem, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { LoadingCheckbox } from '$/components/common/LoadingCheckbox';

interface Props {
  id: string;
  name: string;
  isChecked: boolean;
  onChange: (id: string) => void;
  isLoading?: boolean;
  amount?: number;
  color?: string;
  compactMode?: boolean;
}

export const MultiSelectionMenuItem: FC<Props> = ({
  id,
  name,
  isChecked,
  onChange,
  color,
  amount,
  isLoading = false,
  compactMode = false,
}) => {
  return (
    <MenuItem
      display='flex'
      w='full'
      minW='full'
      py={compactMode ? '0.5' : '1.5'}
    >
      <FormLabel
        justifyContent='space-between'
        gap='4'
        display='flex'
        w='full'
        minW='full'
        m='0'
        cursor='pointer'
      >
        <Flex align='center' gap='2'>
          {color && (
            <Box
              w='32px'
              h='20px'
              bg={color}
              borderWidth='1px'
              borderColor={id.includes('white') ? 'border' : 'transparent'}
              borderRadius='2px'
            />
          )}
          <Text fontSize={compactMode ? 'xs' : 'sm'}>{name}</Text>
        </Flex>
        <Flex align='center' gap='2'>
          {amount && (
            <Text color='ultralightText' fontSize={compactMode ? 'xss' : 'xs'}>
              {amount}
            </Text>
          )}
          <LoadingCheckbox
            isLoading={isLoading}
            isChecked={isChecked}
            isDisabled={isLoading}
            onChange={() => onChange(id)}
          />
        </Flex>
      </FormLabel>
    </MenuItem>
  );
};
