import { createRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { EditorPage } from '$/pages/EditorPage/pages/EditorPage';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { EditorRouteLayout } from '$/routes/Layouts/EditorLayout';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

const EditorParamsSchema = z.object({
  projectId: z.string().optional(),
});

export const EditorRoute = createRoute({
  getParentRoute: () => EditorRouteLayout,
  path: '/editor/',
  component: EditorPage,
  validateSearch: (search) => EditorParamsSchema.parse(search),
  onLeave: () => {
    useEditorStore.getState().resetStore();
  },
  onEnter: () => {
    useEditorStore.getState().resetStore();
    useInspirationEditorStore.setState({ perspectiveFullscreen: false });
  },
  loader: ({ context: { client } }) => {
    useCollectionStore.setState({ filter: undefined });
    void client.ensureQueryData(filterOptionsQuery()).then((filter) => {
      useCollectionStore.setState({ filter });
    });
  },
});
