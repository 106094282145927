import { ResponsiveValue, VStack } from '@chakra-ui/react';
import { FC, RefObject } from 'react';

import { For } from '$/components/common/Flow/For';
import { Show } from '$/components/common/Flow/Show';
import { FanPresentationRow } from '$/components/core/Collection/MaterialPresentation/FanPresentationRow';
import { ListPresentationItem } from '$/components/core/Collection/MaterialPresentation/ListPresentationItem';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  observer: RefObject<HTMLDivElement>;
  setActiveMaterial: (material: Material) => void;
  materials: Material[];
  type: MaterialType;
  isLoading: boolean;
  disableHovering?: boolean;
  itemHeight?: ResponsiveValue<string>;
  rowGap?: ResponsiveValue<string>;
  borderRadius?: string;
  activeMaterialId?: string;
  hideFavoriteButton?: boolean;
}

export const FanPresentation: FC<Props> = ({
  observer,
  setActiveMaterial,
  materials,
  type,
  isLoading,
  disableHovering,
  itemHeight,
  rowGap = '3',
  borderRadius,
  activeMaterialId,
  hideFavoriteButton = false,
}) => {
  const fanPages = materials.reduce<Record<number, Material[]>>((acc, curr) => {
    const page = curr.position.page;
    if (!page || page === 0) return acc;
    acc[page] = [...(acc[page] || []), curr];
    return acc;
  }, {});

  const sortedKeys = Object.keys(fanPages).sort(
    (a, b) => Number(a) - Number(b),
  );

  return (
    <VStack gap={rowGap} overflow='auto'>
      <For each={sortedKeys}>
        {(page, i) => (
          <FanPresentationRow
            fanPage={fanPages[Number(page)]}
            showObserver={i === sortedKeys.length - 5}
            observer={observer}
            setActiveMaterial={setActiveMaterial}
            disableHovering={disableHovering}
            height={itemHeight}
            borderRadius={borderRadius}
            activeMaterialId={activeMaterialId}
            hideFavoriteButton={hideFavoriteButton}
          />
        )}
      </For>
      <Show when={isLoading}>
        {repeat(20).map((i) => (
          <ListPresentationItem
            materialType={type}
            key={i}
            hideFavoriteButton={hideFavoriteButton}
          />
        ))}
      </Show>
    </VStack>
  );
};
