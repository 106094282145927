/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

import {
  LinkProps as ChakraLinkProps,
  Link as ChakraLink,
} from '@chakra-ui/react';
import {
  LinkProps,
  AnyRoute,
  RegisteredRouter,
  RoutePaths,
  Link,
  ToOptions,
} from '@tanstack/react-router';
import { ReactNode, RefAttributes } from 'react';

import { routeTree } from '$/routes';

export type TypedToOptions = ToOptions<typeof routeTree>['to'];

export type StyledLinkProps<
  TRouteTree extends AnyRoute = RegisteredRouter['routeTree'],
  TFrom extends RoutePaths<TRouteTree> | string = string,
  TTo extends string = '',
  TMaskFrom extends RoutePaths<TRouteTree> | string = TFrom,
  TMaskTo extends string = '',
> = LinkProps<TRouteTree, TFrom, TTo, TMaskFrom, TMaskTo> &
  RefAttributes<HTMLAnchorElement>;

export const StyledLink = <
  TRouteTree extends AnyRoute = RegisteredRouter['routeTree'],
  TFrom extends RoutePaths<TRouteTree> | string = string,
  TTo extends string = '',
  TMaskFrom extends RoutePaths<TRouteTree> | string = TFrom,
  TMaskTo extends string = '',
>({
  link,
  children,
  ...rest
}: {
  link: LinkProps<TRouteTree, TFrom, TTo, TMaskFrom, TMaskTo> &
    RefAttributes<HTMLAnchorElement>;
  children: ReactNode;
} & ChakraLinkProps) => {
  return (
    <ChakraLink
      {...rest}
      as={Link}
      _hover={{ textDecor: 'none', ...rest._hover }}
      {...link}
    >
      {children}
    </ChakraLink>
  );
};
