import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ActionCard } from '$/components/core/Projects/components/Cards/ActionCard';

export const UpgradeAccountCard = () => {
  const { t } = useTranslation();

  return (
    <Link to='/pricing'>
      <ActionCard
        icon={<Icon icon='leaderboard' h='28px' w='26px' />}
        headerText={t('dashboard.welcome.upgrade')}
        lowerText={t('dashboard.welcome.createFurtherProject')}
        background='dashboard.createAccountCard.background'
        color='dashboard.createAccountCard.color'
      />
    </Link>
  );
};
