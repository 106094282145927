import { object, z } from 'zod';

import {
  GeneralBackendResponseSchema,
  GeneralVeeuzeAuthResponse,
  parseErrorResult,
} from '$/services/mapper/authentication';

export const GenericVeeuzeCloudResponseSchema = object(
  GeneralVeeuzeAuthResponse,
).transform((data) => {
  return parseErrorResult(data, () => {});
});

const sapCxConnectionStatus = z.enum(['NotYetChecked']);

export const UserSchema = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  profileImageUrl: z.string().nullable(),
  sapCxConnectionStatus: sapCxConnectionStatus,
  role: z.enum(['free', 'community', 'standard', 'pro']).default('pro'),
});

export type User = z.infer<typeof UserSchema>;

export const RegistrationResponseSchema = z
  .object({
    ...GeneralBackendResponseSchema,
    payload: z
      .object({
        user: UserSchema,
      })
      .optional(),
  })
  .transform((data) => {
    return {
      message: data.message,
      user: data.payload?.user,
    };
  });
