import { Box, Spacer } from '@chakra-ui/react';

import { LayerSidebar } from '$/components/core/Editor/LayerSidebar';
import { SaveSection } from '$/components/core/Editor/SaveSection';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { Layers } from '$/pages/InspirationEditorPage/components/LayerSection/components/Layers';
import { PerspectiveSection } from '$/pages/InspirationEditorPage/components/LayerSection/components/PerspectiveSection';
import { VariantSection } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection';
import { useSaveInspirationProject } from '$/pages/InspirationEditorPage/hooks/useSaveInspirationProject';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export const LayerSection = () => {
  const [isSaving, onSaveProject] = useSaveInspirationProject();

  const scene = useInspirationEditorStore.useScene();

  const resetComponentSelection = () =>
    useInspirationEditorStore.setState({
      activeComponentId: null,
      hoveredComponentId: null,
    });

  return (
    <LayerSidebar projectName={scene?.name} pb='4'>
      <Layers />
      <Spacer onClick={resetComponentSelection} />
      <SidebarDivider />

      <Box px='4' pt='3' pb='4'>
        <PerspectiveSection scene={scene} />
      </Box>

      <SidebarDivider />

      <Box px='4' pt='3' pb='4'>
        <VariantSection />
      </Box>

      <SidebarDivider />

      <SaveSection isSaving={isSaving} onSave={onSaveProject} />
    </LayerSidebar>
  );
};
