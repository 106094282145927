import { useBreakpointValue } from '@chakra-ui/react';

export const useIsMobile = () => {
  const isMobile = !!useBreakpointValue(
    { base: true, mobile: false },
    { ssr: false },
  );

  return isMobile;
};
