import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { AppliedMaterial } from '$/components/core/Editor/Mobile/components/AppliedMaterial';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props {
  onOpen: () => void;
}

export const AppliedColorBar: FC<Props> = ({ onOpen }) => {
  const { material } = useInspirationEditorStore(
    useShallow((s) => ({ material: s.getActiveComponent()?.material })),
  );

  if (!material) return null;

  return (
    <Box pos='absolute' bottom='60px' w='full' p='4' onClick={onOpen}>
      <AppliedMaterial
        material={material}
        bg='background'
        borderRadius='12px'
      />
    </Box>
  );
};
