import { redirect } from '@tanstack/react-router';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';

export const routeAuthGuard = () => {
  const { user, userRole } = useAuthenticationStore.getState();

  if (user == null) {
    throw redirect({ to: '/not-allowed', replace: true });
  }

  return { user, role: userRole };
};

export const authLoader = () => {
  const { user } = useAuthenticationStore.getState();

  if (user == null) {
    throw redirect({ to: '/not-allowed', replace: true });
  }

  return user;
};
