import { HStack, StackProps, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { FC } from 'react';

import { AvailableIcons, Icon } from '$/components/common/Icon';
import { ColorLayerImage } from '$/pages/InspirationEditorPage/components/LayerSection/components/ColorLayerImage';
import { useHoverAndActiveState } from '$/pages/InspirationEditorPage/hooks/useHoverAndActiveState';
import {
  Layer,
  LayerCategory,
  useInspirationEditorStore,
} from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props extends StackProps {
  layer: Layer;
}

const mapLayerCategoryToIcon = (category: LayerCategory): AvailableIcons => {
  switch (category) {
    case 'ceiling':
      return 'ceiling';
    case 'floor':
      return 'floor';
    case 'wall':
      return 'wall';
    case 'outside':
      return 'cottage';
    case 'unknown':
    default:
      return 'other_objects';
  }
};

export const ColorLayer: FC<Props> = ({ layer, ...props }) => {
  const changeComponent = useInspirationEditorStore.useChangeComponent();
  const getActiveHoverProps = useHoverAndActiveState(() => {
    changeComponent(layer.id);
  });

  const nameTranslationKey = `inspirationEditor.objects.${layer.uniqueName}`;

  return (
    <HStack cursor='pointer' {...getActiveHoverProps(layer.id)} {...props}>
      <HStack gap='1'>
        <Icon
          icon={mapLayerCategoryToIcon(layer.category)}
          color='lighterText'
          boxSize='4'
        />
        <ColorLayerImage material={layer.material} />
      </HStack>
      <Text fontSize='xs' lineHeight='16px'>
        {t(nameTranslationKey)}
      </Text>
    </HStack>
  );
};
