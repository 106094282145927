import { endpointFactory } from '$/services/endpoints';
import { veeuzeFetcher } from '$/services/fetcher';
import {
  FandeckInfoSchema,
  FandeckPageCountSchema,
} from '$/services/usecases/fandeck/mapper/fandeck';

interface FandeckInfoPayload {
  collection: string;
  page: number;
  signal: AbortSignal;
}

export const getFandeckInfo = async ({
  collection,
  page,
  signal,
}: FandeckInfoPayload) => {
  return await veeuzeFetcher(
    endpointFactory.fandeckInfo({ collection, page }),
    {
      signal,
      parser: (data) => FandeckInfoSchema.parse(data),
      msg: `Could not get the fandeck info for collection ${collection} page ${page}`,
    },
  );
};

interface FandeckPageCountPayload {
  collection: string;
  signal: AbortSignal;
}

export const getFandeckPageCount = async ({
  collection,
  signal,
}: FandeckPageCountPayload) => {
  return await veeuzeFetcher(endpointFactory.fandeckPageCount({ collection }), {
    signal,
    parser: (data) => FandeckPageCountSchema.parse(data),
    msg: `Could not get the fandeck info for collection ${collection} page 1`,
  });
};
