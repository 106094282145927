import { Button, HStack, Input, Text } from '@chakra-ui/react';
import { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

interface PaginationProps {
  currentPage: number;
  pageCount?: number;
  onPageChange: (page: number) => void;
}

const delay = 500;

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  pageCount,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const [pageSelectorPage, setPageSelectorPage] = useState(currentPage);

  useEffect(() => {
    setPageSelectorPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    let timeoutId: number | null = null;

    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      if (!isNaN(pageSelectorPage)) {
        onPageChange(pageSelectorPage);
      }
    }, delay);

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSelectorPage]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (pageCount && currentPage < pageCount) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <HStack justifyContent='space-between' px='1'>
      <Button
        minW='auto'
        h='auto'
        p={0}
        transform='rotate(90deg)'
        isDisabled={currentPage === 1}
        onClick={handlePreviousPage}
        variant='ghost'
      >
        <Icon icon='chevron_down' w='10px' />
      </Button>

      <HStack>
        <Input
          w='30px'
          minW='0'
          h='6'
          px='0'
          textColor='paginationText'
          fontSize='xs'
          textAlign='center'
          borderColor='border'
          disabled={!pageCount}
          isDisabled={pageCount === 1}
          max={pageCount}
          min={1}
          onBlur={() => {
            if (isNaN(pageSelectorPage)) {
              setPageSelectorPage(currentPage);
            }
          }}
          onChange={(e) => {
            let newValue = parseInt(e.currentTarget.value);

            if (newValue < 1) {
              newValue = 1;
            } else if (newValue > pageCount!) {
              newValue = pageCount!;
            }

            setPageSelectorPage(newValue);
          }}
          onClick={(e) => e.stopPropagation()}
          type='number'
          value={pageSelectorPage}
        />
        <Text color='paginationText' fontSize='12px'>
          {t('general.paginationGlueWord')} {pageCount ?? '000'}
        </Text>
      </HStack>

      <Button
        minW='auto'
        h='auto'
        p={0}
        color='textButton.disabledColor'
        transform='rotate(270deg)'
        isDisabled={currentPage === pageCount}
        onClick={handleNextPage}
        variant='ghost'
      >
        <Icon icon='chevron_down' w='10px' />
      </Button>
    </HStack>
  );
};
