import { QueryClient } from '@tanstack/react-query';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { logError } from '$/logger';

export const STANDARD_STALE_TIME = 1000 * 60 * 10;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

export const veeuzeFetcher = async <T>(
  url: string,
  {
    msg,
    parser,
    signal,
    body,
    method = 'GET',
    includeCredentials = false,
  }: {
    msg: string;
    parser: (data: unknown) => T;
    signal?: AbortSignal;
    body?: RequestInit['body'] | object;
    method?: RequestInit['method'];
    includeCredentials?: boolean;
  },
): Promise<T> => {
  const { accessToken } = useAuthenticationStore.getState();
  const headers = new Headers();

  if (includeCredentials) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  }

  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    headers.append('Content-Type', 'application/json');
  }

  const response = await fetch(url, {
    signal,
    body: typeof body === 'object' ? JSON.stringify(body) : body,
    method,
    headers,
    credentials: includeCredentials ? 'include' : 'omit',
  });

  if (response.status !== 200) {
    logError([msg, response.status, await response.text()]);
    throw new Error(msg);
  }

  try {
    return parser(await response.json());
  } catch (e) {
    logError([msg, e, response.body]);
    throw new Error(msg);
  }
};

export const fetcher = async <T>(
  url: string,
  {
    parser,
    signal,
    body,
    method,
    includeCredentials = false,
    isFormData = false,
  }: {
    parser: (data: unknown) => T;
    signal?: AbortSignal;
    body?: RequestInit['body'] | object;
    method: RequestInit['method'];
    includeCredentials?: boolean;
    isFormData?: boolean;
  },
): Promise<{ isSuccessful: boolean; httpStatusCode: number; response: T }> => {
  const { accessToken } = useAuthenticationStore.getState();
  const headers = new Headers();

  if (includeCredentials) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  }

  if (
    (method === 'POST' || method === 'PUT' || method === 'PATCH') &&
    !isFormData
  ) {
    headers.append('Content-Type', 'application/json');
  }

  const shouldStringify = typeof body === 'object' && !isFormData;

  const response = await fetch(url, {
    signal,
    body: shouldStringify ? JSON.stringify(body) : (body as BodyInit),
    method,
    headers,
    credentials: includeCredentials ? 'include' : 'omit',
  });

  try {
    return {
      isSuccessful: response.status < 400,
      httpStatusCode: response.status,
      response: parser(await response.json()),
    };
  } catch (e) {
    logError([e, response.body]);
    throw new Error(JSON.stringify(e));
  }
};
