import { ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ModalHeaderWithBackButton } from '$/components/common/Modals/ModalHeaderWithBackButton';
import { ForgotPasswordForm } from '$/components/core/Authentication/components/ForgotPasswordForm';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';

export const PasswordForgottenModalContent = () => {
  const { t } = useTranslation();
  const setAuthenticationModalType = useAuthenticationStore.useSetModalType();

  return (
    <ModalContent maxW='500px' bg='background' borderRadius='12px'>
      <ModalHeaderWithBackButton
        onBackButtonClick={() => setAuthenticationModalType('login')}
        trackingContentName='CloudAccount'
        trackingContentPiece='ForgetPassword'
        trackingContentTarget='Back'
        displayBackButton={true}
        backButtonText={t('general.back')}
        headerText={t('dashboard.authentication.resetPassword.action')}
      />
      <ModalCloseButton top='0' right='0' mt='8' mr='8' />
      <ModalBody mt='8' px='10' pb='10'>
        <ForgotPasswordForm />
      </ModalBody>
    </ModalContent>
  );
};
