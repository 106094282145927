import { HStack, Spinner, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { Icon } from '$/components/common/Icon';

interface Props {
  prefix: ReactNode;
  header: string;
  onClick?: () => void;
  suffix?: ReactNode;
  isActive?: boolean;
  isLoading?: boolean;
  isCollapsible?: boolean;
}

export const FilterHeader: FC<Props> = ({
  isActive,
  header,
  prefix,
  suffix,
  onClick,
  isLoading = false,
  isCollapsible = false,
}) => {
  return (
    <HStack
      justify='space-between'
      w='full'
      cursor={onClick ? 'pointer' : 'default'}
      onClick={() => !isLoading && onClick && onClick()}
    >
      <HStack justify='space-between' w='full' color='lighterText'>
        <HStack gap='2'>
          {prefix}
          <Text fontSize='sm' fontWeight='bold' lineHeight='20px'>
            {header}
          </Text>
        </HStack>
        {suffix}
      </HStack>

      <Show when={isCollapsible}>
        <Show
          when={!isLoading}
          fallback={() => (
            <Spinner w='13px' h='13px' emptyColor='neutral.1000/30' />
          )}
        >
          <Icon
            color='lighterText'
            icon='chevron_down'
            transform={`rotate(${isActive ? '180deg' : '0deg'})`}
            boxSize='3'
          />
        </Show>
      </Show>
    </HStack>
  );
};
