import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  HStack,
  Input,
  Stack,
  Button,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { RespondingIconButton } from '$/components/core/Editor/ActionBar/components/RespondingIconButton';
import { VisibilitySelection } from '$/components/core/Editor/AttributeSidebar/components/Share/VisibilitySelection';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { queryClient } from '$/services/fetcher';
import { changeProjectVisibility } from '$/services/usecases/projects';
import {
  editorProjectQuery,
  inspirationProjectQuery,
} from '$/services/usecases/projects/queries';

// interface ShareFormInput {
//   mailAddress: string;
// }

interface FormSectionProps {
  projectId: string;
  type: 'inspiration' | 'editor';
}

const FormSection: FC<FormSectionProps> = ({ type, projectId }) => {
  const { t } = useTranslation();

  // const formMethods = useForm<ShareFormInput>({ mode: 'onSubmit' });

  // const onSubmit: SubmitHandler<ShareFormInput> = async () => {
  //   await new Promise((resolve) => setTimeout(resolve, 2000));
  // };

  const getPath = () => {
    if (type === 'inspiration') {
      return `${window.location.origin}/inspirationProjects/${projectId}`;
    }

    return `${window.location.origin}/editor?projectId=${projectId}`;
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(getPath());
  };

  return (
    <Stack gap='6'>
      {/*<HStack as='form' w='full'>
        <FormProvider {...formMethods}>
          <HookFormInput<ShareFormInput>
            type='email'
            accessor='mailAddress'
            label={t('editor.invite_per_mail')}
            placeholder={t('dashboard.authentication.defaultPlaceholder', {
              field: t('dashboard.authentication.emailLabel'),
            })}
            ignoreBlur
            registerOptions={{
              required: t('dashboard.authentication.errorMessages.isRequired'),
              pattern: {
                value: emailFormatRegex,
                message: t(
                  'dashboard.authentication.errorMessages.wrongFormat',
                ),
              },
            }}
            rightElement={
              <RespondingIconButton
                onClick={formMethods.handleSubmit(onSubmit)}
                aria-label='send'
                icon='project_send'
                iconColor='reverseText'
                ml='2'
                isDisabled={!formMethods.formState.isValid}
              />
            }
          />
        </FormProvider>
      </HStack>*/}

      <Stack>
        <Text fontSize='sm'>{t('editor.copy_link_here')}</Text>
        <HStack gap='2'>
          <Input
            bg='form.inputBackground !important'
            _active={{}}
            _focus={{}}
            placeholder={t('editor.link')}
            readOnly
            value={getPath()}
          />
          <RespondingIconButton
            aria-label='copy'
            icon='project_copy'
            iconColor='reverseText'
            onClick={handleCopy}
          />
        </HStack>
      </Stack>
    </Stack>
  );
};

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  type: 'inspiration' | 'editor';
}

export const ShareModal: FC<ShareModalProps> = ({
  isOpen,
  onClose,
  projectId,
  type,
}) => {
  const { t } = useTranslation();

  const editProjectQuery = editorProjectQuery(projectId, type === 'editor');
  const inspProjectQuery = inspirationProjectQuery(
    projectId,
    type === 'inspiration',
  );

  const inspProject = useQuery(inspProjectQuery);
  const editProject = useQuery(editProjectQuery);

  const isInitialPublic = (type === 'inspiration' ? inspProject : editProject)
    .data?.response.payload?.isPublic;

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<'public' | 'private'>(
    isInitialPublic ? 'public' : 'private',
  );

  useEffect(() => {
    setValue(isInitialPublic ? 'public' : 'private');
  }, [isOpen, isInitialPublic]);

  const handleVisibilityToggle = async () => {
    setIsLoading(true);

    await changeProjectVisibility(projectId, value === 'public')
      .then(() => onClose())
      .finally(async () => {
        await queryClient.invalidateQueries({
          queryKey: (type === 'inspiration'
            ? inspProjectQuery
            : editProjectQuery
          ).queryKey,
        });

        setIsLoading(false);
      });
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minW={{ base: 'unset', mobile: '570px' }}
        maxW='570px'
        mx='4'
        p={{ base: '4', mobile: '10' }}
      >
        <HStack justify='space-between' pb='4'>
          <Text
            p='0'
            fontSize={{ base: 'h10', mobile: 'h7' }}
            fontWeight='bold'
          >
            {t('editor.share_project')}
          </Text>

          <ModalCloseButton pos='static' />
        </HStack>

        <ModalBody p='0'>
          <Stack gap={{ base: '4', mobile: '6' }}>
            <Text fontSize='sm'>
              <Trans i18nKey='editor.share_modal_text' />
            </Text>
            <VisibilitySelection value={value} onChange={setValue} />
            <FormSection type={type} projectId={projectId} />
          </Stack>

          <SidebarDivider mt='6' />

          <HStack justify='space-between' mt='10'>
            <Button
              color='lighterText'
              fontSize='sm'
              onClick={onClose}
              variant='ghost'
            >
              {t('general.cancel')}
            </Button>
            <Button
              fontSize='sm'
              isLoading={isLoading}
              onClick={handleVisibilityToggle}
            >
              {t('editor.save')}
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
