import { HStack, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { NewProjectButton } from '$/components/core/Projects/components/NewProjectButton';

export const ProjectHeader = () => {
  const { t } = useTranslation();

  return (
    <HStack justify='space-between'>
      <Heading as='h1' fontSize='3xl'>
        {t('dashboard.projects.header')}
      </Heading>
      <NewProjectButton />
    </HStack>
  );
};
