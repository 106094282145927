import { HStack, Stack, StackProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorBox } from '$/components/core/Editor/ColorSelectionSidebar/ColorBox';
import { Material } from '$/services/usecases/materials';

interface Props extends StackProps {
  material: Material;
}

export const AppliedMaterial: FC<Props> = ({ material, ...rest }) => {
  const { t } = useTranslation();

  return (
    <HStack
      p='2'
      border='1px solid'
      borderColor='border'
      borderRadius='8px'
      {...rest}
    >
      <ColorBox
        materialColor={material.color}
        image={material.url({ width: '60', height: '60' })}
        w='56px'
        h='52px'
      />
      <Stack gap='0'>
        <Text color='lighterText' fontSize='xss' lineHeight='16px'>
          {t('dashboard.inspiration.chosenColor')}
        </Text>
        <Text fontSize='sm' fontWeight='bold' lineHeight='20px'>
          {material.info}
        </Text>
        <Text
          color='lighterText'
          fontSize='xss'
          fontWeight='bold'
          lineHeight='16px'
        >
          {material.collection}
        </Text>
      </Stack>
    </HStack>
  );
};
