import { Box, IconButton } from '@chakra-ui/react';
import { KeepScale } from 'react-zoom-pan-pinch';

import { Icon } from '$/components/common/Icon';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export const FullscreenButton = () => {
  const perspectiveFullscreen =
    useInspirationEditorStore.usePerspectiveFullscreen();
  const togglePerspectiveFullscreen =
    useInspirationEditorStore.useTogglePerspectiveFullscreen();

  return (
    <Box pos='absolute' top='16px' right='16px' bg='red'>
      <KeepScale>
        <IconButton
          pos='absolute'
          top='0'
          right='0'
          opacity={perspectiveFullscreen ? '1' : '0'}
          pointerEvents={perspectiveFullscreen ? 'auto' : 'none'}
          transition='opacity 0.3s'
          aria-label=''
          icon={<Icon icon='zoom_in_map' width='18px' />}
          onClick={(e) => {
            e.stopPropagation();
            togglePerspectiveFullscreen();
          }}
          size='sm'
          variant='secondary'
        />
      </KeepScale>
    </Box>
  );
};
