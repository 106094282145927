import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { MaterialType } from '$/services/mapper/uses';
import { MenuLayer } from '$/stores/useMaterialContextMenuStore';

export type FavoriteModalMode =
  | 'deleteFavorite'
  | 'renameFolder'
  | 'deleteFolder'
  | 'createFolder';

type FavoriteModalProps = {
  isOpen: boolean;
  modalMode: FavoriteModalMode;
  itemIdentifier: string;
  itemName: string;
  itemType: MaterialType | undefined;
  menuLayer: MenuLayer | undefined;
  onClose: () => void;
  openFavoriteModal: (
    modalMode: FavoriteModalMode,
    itemIdentifier: string,
    itemName: string,
    itemType?: MaterialType,
    menuLayer?: MenuLayer,
  ) => void;
};

const store = create<FavoriteModalProps>()((set) => ({
  isOpen: false,
  modalMode: 'deleteFavorite',
  itemIdentifier: '',
  itemName: '',
  itemType: undefined,
  menuLayer: undefined,
  onClose: () => {
    set({ isOpen: false });
  },
  openFavoriteModal: (
    mode,
    itemIdentifier,
    itemName,
    itemType,
    menuLayer = undefined,
  ) =>
    set({
      modalMode: mode,
      isOpen: true,
      itemIdentifier,
      itemType,
      itemName,
      menuLayer,
    }),
  saveAction: () => {},
}));

export const useFavoriteModal = createSelectorHooks(store);
