import { HStack, StackProps } from '@chakra-ui/react';
import { FC } from 'react';

import { BrightnessInputElement } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter/BrightnessInputElement';
import { BrightnessSlider } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter/BrightnessSlider';

interface Props extends StackProps {
  value: number[];
  setValue: (value: number[]) => void;
}

export const BrightnessFilterMenuItem: FC<Props> = ({
  value,
  setValue,
  ...rest
}) => {
  const lowerBoundsTransformer = (curr: number) => {
    if (curr <= 0) {
      return 0;
    }

    if (curr >= value[1]) {
      return value[1] - 1;
    }

    return curr;
  };

  const upperBoundsTransformer = (curr: number) => {
    if (curr >= 100) {
      return 100;
    }

    if (curr <= value[0]) {
      return value[0] + 1;
    }

    return curr;
  };

  return (
    <HStack gap='4' p='3' {...rest}>
      <BrightnessInputElement
        value={value[0]}
        validate={(curr) => curr >= 0 && curr <= 100 && curr < value[1]}
        transformer={lowerBoundsTransformer}
        onChange={(newValue) => setValue([newValue, value[1]])}
      />
      <BrightnessSlider value={value} setValue={setValue} />
      <BrightnessInputElement
        value={value[1]}
        validate={(curr) => curr >= 0 && curr <= 100 && curr > value[0]}
        transformer={upperBoundsTransformer}
        onChange={(newValue) => setValue([value[0], newValue])}
      />
    </HStack>
  );
};
