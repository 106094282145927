import { endpointFactory } from '$/services/endpoints';
import { veeuzeFetcher } from '$/services/fetcher';
import {
  AllCategoriesSchema,
  Category,
} from '$/services/usecases/scenes/mapper/allCategories';
import {
  Scene,
  AllSceneSchema,
} from '$/services/usecases/scenes/mapper/allScenes';
import {
  CompleteScene,
  CompleteSceneSchema,
} from '$/services/usecases/scenes/mapper/completeScene';
import { groupBy } from '$/utils/arrayUtils';

export type { Scene, Category };

export const getCompleteScene = async (
  sceneId: string,
  signal: AbortSignal,
): Promise<CompleteScene> => {
  return await veeuzeFetcher(
    endpointFactory.inspirationScenes({ sceneId, withComponents: true }),
    {
      msg: 'could not fetch inspiration scenes with components',
      parser: (data) => CompleteSceneSchema.parse(data),
      signal,
    },
  );
};

export const getAllScenes = async (
  signal: AbortSignal,
): Promise<Record<string, Scene[]>> => {
  const scenes = await veeuzeFetcher(endpointFactory.inspirationScenes(), {
    msg: 'could not fetch inspiration scenes',
    parser: (data) => AllSceneSchema.parse(data),
    signal,
  });

  return groupBy<Scene, string>(scenes, (obj) => obj.categoryId);
};

export const getAllCategories = async (
  signal: AbortSignal,
): Promise<Category[]> => {
  return await veeuzeFetcher(endpointFactory.inspirationCategories(), {
    msg: 'could not fetch inspiration categories',
    parser: (data) => AllCategoriesSchema.parse(data),
    signal,
  });
};
