import { HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { AvailableIcons, Icon } from '$/components/common/Icon';

interface Props {
  title: string;
  icon?: AvailableIcons;
}

export const SectionTitle: FC<Props> = ({ title, icon }) => {
  return (
    <HStack>
      {icon && <Icon boxSize='4' icon={icon} color='lighterText' />}
      <Text color='lighterText' fontSize='sm' fontWeight='bold'>
        {title}
      </Text>
    </HStack>
  );
};
