import { Stack, StackProps, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props extends StackProps {
  icon: ReactNode;
  headerText: string;
  lowerText?: string;
}

export const ActionCard: FC<Props> = ({
  icon,
  headerText,
  lowerText,
  ...stackProps
}) => {
  return (
    <Stack layerStyle='WelcomeCard' gap='4' {...stackProps}>
      {icon}
      <Stack align='center' gap='1' w='full'>
        <Text fontSize='sm' fontWeight='bold'>
          {headerText}
        </Text>
        <Text maxW='90%' fontSize='xs' fontWeight='normal' lineHeight='4'>
          {lowerText}
        </Text>
      </Stack>
    </Stack>
  );
};
