import { ColorProps, IconButton, IconButtonProps } from '@chakra-ui/react';
import { FC, useState } from 'react';

import { AvailableIcons, Icon } from '$/components/common/Icon';

interface LoadingIconButtonProps
  extends Omit<IconButtonProps, 'onClick' | 'icon'> {
  onClick: () => Promise<void>;
  icon: AvailableIcons;
  iconColor: ColorProps['color'];
}

type ButtonState = 'idle' | 'loading' | 'success';

export const RespondingIconButton: FC<LoadingIconButtonProps> = ({
  onClick,
  icon,
  iconColor = 'reverseText',
  ...props
}) => {
  const [buttonState, setButtonState] = useState<ButtonState>('idle');

  const handleClick = async () => {
    setButtonState('loading');
    await onClick();
    setButtonState('success');
    setTimeout(() => {
      setButtonState('idle');
    }, 1000);
  };

  return (
    <IconButton
      {...props}
      bg={buttonState === 'success' ? 'success.500' : undefined}
      _hover={buttonState === 'success' ? { bg: 'success.500' } : undefined}
      aria-label='icon button'
      icon={
        buttonState === 'success' ? (
          <Icon icon='check_icon' strokeWidth={2} color='reverseText' />
        ) : (
          <Icon icon={icon} color={iconColor} w='16px' />
        )
      }
      isLoading={buttonState === 'loading'}
      onClick={handleClick}
      variant='primary'
    />
  );
};
