import { HStack } from '@chakra-ui/react';
import { Await } from '@tanstack/react-router';
import { Suspense } from 'react';

import { For } from '$/components/common/Flow/For';
import { FilterCard } from '$/pages/DashboardPages/pages/Inspiration/components/FilterCard';
import { LoadingFilterCard } from '$/pages/DashboardPages/pages/Inspiration/components/FilterCard/LoadingFilterCard';
import { InspirationsRoute } from '$/routes/Inspirations/InspirationsRoute';
import { endpointFactory } from '$/services/endpoints';
import { repeat } from '$/utils/arrayUtils';

export const FilterSection = () => {
  const { data } = InspirationsRoute.useLoaderData();

  return (
    <HStack>
      <HStack
        className='hidden-scrollbar'
        wrap={{ base: 'nowrap', md: 'wrap' }}
        gap={{ base: '2', md: '4' }}
        overflowX={{ base: 'auto', md: 'hidden' }}
      >
        <Suspense
          fallback={repeat(8).map((id) => (
            <LoadingFilterCard key={id} id={id} />
          ))}
        >
          <Await promise={data}>
            {({ categories, scenes }) => (
              <For
                each={categories.filter(
                  (category) => scenes && scenes[category.id]?.length > 0,
                )}
              >
                {(category) => {
                  const imageId = scenes[category.id][0].id;

                  return (
                    <FilterCard
                      key={category.id}
                      category={category}
                      imageUrl={endpointFactory.sceneImageUrl({
                        id: imageId,
                        width: '128',
                        height: '128',
                      })}
                    />
                  );
                }}
              </For>
            )}
          </Await>
        </Suspense>
      </HStack>
    </HStack>
  );
};
