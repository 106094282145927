import {
  Center,
  HStack,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

interface Props {
  value: 'private' | 'public';
  onChange: (value: 'private' | 'public') => void;
}

export const VisibilitySelection: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const options = [
    {
      value: 'private',
      title: t('general_editor.share.privateTitle'),
      description: t('general_editor.share.privateDescription'),
      icon: <Icon icon='lock' h='14px' />,
    },
    {
      value: 'public',
      title: t('general_editor.share.publicTitle'),
      description: t('general_editor.share.publicDescription'),
      icon: <Icon icon='globe' h='14px' />,
    },
  ];

  return (
    <RadioGroup onChange={onChange} value={value}>
      <Stack gap='6'>
        {options.map((option) => (
          <HStack
            key={option.value}
            gap='4'
            m={value === option.value ? '0' : '1px'}
            border={value === option.value ? '2px solid' : '1px solid'}
            borderColor={value === option.value ? 'borderActive' : 'border'}
            borderRadius='4px'
          >
            <Radio w='full' px='3' py='4' value={option.value}>
              <HStack gap='4' ml='2'>
                <Center
                  w='10'
                  bg='bodyBackground'
                  border='1px solid'
                  borderColor='border'
                  borderRadius='4px'
                  aspectRatio='1/1'
                >
                  {option.icon}
                </Center>
                <Stack gap='2px'>
                  <Text fontSize='md' fontWeight='bold' lineHeight='24px'>
                    {option.title}
                  </Text>
                  <Text maxW='250px' fontSize='sm' lineHeight='20px'>
                    {option.description}
                  </Text>
                </Stack>
                <Spacer />
              </HStack>
            </Radio>
          </HStack>
        ))}
      </Stack>
    </RadioGroup>
  );
};
