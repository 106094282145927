import { Accordion } from './Accordion';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { Input } from './Input';
import { Menu } from './Menu';
import { Modal } from './Modal';
import { Popover } from './Popover';
import { Radio } from './Radio';
import { Skeleton } from './Skeleton';
import { Spinner } from './Spinner';
import { Switch } from './Switch';

export const components = {
  Accordion,
  Button,
  Menu,
  Checkbox,
  Modal,
  Input,
  Radio,
  Popover,
  Spinner,
  Switch,
  Skeleton,
};
