import { PartialOptions } from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export const scrollable = (
  overflow: PartialOptions['overflow'],
  overwrite: PartialOptions = {},
) => {
  const options: PartialOptions = {
    overflow,
    scrollbars: { autoHide: 'scroll' },
    ...overwrite,
  };

  return { as: OverlayScrollbarsComponent, options };
};
