import { z } from 'zod';

export const ModuleContextMenuSchema = z
  .object({
    x: z.number(),
    y: z.number(),
    elementIdx: z.number().nullable().optional(),
    layerIdx: z.number().nullable().optional(),
  })
  .transform((data) => ({
    x: data.x,
    y: data.y,
    elementIndex: data.elementIdx,
    layerIndex: data.layerIdx,
  }));

export type ModuleContextMenu = z.infer<typeof ModuleContextMenuSchema>;
