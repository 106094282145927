import { Box } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

import { AuthenticationModal } from '$/components/core/Authentication/AuthenticationModal';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { FullscreenButton } from '$/components/core/Editor/AttributeSidebar/components/FullscreenButton';
import { InactivityModal } from '$/components/core/Editor/InactivityModal';
import { BottomActionBar } from '$/components/core/Editor/Mobile/BottomActionBar';
import { TopActionBar } from '$/components/core/Editor/Mobile/TopActionBar';
import { useIsMobile } from '$/hooks/useIsMobile';
import { useKeyPress } from '$/hooks/useKeyPress';
import { ActionBarSection } from '$/pages/EditorPage/components/ActionbarSection';
import { AttributesSection } from '$/pages/EditorPage/components/AttributesSection';
import { ComponentSection } from '$/pages/EditorPage/components/ComponentSection';
import { EditorFullscreenModal } from '$/pages/EditorPage/components/EditorFullscreenModal';
import { LoadModal } from '$/pages/EditorPage/components/LoadModal/LoadModal';
import { ModuleSection } from '$/pages/EditorPage/components/ModuleSection';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { editorActions } from '$/pages/EditorPage/stores/editorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { EditorRoute } from '$/routes/Editor/EditorRoute';
import { queryClient } from '$/services/fetcher';
import { editorProjectQuery } from '$/services/usecases/projects/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

const DesktopContent = () => {
  const { projectId } = EditorRoute.useSearch();
  const freeUserToken = useEditorStore.useFreeUserToken();
  const projectName = useEditorStore.useProjectName();

  const activeLayer = useEditorStore.useActiveLayerIndex();
  const activeComponent = useEditorStore.useActiveComponentIndex();
  const actions = useEditorActions();

  const user = useAuthenticationStore.useUser();

  useKeyPress(({ ctrl, key }) => {
    if (ctrl && key === 'z') {
      actions.selectAction(ModuleSendSelectAction.Undo);
    }

    if (ctrl && key === 'y') {
      actions.selectAction(ModuleSendSelectAction.Redo);
    }

    if (key === 'Delete') {
      if (activeComponent == null) return;
      if (activeLayer == null) {
        actions.deleteComponent(activeComponent);
      } else {
        actions.deleteObject(activeComponent, activeLayer);
      }
    }
  });

  return (
    <>
      {projectId == null && freeUserToken == null && <LoadModal />}
      <ComponentSection projectName={projectName ?? ''} />
      <ActionBarSection groups={editorActions} />
      <AttributesSection />
      <EditorFullscreenModal />
      <FullscreenButton />
      {user?.id == null && <InactivityModal />}
    </>
  );
};

const MobileContent = () => {
  const projectName = useEditorStore.useProjectName();

  const { zoomPercentage, selectAction } = useEditorActions();

  useEffect(() => {
    zoomPercentage(30);
    selectAction(ModuleSendSelectAction.SelectPoly);
  }, [zoomPercentage, selectAction]);

  return (
    <>
      <TopActionBar name={projectName} />
      <BottomActionBar mode='editor' />
    </>
  );
};

export const EditorPage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const freeUserToken = useEditorStore.useFreeUserToken();

  const { saveProject } = useEditorActions();
  const { projectId } = EditorRoute.useSearch();

  const user = useAuthenticationStore.useUser();
  const setHasUser = useCollectionStore.useSetHasUser();

  useEffect(() => {
    if (projectId == null && freeUserToken == null) return;

    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return true;
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [freeUserToken, projectId]);

  useEffect(() => {
    void setHasUser(user?.id != null);

    if (user?.id != null && projectId == null) {
      saveProject();
    }
  }, [projectId, saveProject, setHasUser, user?.id]);

  useEffect(() => {
    const loadProject = async () => {
      if (projectId == null) {
        if (isMobile) await navigate({ to: '/not-allowed' });
        return;
      }

      const { response, httpStatusCode, isSuccessful } =
        await queryClient.fetchQuery(editorProjectQuery(projectId));

      if (!isSuccessful || response.payload == null) {
        if (httpStatusCode === 401) {
          await navigate({ to: '/not-allowed' });
        }
        return;
      }

      useEditorStore.setState({
        editorProject: response.payload,
        projectName: response.payload.projectName,
      });
    };

    void loadProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <>
      <ModuleSection />
      {!isMobile && <DesktopContent />}
      {isMobile && <MobileContent />}
      <AuthenticationModal />
      <Box pos='relative' zIndex={-2} w='100vw' h='100dvh' />
    </>
  );
};
