import {
  Box,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';

import { FilterSidebar } from '$/components/core/Editor/MaterialFullscreen/component/FilterSidebar';
import { FullscreenOverviewHeader } from '$/components/core/Editor/MaterialFullscreen/component/FullscreenOverviewHeader';
import { FullscreenSearchHeader } from '$/components/core/Editor/MaterialFullscreen/component/FullscreenSearchHeader';
import { FullscreenFavoritesModal } from '$/components/core/Editor/MaterialFullscreen/pages/FullscreenFavoritesModal';
import { FullscreenOverviewModal } from '$/components/core/Editor/MaterialFullscreen/pages/FullscreenOverviewModal';
import { MaterialSelectionModal } from '$/components/core/Editor/MaterialFullscreen/pages/MaterialSelectionModal';
import { useMaterialFullscreenStore } from '$/components/core/Editor/MaterialFullscreen/store/useMaterialFullscreenStore';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';

interface Props {
  activeMaterial?: Material | null;
  materialTypes?: MaterialType[];
  selectMaterial: (material: Material) => void;
}

export const MaterialFullscreenModal: FC<Props> = ({
  activeMaterial,
  materialTypes,
  selectMaterial,
}) => {
  const modalType = useMaterialFullscreenStore.useModalType();
  const isOpen = useMaterialFullscreenStore.useIsModalOpen();

  const onClose = useMaterialFullscreenStore.useOnModalClose();
  const setModalType = useMaterialFullscreenStore.useSetModalType();

  const select = (material: Material) => {
    selectMaterial(material);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) useMaterialFullscreenStore.getState().reset();
  }, [isOpen, setModalType]);

  const showFilterSidebar = modalType === 'material';

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        w='90%'
        maxW='1200px'
        h='90%'
        maxH='940px'
        borderRadius='16px'
      >
        <Grid
          templateColumns={
            showFilterSidebar
              ? { base: '200px 1fr', md: '288px 1fr' }
              : '0px 1fr'
          }
          minH='0'
        >
          <Box overflowY='auto'>
            {showFilterSidebar && <FilterSidebar material={activeMaterial} />}
          </Box>

          <Grid templateRows='auto 1fr' minH='0'>
            {modalType === 'overview' && (
              <FullscreenOverviewHeader material={activeMaterial} />
            )}

            {(modalType === 'favorites' || modalType === 'material') && (
              <FullscreenSearchHeader material={activeMaterial} />
            )}

            <ModalBody overflowY='auto' px='8' pb='8'>
              {modalType === 'overview' && (
                <FullscreenOverviewModal
                  onSelect={select}
                  materialTypes={materialTypes}
                />
              )}
              {modalType === 'material' && (
                <MaterialSelectionModal onSelect={select} />
              )}
              {modalType === 'favorites' && (
                <FullscreenFavoritesModal onSelect={select} />
              )}
            </ModalBody>
          </Grid>
        </Grid>
      </ModalContent>
    </Modal>
  );
};
