import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends IconButtonProps {
  toggleFavorite?: VoidFunction;
}

export const FavoriteButton: FC<Props> = ({ toggleFavorite, ...props }) => {
  return (
    <IconButton
      {...props}
      layerStyle='floatCardButton'
      boxSize='6'
      borderRadius='4px'
      isLoading={!toggleFavorite}
      onClick={(e) => {
        e.stopPropagation();
        toggleFavorite?.();
      }}
      variant='text'
    />
  );
};
