import { SimpleGrid, Skeleton, Stack } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { outlined } from '$/components/common/Accessibility';
import { Icon } from '$/components/common/Icon';
import { AttributeHeader } from '$/components/core/Editor/AttributeHeader';
import { ColorBox } from '$/components/core/Editor/ColorSelectionSidebar/ColorBox';
import { Pagination } from '$/components/core/Pagination';
import { Material } from '$/services/usecases/materials';
import {
  useFanMaterials,
  useFanMaterialsPageCount,
} from '$/stores/useFanMaterials';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  material: Material;
  onColorChange: (material: Material) => void;
}

export const FanPageSelector: FC<Props> = ({ material, onColorChange }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(material.position.page);
  useEffect(() => setCurrentPage(material.position.page), [material]);

  const pageCount = useFanMaterialsPageCount(material.rawCollection);
  const [isLoading, fanMaterials] = useFanMaterials(
    material.rawCollection,
    currentPage,
  );

  if (!isLoading && pageCount && (!fanMaterials.length || pageCount <= 0))
    return null;

  return (
    <Stack gap='3'>
      <AttributeHeader
        label={t('inspirationEditor.filter.fanPage')}
        icon={<Icon icon='collection' boxSize='4' />}
        description='MISSING TRANSLATION'
      />

      <SimpleGrid w='full' px='1' columns={fanMaterials.length || 6}>
        {fanMaterials.length === 0 &&
          repeat(6).map((id, i) => (
            <Skeleton
              key={id}
              w='full'
              h={{ base: '10', mobile: '6' }}
              borderRightRadius={i === 5 ? '4px' : '0'}
              borderLeftRadius={i === 0 ? '4px' : '0'}
            />
          ))}

        {fanMaterials.map((fanMaterial, index) => {
          const isActive = fanMaterial.uniqueKey === material.uniqueKey;
          const isFirstElement = index === 0;
          const isLastElement = index === fanMaterials.length - 1;

          return (
            <ColorBox
              key={fanMaterial.id}
              onClick={() => onColorChange(fanMaterial)}
              materialColor={fanMaterial.color}
              image={fanMaterial.url({ width: '30', height: '30' })}
              w='full'
              h={{ base: '10', mobile: '6' }}
              zIndex={isActive ? '1' : '0'}
              border='1px solid'
              borderColor='border'
              borderLeft={isFirstElement || isActive ? 'auto' : 'none'}
              borderRightRadius={isLastElement ? '4px' : '0'}
              borderLeftRadius={isFirstElement ? '4px' : '0'}
              borderRadius={isActive ? '4px !important' : undefined}
              {...outlined({ visible: isActive })}
            />
          );
        })}
      </SimpleGrid>

      <Pagination
        currentPage={currentPage}
        pageCount={pageCount}
        onPageChange={setCurrentPage}
      />
    </Stack>
  );
};
