import { createRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { NewPasswordPage } from '$/pages/Authentication/pages/NewPasswordPage';
import { RootRoute } from '$/routes/RootRoute';

const searchSchema = z.object({
  otp: z.number(),
  email: z.string().email(),
});

export const NewPasswordRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/new-password',
  component: NewPasswordPage,
  validateSearch: searchSchema,
});
