import { HStack, Spacer, Switch, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';

export const VisibilityToggle = () => {
  const { t } = useTranslation();
  const { toggleVisibility } = useEditorActions();
  const [isVisible, setIsVisible] = useState(true);

  const onToggle = () => {
    toggleVisibility(!isVisible);
    setIsVisible((current) => !current);
  };

  return (
    <HStack as='button' w='full' px='4' py='1' color='lighterText'>
      <Icon icon='visibility_on' boxSize='4' />
      <Text
        color='lighterText'
        fontSize='sm'
        fontWeight='bold'
        onClick={onToggle}
      >
        {t('editor.visibility')}
      </Text>
      <Spacer />
      <Switch isChecked={isVisible} onChange={onToggle} />
    </HStack>
  );
};
