import { Modal, ModalOverlay } from '@chakra-ui/react';
import { useEffect } from 'react';

import { LoginModalContent } from '$/components/core/Authentication/components/LoginModalContent';
import { LoginToSaveModalContent } from '$/components/core/Authentication/components/LoginToSaveModalContent';
import { PasswordForgottenModalContent } from '$/components/core/Authentication/components/PasswordForgottenModalContent';
import { RegisterModalContent } from '$/components/core/Authentication/components/RegisterModalContent';
import { RegisterOtpModalContent } from '$/components/core/Authentication/components/RegisterOtpModalContent';
import { RegisterSuccessModalContent } from '$/components/core/Authentication/components/RegisterSuccessModalContent';
import { VersionComparisonModalContent } from '$/components/core/Authentication/components/VersionComparisonModalContent';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { trackContentImpression, trackContentInteraction } from '$/logger';
import { mapAuthModalTypeToPiwikContentPiece } from '$/utils/piwikUtils';

export const AuthenticationModal = () => {
  const isAuthenticationModalOpen = useAuthenticationStore.useIsModalOpen();
  const onCloseAuthenticationModal = useAuthenticationStore.useOnModalClose();
  const setModalType = useAuthenticationStore.useSetModalType();
  const authenticationModalType = useAuthenticationStore.useModalType();

  useEffect(() => {
    if (!isAuthenticationModalOpen) {
      return;
    }

    const contentPiece = mapAuthModalTypeToPiwikContentPiece(
      authenticationModalType,
    );

    if (!contentPiece) {
      return;
    }

    trackContentImpression('CloudAccount', contentPiece, 'Open');
  }, [isAuthenticationModalOpen, authenticationModalType]);

  const handleCloseAuthenticationModal = () => {
    const contentPiece = mapAuthModalTypeToPiwikContentPiece(
      authenticationModalType,
    );

    if (contentPiece) {
      trackContentInteraction(
        'ClickButton',
        'CloudAccount',
        contentPiece,
        'Close',
      );
    }

    onCloseAuthenticationModal();
  };

  const getModalContent = () => {
    switch (authenticationModalType) {
      case 'login':
        return <LoginModalContent />;
      case 'register':
        return <RegisterModalContent />;
      case 'passwordForgotten':
        return <PasswordForgottenModalContent />;
      case 'registerOtp':
        return <RegisterOtpModalContent />;
      case 'registerSuccess':
        return (
          <RegisterSuccessModalContent onClose={() => setModalType('login')} />
        );
      case 'versionComparison':
        return <VersionComparisonModalContent />;
      case 'loginToSave':
        return <LoginToSaveModalContent onClose={onCloseAuthenticationModal} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      isCentered
      isOpen={isAuthenticationModalOpen}
      onClose={handleCloseAuthenticationModal}
    >
      <ModalOverlay />
      {getModalContent()}
    </Modal>
  );
};
