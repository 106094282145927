import { Box, Button, Skeleton } from '@chakra-ui/react';
import { useRouter } from '@tanstack/react-router';
import { FC } from 'react';

import { FloatingCard } from '$/components/common/FloatingCard';
import { Icon } from '$/components/common/Icon';
import { ThemeSwitcher } from '$/components/common/ThemeSwitcher';

interface Props {
  name?: string | null;
}

export const TopActionBar: FC<Props> = ({ name }) => {
  const router = useRouter();

  return (
    <FloatingCard
      wrapper={{ top: '0', p: '4' }}
      justify='space-between'
      p='1'
      pr='4'
    >
      <Button
        py='10px'
        fontSize='sm'
        leftIcon={
          <Icon
            icon='arrow_right'
            transform='scale(-1)'
            color='lighterText'
            w='14px'
            mr='2'
          />
        }
        onClick={() => router.history.back()}
        variant='ghost'
      >
        {!name ? <Skeleton w='100px'>T</Skeleton> : name}
      </Button>
      <Box h='24px'>
        <ThemeSwitcher />
      </Box>
    </FloatingCard>
  );
};
