import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FC, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ExportFileTypeSelection,
  ExportType,
} from '$/components/core/Editor/ExportFileTypeSelection';
import { useToast } from '$/hooks/useToast';
import { ModuleReceiveAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { ModuleReceiveMessage } from '$/pages/EditorPage/hooks/useEditorModuleListener';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { config } from '$/services/endpoints';
import { SaveProjectResponseSchema } from '$/services/usecases/editor/mapper/saveProjectResponse';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { toUniqueArray } from '$/utils/arrayUtils';

export interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

export type ExportFormData = {
  exportType: ExportType;
};

const reducer = (state: ExportFormData, newData: Partial<ExportFormData>) => {
  return { ...state, ...newData };
};

export const ExportEditorModal: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const components = useEditorStore.useComponents();
  const materialIds = toUniqueArray(
    components
      .map((component) => component.materialId)
      .filter((id) => id != null) as string[],
  );
  const { saveProject } = useEditorActions();
  const projectName = useEditorStore.useProjectName();

  const { data: materials } = useQuery(materialsQuery({ ids: materialIds }));

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, {
    exportType: 'jpg',
  });

  const onMessageReceived = async (event: MessageEvent) => {
    if (event.origin !== config.EDITOR_BASE_URL) return;
    const data = (
      typeof event.data == 'string' ? JSON.parse(event.data) : event.data
    ) as ModuleReceiveMessage;

    if (data.action == null) {
      return;
    }
    data.action = data.action.toLocaleLowerCase() as ModuleReceiveAction;

    if (data.action === ModuleReceiveAction.ProjectSaved) {
      const { projectId } = SaveProjectResponseSchema.parse(data);

      try {
        const exportModule = await import(
          '$/pages/EditorPage/components/ExportModal/utils/ExportHelper'
        );
        await exportModule.generateExportZipFolderWithContent(
          state.exportType,
          projectId,
          components.map((component, index) => ({
            id: index.toString(),
            category: null,
            material:
              materials?.materials.find(
                (material) => material.id === component.materialId,
              ) ?? null,
            name: component.name,
          })),
          projectName ?? 'Project',
          t,
        );
      } catch (error: unknown) {
        console.error(error);
        toast(
          'Something went wrong',
          'error',
          'There was an error generating your export',
        );
      }
      setIsLoading(false);

      window.removeEventListener('message', onMessageReceived);
    }
  };

  const onExport = () => {
    setIsLoading(true);

    window.addEventListener('message', onMessageReceived);

    saveProject();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minW={{ base: 'unset', mobile: '500px' }}
        maxW='500px'
        mx='4'
        p={{ base: '4', mobile: '10' }}
      >
        <HStack justify='space-between' pb='4'>
          <Text fontSize={{ base: 'h10', mobile: 'h7' }} fontWeight='bold'>
            {t('editor.exportText')}
          </Text>
          <ModalCloseButton pos='static' />
        </HStack>

        <ModalBody p='0'>
          <Stack gap={{ base: '4', mobile: '6' }}>
            <ExportFileTypeSelection
              exportType={state.exportType}
              dispatch={dispatch}
            />
          </Stack>

          <HStack justifyContent='center' mt={{ base: '6', mobile: '10' }}>
            <Button
              w={{ base: 'full', mobile: 'fit-content' }}
              fontSize='sm'
              isLoading={isLoading}
              onClick={onExport}
            >
              {t('editor.exportText')}
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
