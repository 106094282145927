import { HStack, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { Material } from '$/services/usecases/materials';
import { hexToRgb } from '$/utils/colorUtils';

interface DetailRowProps {
  label: string;
  value: string | number;
}

const DetailRow: FC<DetailRowProps> = ({ label, value }) => {
  return (
    <HStack justify='space-between' fontSize='sm' lineHeight='20px'>
      <Text>{label}</Text>
      <Text fontWeight='bold' textAlign='end'>
        {value}
      </Text>
    </HStack>
  );
};

interface MaterialDetailsProps {
  material: Material;
}

export const MaterialDetails: FC<MaterialDetailsProps> = ({ material }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <DetailRow
        label={t('inspirationEditor.material.producer')}
        value={material.producer}
      />
      <DetailRow
        label={t('inspirationEditor.material.collection')}
        value={material.collection}
      />
      <Show when={material.brightness !== -1}>
        <DetailRow
          label={t('inspirationEditor.material.brightness')}
          value={material.brightness}
        />
      </Show>
      <Show when={material.color !== null && material.color !== '#'}>
        <DetailRow
          label={t('inspirationEditor.material.rgb')}
          value={hexToRgb(material.color).join(' ')}
        />
      </Show>
      <Show when={material.LCH !== null && material.LCH !== ''}>
        <DetailRow
          label={t('inspirationEditor.material.lch')}
          value={material.LCH}
        />
      </Show>
      <Show when={material.position.pagePosition !== 0}>
        <DetailRow
          label={t('inspirationEditor.material.position')}
          value={material.position.pagePosition}
        />
      </Show>
      <Show when={material.family !== ''}>
        <DetailRow
          label={t('inspirationEditor.material.family')}
          value={material.family}
        />
      </Show>
    </Stack>
  );
};
