import {
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { Icon } from '$/components/common/Icon';
import { useNonFreeAction } from '$/hooks/useNonFreeAction';
import { AddVariantButton } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/AddVariantButton';
import { ComparisonModal } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/ComparisonModal';
import { VariantCard } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/VariantCard';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { indexToLetter } from '$/utils/numberUtils';

export const VariantSection = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onToggle } = useDisclosure();

  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();
  const handleNonFreeAction = useNonFreeAction();

  return (
    <Stack gap='3'>
      <Text color='lighterText' fontWeight='bold'>
        {t('editor.variants')}
      </Text>

      <SimpleGrid gap='1' w='full' columns={3}>
        {sceneLayersVariants.length === 0 && (
          <Skeleton h='40px' borderRadius='4px' aspectRatio='3/2' />
        )}
        {sceneLayersVariants.map((_, index) => (
          <VariantCard key={index} variantButtonIndex={index} />
        ))}
        {sceneLayersVariants.length < 3 && (
          <AddVariantButton
            variantName={indexToLetter(sceneLayersVariants.length)}
          />
        )}
      </SimpleGrid>
      <TrackedButton
        h='8'
        mt='2'
        fontSize='xs'
        fontWeight='normal'
        isDisabled={sceneLayersVariants.length < 2}
        leftIcon={<Icon icon='compare' width='6' />}
        onClick={() => handleNonFreeAction(() => onToggle())}
        variant='secondary'
        contentInteraction='ClickVariants'
        contentName='Variants'
        contentPiece='CompareVariantes'
        contentTarget={{
          targetCount: sceneLayersVariants.length,
        }}
      >
        {t('inspirationEditor.compareVariants')}
      </TrackedButton>
      <ComparisonModal isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
};
