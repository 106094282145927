import { Box } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';

import { MobileSubFilterPage } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileSubFilterPage';
import { FavoriteFolderView } from '$/components/core/Editor/ColorSelectionSidebar/Favorites/FavoriteFolderView';
import { EditorFilterMainPage } from '$/components/core/Editor/Mobile/components/Filter/EditorFilterMainPage';
import { FilterType, MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

interface Props {
  initialType: FilterType;
  material?: Material | null;
  materialTypes?: MaterialType[];
  onSelectMaterial: (material: Material) => void;
  isOpen: boolean;
  onClose: () => void;
  mode: 'inspiration' | 'editor';
  onBack?: () => void;
}

export const EditorFilterDrawerContent: FC<Props> = ({
  initialType,
  material,
  materialTypes,
  onSelectMaterial,
  isOpen,
  onClose,
  mode,
  onBack,
}) => {
  const [subFilter, setSubFilter] = useState<string | null>(null);

  const activeFolderId = useFavoriteStore.useActiveFolderId();

  useEffect(() => {
    if (!isOpen && subFilter) setSubFilter(null);
  }, [isOpen, subFilter]);

  return (
    <Box overflowY='auto' pb='8'>
      {!subFilter && !activeFolderId && (
        <EditorFilterMainPage
          closeFilterDrawer={onClose}
          setSubFilter={setSubFilter}
          type={initialType}
          onSelectMaterial={onSelectMaterial}
          material={material}
          materialTypes={materialTypes}
          onBack={onBack}
          mode={mode}
        />
      )}
      {subFilter && !activeFolderId && (
        <MobileSubFilterPage
          selectedSubFilter={subFilter}
          closeFilterDrawer={onClose}
          setSubFilter={setSubFilter}
          closeOnSelect={false}
        />
      )}
      {activeFolderId && (
        <FavoriteFolderView
          activeMaterialId={material?.id}
          onClickItem={(m) => {
            useFavoriteStore.getState().setActiveFolderId(null);
            onSelectMaterial(m);
          }}
          drawerMode
        />
      )}
    </Box>
  );
};
