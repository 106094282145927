import { Fade, IconButton } from '@chakra-ui/react';
import { FC, ForwardedRef, RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

interface Props {
  containerRef: RefObject<HTMLDivElement> | ForwardedRef<HTMLDivElement>;
  right: string;
  bottom: string;
}

export const BackToTopButton: FC<Props> = ({ containerRef, right, bottom }) => {
  const { t } = useTranslation();
  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    if (
      containerRef &&
      typeof containerRef === 'object' &&
      containerRef.current
    ) {
      const container = containerRef.current;

      const handleScroll = () => {
        if (container.scrollTop > 100) {
          setShowElement(true);
        } else {
          setShowElement(false);
        }
      };

      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [containerRef]);

  const onClick = () => {
    if (
      containerRef &&
      typeof containerRef === 'object' &&
      containerRef.current
    ) {
      containerRef.current.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    }
  };

  return (
    <Fade in={showElement} unmountOnExit>
      <IconButton
        pos='absolute'
        zIndex='sticky'
        right={right}
        bottom={bottom}
        display={{ base: 'inline-flex', mobile: 'none' }}
        w='3rem'
        minW='0'
        maxW='fit-content'
        h='3rem'
        px='3'
        py='1'
        bg='background'
        border='1px solid'
        borderColor='border'
        borderRadius='8px'
        aria-label={t('general.backToTop')}
        icon={<Icon w='30px' transform='rotate(-90deg)' icon='arrow_right' />}
        onClick={onClick}
        variant='text'
      ></IconButton>
    </Fade>
  );
};
