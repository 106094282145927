import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { AttributeHeader } from '$/components/core/Editor/AttributeHeader';
import { BrightnessFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/BrightnessFilter';
import { ListFilter } from '$/components/core/Editor/MaterialFullscreen/component/FilterSidebar/components/ListFilter';
import { TagFilter } from '$/components/core/Editor/MaterialFullscreen/component/FilterSidebar/components/TagFilter';
import { useMaterialFullscreenStore } from '$/components/core/Editor/MaterialFullscreen/store/useMaterialFullscreenStore';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { useCollectionStore } from '$/stores/useCollectionStore';

const typeThreshold = 15;

export const FilterItems = () => {
  const { t } = useTranslation();
  const filter = useCollectionStore.useFilter();
  const modalPayload = useMaterialFullscreenStore.useModalPayload();

  if (!filter) return null;

  const allFilter = [...filter.general, ...filter.additional];

  return (
    <Stack>
      {allFilter.map((filter) => {
        return (
          <Stack key={filter.searchName} gap='4'>
            <SidebarDivider pt='2' />
            <Stack gap='3' px='4'>
              <AttributeHeader
                icon={<Icon w='4' h='4' icon={filter.iconName} />}
                label={t(filter.displayNameKey)}
              />
              {filter.items.length < typeThreshold && (
                <TagFilter filter={filter} />
              )}
              {filter.items.length >= typeThreshold && (
                <ListFilter filter={filter} />
              )}
            </Stack>
          </Stack>
        );
      })}

      {modalPayload.materialType === 'uni' && (
        <>
          <SidebarDivider pt='2' />
          <BrightnessFilter px='4' />
        </>
      )}
    </Stack>
  );
};
