import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from '$/hooks/useToast';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { buildRenderedInspirationImageUrl } from '$/services/endpoints';
import {
  upsertInspirationProject,
  uploadProjectThumbnail,
} from '$/services/usecases/projects';

export const useSaveInspirationProject = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const [isSaving, setIsSaving] = useState(false);

  const scene = useInspirationEditorStore.useScene();
  const variants = useInspirationEditorStore.useSceneLayersVariants();
  const currentProjectId = useInspirationEditorStore.useCurrentProjectId();

  const setCurrentProjectId =
    useInspirationEditorStore.useSetCurrentProjectId();

  const onSaveProject = async () => {
    if (scene === null) return;

    setIsSaving(true);

    const variantArray = variants.map((variant) =>
      variant.map((layer) => ({
        objectId: layer.id,
        materialUniqueKey: layer.material?.uniqueKey ?? null,
      })),
    );

    const response = await upsertInspirationProject(
      variantArray,
      scene?.id,
      scene?.name,
      currentProjectId,
    );

    if (response.isSuccessful) {
      setCurrentProjectId(response.response.payload.id);

      toast(
        t('editor.projectSaved'),
        'success',
        t('editor.projectSavedDescription'),
        { customIcon: 'check_icon' },
      );

      const imageResponse = await fetch(
        buildRenderedInspirationImageUrl({
          layers: variants[0],
          sceneId: scene.id,
          resolution: {
            width: 500,
            height: 500,
          },
        }),
      );
      const imageBlob = await imageResponse.blob();
      const file = new File([imageBlob], 'image.jpg', { type: imageBlob.type });
      await uploadProjectThumbnail(response.response.payload.id, file);
    } else {
      toast(
        t('error.somethingWentWrong'),
        'error',
        t('error.somethingWentWrongDescription'),
      );
    }

    setIsSaving(false);
  };

  return [isSaving, onSaveProject] as const;
};
