import {
  Box,
  Button,
  HStack,
  Stack,
  VStack,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { TestPatternToggle } from '$/pages/EditorPage/components/AttributesSection/components/TestpatternToggle';
import { TitleSection } from '$/pages/EditorPage/components/AttributesSection/components/TitleSection';
import { UnitOption } from '$/pages/EditorPage/components/AttributesSection/components/UnitOption';
import { VisibilityToggle } from '$/pages/EditorPage/components/AttributesSection/components/VisibilityToggle';
import { VisualizationSection } from '$/pages/EditorPage/components/AttributesSection/components/VisualizationSection';
import { EditorNumberInput } from '$/pages/EditorPage/components/AttributesSection/NumberInput';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const GridSidebar = () => {
  const { t } = useTranslation();
  const { moveGrid, setElementSettings } = useEditorActions();
  const components = useEditorStore.useComponents();
  const activeComponentIndex = useEditorStore.useActiveComponentIndex();
  const [currentUnit, setCurrentUnit] = useState<'cm' | 'm'>('cm');

  if (activeComponentIndex == null) return;

  const activeComponent = components[activeComponentIndex];

  const onResizeGrid = (width: number, height: number) => {
    setElementSettings(activeComponentIndex, {
      height,
      width,
    });
  };

  const toVisualNumber = (value: number) => {
    return currentUnit === 'cm' ? value / 10 : value / 1000;
  };

  const fromVisualNumber = (value: number) => {
    return currentUnit === 'cm' ? value * 10 : value * 1000;
  };

  return (
    <VStack w='full' fontSize='sm'>
      <TitleSection title={t('editor.grid')} />
      <SidebarDivider />
      <VisualizationSection />
      <SidebarDivider />
      <TestPatternToggle
        componentIndex={activeComponentIndex}
        initialValue={activeComponent.showTestPattern}
      />
      <SidebarDivider />
      <Stack w='full' mt='1' mb='2' px='4'>
        <SectionTitle title={t('editor.realWorldDimensions')} icon='size' />
        <EditorNumberInput
          text={t('editor.dimensions.height')}
          initialValue={toVisualNumber(activeComponent.grid.height)}
          onChange={(val) =>
            onResizeGrid(activeComponent.grid.width, fromVisualNumber(val))
          }
          minValue={1}
          stepSize={currentUnit === 'cm' ? 10 : 0.5}
        />
        <EditorNumberInput
          text={t('editor.dimensions.width')}
          initialValue={toVisualNumber(activeComponent.grid.width)}
          onChange={(val) =>
            onResizeGrid(fromVisualNumber(val), activeComponent.grid.height)
          }
          minValue={1}
          stepSize={currentUnit === 'cm' ? 10 : 0.5}
        />
        <HStack mt='2'>
          <Text fontSize='sm'>{t('editor.unit')}</Text>
          <Spacer />
          <UnitOption
            activeUnit={currentUnit}
            unit='cm'
            onClick={(unit) => setCurrentUnit(unit)}
          />
          <UnitOption
            activeUnit={currentUnit}
            unit='m'
            onClick={(unit) => setCurrentUnit(unit)}
          />
        </HStack>
      </Stack>
      <SidebarDivider />
      <Stack w='full' mt='1' mb='2' px='4'>
        <SectionTitle title={t('editor.position.title')} icon='position' />
        <EditorNumberInput
          text='X'
          initialValue={activeComponent.grid.x}
          onChange={(val) =>
            moveGrid(activeComponentIndex, val, activeComponent.grid.y)
          }
        />
        <EditorNumberInput
          text='Y'
          initialValue={activeComponent.grid.y}
          onChange={(val) =>
            moveGrid(activeComponentIndex, activeComponent.grid.x, val)
          }
        />
      </Stack>
      <SidebarDivider />
      <VisibilityToggle />
      <SidebarDivider />
      <Box w='full' mt='1' px='4'>
        <Button
          w='full'
          minH='0'
          py='2'
          fontSize='xs'
          fontWeight='normal'
          variant='secondary'
        >
          {t('editor.resetToAuto')}
        </Button>
      </Box>
    </VStack>
  );
};
