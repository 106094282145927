import { HStack, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { MaterialListItem } from '$/components/core/Collection/MaterialListItem';
import { AttributeHeader } from '$/components/core/Editor/AttributeHeader';
import { FilterItems } from '$/components/core/Editor/MaterialFullscreen/component/FilterSidebar/components/FilterItems';
import { useMaterialFullscreenStore } from '$/components/core/Editor/MaterialFullscreen/store/useMaterialFullscreenStore';
import { Material } from '$/services/usecases/materials';

interface Props {
  material?: Material | null;
}

export const FilterSidebar: FC<Props> = ({ material }) => {
  const { t } = useTranslation();

  const modalPayload = useMaterialFullscreenStore.useModalPayload();

  const materialTitle = t(
    `general_editor.materialType.${modalPayload.materialType!}`,
  );

  return (
    <Stack
      gap='4'
      minH='full'
      py='8'
      borderRight='solid 1px'
      borderRightColor='border'
    >
      <HStack gap='3' pl='4'>
        <Icon w='6' h='6' icon='color_palette' />
        <Text fontSize='xl' fontWeight='bold'>
          {t('general_editor.filter')}
        </Text>
      </HStack>
      {material && (
        <Stack gap='4' pl='4'>
          <AttributeHeader
            icon={<Icon w='4' h='4' icon='inspiration_marker' />}
            label={t('general_editor.used', { type: materialTitle })}
          />
          <MaterialListItem material={material} />
        </Stack>
      )}
      <FilterItems />
    </Stack>
  );
};
