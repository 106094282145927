import { useQuery } from '@tanstack/react-query';

import {
  fandeckPageCountQuery,
  fandeckMaterialsQuery,
} from '$/services/usecases/fandeck/queries';

export const useFanMaterials = (collection: string, page: number) => {
  const materials = useQuery(fandeckMaterialsQuery(collection, page));
  const sorted = materials.data?.materials.sort(
    (a, b) => a.position.pagePosition - b.position.pagePosition,
  );
  return [materials.isLoading || materials.isFetching, sorted ?? []] as const;
};

export const useFanMaterialsPageCount = (collection: string) => {
  const pageCount = useQuery(fandeckPageCountQuery(collection));
  return pageCount.data;
};
