import {
  AccordionItem,
  HStack,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponent, useState, KeyboardEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoSelectInputField } from '$/components/common/AutoSelectInputField';
import { Icon } from '$/components/common/Icon';
import { ComponentBitmapItem } from '$/pages/EditorPage/components/ComponentSection/ComponentBitmapItem';
import { ComponentObjectList } from '$/pages/EditorPage/components/ComponentSection/ComponentObjectList';
import { ContextMenuButton } from '$/pages/EditorPage/components/ComponentSection/ContextMenuButton';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { isActionPolyOnlyApplicable } from '$/pages/EditorPage/stores/editorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { ColorLayerImage } from '$/pages/InspirationEditorPage/components/LayerSection/components/ColorLayerImage';
import { EditorComponent } from '$/services/usecases/editor/mapper/editorStatus';
import { materialsQuery } from '$/services/usecases/materials/queries';

interface IComponentListItemProps {
  component: EditorComponent;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  index: number;
  dragIndex: number;
}

export const ComponentListItem: FunctionComponent<IComponentListItemProps> = ({
  component,
  dragHandleProps,
  index,
  dragIndex,
}) => {
  const { t } = useTranslation();
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [name, setName] = useState<string>(component.name);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const deleteAccordionItem = useEditorStore.useDeleteAccordionItem();

  const actions = useEditorActions();

  const activeComponentIndex = useEditorStore.useActiveComponentIndex();
  const components = useEditorStore.useComponents();

  const activeLayer = useEditorStore.useActiveLayerIndex();
  const setComponentActive = useEditorStore.useSetComponentActive();
  const { setHoveredElement, selectAction, selectComponent, setHoveredGrid } =
    useEditorActions();
  const workMode = useEditorStore.useWorkMode();

  const { data } = useQuery(
    materialsQuery(
      {
        ids: component?.materialId ? [component?.materialId] : undefined,
      },
      true,
    ),
  );

  const material = data?.materials.length === 1 ? data.materials[0] : null;

  const onEdit = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key != 'Enter') return;
    actions.renameComponent(index, name);
    setIsInEditMode(false);
  };

  const handleInputBlur = () => {
    actions.renameComponent(index, name);
    setIsInEditMode(false);
  };

  const handleComponentClick = (clickedIndex: number) => {
    setComponentActive(actions, clickedIndex);

    if (
      clickedIndex &&
      components[clickedIndex].mode === 'bitmap' &&
      isActionPolyOnlyApplicable(workMode)
    ) {
      actions.selectAction(ModuleSendSelectAction.SelectPoly);
    }
  };

  const handleRightClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onOpen();
  };

  const enableEditMode = () => {
    setIsInEditMode(true);
    onClose();
  };

  const onDelete = () => {
    deleteAccordionItem(dragIndex);
    actions.deleteComponent(index);
    onClose();
  };

  const onSelectGrid = () => {
    selectComponent(index);
    selectAction(ModuleSendSelectAction.EditGrid);
  };

  const isInBitmapMode = component.mode === 'bitmap';

  const hasLayers = true; //component.layers.length > 0 || isInBitmapMode;

  const isActive =
    activeLayer === null && activeComponentIndex === component.index;

  const isComponentActive = isActive && workMode !== 'grid';

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <AccordionItem w='full' py='1'>
        <PopoverTrigger>
          <HStack justifyContent='space-between' w='full'>
            <HStack
              justifyContent='flex-start'
              gap='1'
              w='full'
              pl='1'
              bg={isComponentActive ? 'secondaryBackground' : 'transparent'}
              _hover={{
                bg: isComponentActive
                  ? 'secondaryBackground'
                  : 'bodyBackground',
              }}
              onMouseLeave={() => {
                setIsHovered(false);
                setHoveredElement(null);
              }}
              onMouseOver={() => {
                setIsHovered(true);
                setHoveredElement(component.index);
              }}
              {...dragHandleProps}
            >
              <AccordionButton
                w='6'
                px='0.5'
                py='2'
                borderRadius='2'
                visibility={hasLayers ? 'visible' : 'hidden'}
                disabled={!hasLayers}
                onClick={(e) => e.stopPropagation()}
              >
                <AccordionIcon />
              </AccordionButton>
              <HStack
                gap='2'
                w='full'
                onClick={(e) => {
                  handleComponentClick(component.index);
                  e.stopPropagation();
                }}
                onContextMenu={handleRightClick}
                onDoubleClick={() => setIsInEditMode(true)}
              >
                <ColorLayerImage material={material} boxSize='16px' />
                {isInEditMode ? (
                  <AutoSelectInputField
                    h='auto'
                    fontSize='xs'
                    borderColor='primaryButton.background'
                    borderRadius='4px'
                    _hover={{
                      borderColor: 'primaryButton.background',
                    }}
                    visibility={isInEditMode ? 'visible' : 'hidden'}
                    onBlur={handleInputBlur}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={onEdit}
                    size='sm'
                    value={name}
                  />
                ) : (
                  <Box
                    as='span'
                    w='full'
                    color='editor.objectText'
                    fontSize='xs'
                    userSelect='none'
                  >
                    {component.name}
                  </Box>
                )}
              </HStack>
              <IconButton
                visibility={isHovered ? 'visible' : 'hidden'}
                aria-label={t('inspirationEditor.delete')}
                disabled={!isHovered}
                icon={<Icon icon='trash_can' boxSize='14px' />}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
                variant='text'
              />
            </HStack>
          </HStack>
        </PopoverTrigger>

        <AccordionPanel p='0'>
          {isInBitmapMode ? (
            <ComponentBitmapItem isActive={isComponentActive} />
          ) : (
            <ComponentObjectList
              parentIndex={component.index}
              componentObjects={component.layers}
            />
          )}
          <HStack
            w='full'
            py='2'
            pl='7'
            color='editor.objectText'
            fontSize='xs'
            bg={isActive ? 'secondaryBackground' : 'transparent'}
            _hover={{
              bg: isActive ? 'secondaryBackground' : 'bodyBackground',
            }}
            onClick={onSelectGrid}
            onMouseEnter={() => setHoveredGrid(component.index)}
            onMouseLeave={() => setHoveredGrid(null)}
          >
            <Icon icon='shape_grid' boxSize='3' />
            <Text>{t('editor.grid')}</Text>
          </HStack>
        </AccordionPanel>
      </AccordionItem>

      <PopoverContent w='fit-content'>
        <PopoverArrow />
        <ContextMenuButton
          onClick={() => enableEditMode()}
          text={t('inspirationEditor.rename')}
        />
        <ContextMenuButton
          onClick={() => onDelete()}
          text={t('inspirationEditor.delete')}
        />
      </PopoverContent>
    </Popover>
  );
};
