import {
  Button,
  Divider,
  HStack,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { Icon } from '$/components/common/Icon';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import {
  HookFormCheckbox,
  HookFormCheckboxProps,
} from '$/components/core/Form/HookFormCheckbox';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';
import { StyledLink } from '$/components/core/StyledLink';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { registerUser } from '$/services/usecases/authentication';
import {
  containsLowercase,
  containsNumber,
  containsSpaces,
  containsSpecialChar,
  containsUppercase,
  emailFormatRegex,
} from '$/utils/regexUtils';

export type RegisterData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  acceptedTerms: boolean;
};

const RegisterInput = (props: HookFormInputProps<RegisterData>) => (
  <HookFormInput<RegisterData> {...props} />
);

const RegisterCheckbox = (props: HookFormCheckboxProps<RegisterData>) => (
  <HookFormCheckbox<RegisterData> {...props} />
);

export const RegisterForm = () => {
  const { t } = useTranslation();

  const formMethods = useForm<RegisterData>({
    mode: 'onTouched',
    delayError: 100,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { cloudMessageTranslation } = useCloudMessageTranslation();

  const setModalType = useAuthenticationStore.useSetModalType();
  const setOtpEmail = useAuthenticationStore.useSetOtpEmail();
  const setAuthenticationModalType = useAuthenticationStore.useSetModalType();

  const onRegister = async (data: RegisterData) => {
    const { response, isSuccessful } = await registerUser(data);

    if (isSuccessful) {
      setOtpEmail(data.email);
      setModalType('registerOtp');
    } else {
      setError(cloudMessageTranslation(response.message));
    }
  };

  const isPasswordValid = (password: string) =>
    password.length >= 10 &&
    containsUppercase(password) &&
    containsLowercase(password) &&
    containsNumber(password) &&
    containsSpecialChar(password) &&
    !containsSpaces(password);

  // eslint is lying, it's another type. Recommended by https://www.i18next.com/overview/typescript#example-with-array
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const featureList = t('dashboard.authentication.signUpAdvantages', {
    returnObjects: true,
  }) as string[];

  return (
    <FormProvider {...formMethods}>
      <VStack
        as='form'
        gap='10'
        onSubmit={formMethods.handleSubmit(onRegister)}
      >
        <Stack gap='6'>
          <Stack gap='4' fontSize='sm'>
            <Text>{t('dashboard.authentication.signUpWelcomeText')}</Text>
            <UnorderedList ml='4'>
              {featureList.map((advantage) => (
                <ListItem key={advantage}>{advantage}</ListItem>
              ))}
            </UnorderedList>
          </Stack>
          <HStack gap='4'>
            <RegisterInput
              accessor='firstName'
              label={t('dashboard.authentication.firstNameLabel')}
              placeholder={t('dashboard.authentication.defaultPlaceholder', {
                field: t('dashboard.authentication.firstNameLabel'),
              })}
              registerOptions={{
                required: t(
                  'dashboard.authentication.errorMessages.isRequired',
                ),
              }}
              hideErrorLabel
            />
            <RegisterInput
              accessor='lastName'
              label={t('dashboard.authentication.lastNameLabel')}
              placeholder={t('dashboard.authentication.defaultPlaceholder', {
                field: t('dashboard.authentication.lastNameLabel'),
              })}
              registerOptions={{
                required: t(
                  'dashboard.authentication.errorMessages.isRequired',
                ),
              }}
              hideErrorLabel
            />
          </HStack>
          <RegisterInput
            type='email'
            accessor='email'
            label={t('dashboard.authentication.emailLabel')}
            placeholder={t('dashboard.authentication.defaultPlaceholder', {
              field: t('dashboard.authentication.emailLabel'),
            })}
            registerOptions={{
              required: t('dashboard.authentication.errorMessages.isRequired'),
              pattern: {
                value: emailFormatRegex,
                message: t(
                  'dashboard.authentication.errorMessages.wrongFormat',
                ),
              },
            }}
            description={t('dashboard.authentication.errorMessages.emailRules')}
          />
          <RegisterInput
            type={showPassword ? 'text' : 'password'}
            accessor='password'
            label={t('dashboard.authentication.insertPasswordLabel')}
            placeholder={t('dashboard.authentication.defaultPlaceholder', {
              field: t('dashboard.authentication.passwordLabel'),
            })}
            registerOptions={{
              required: t('dashboard.authentication.errorMessages.isRequired'),
              validate: {
                passwordValid: (v) =>
                  isPasswordValid(String(v)) ||
                  t(
                    'dashboard.authentication.errorMessages.passwordRulesMismatch',
                  ),
              },
            }}
            inputRightElement={
              <Button
                onClick={() => setShowPassword((state) => !state)}
                variant='text'
              >
                <Icon
                  icon={showPassword ? 'visibility_on' : 'visibility_off'}
                />
              </Button>
            }
            description={t(
              'dashboard.authentication.errorMessages.passwordRules',
            )}
          />
          {error && (
            <Text color='form.errorText' fontWeight='bold'>
              {error}
            </Text>
          )}
          <HStack alignItems='flex-start' gap='3'>
            <RegisterCheckbox
              accessor='acceptedTerms'
              registerOptions={{ validate: (val) => val === true }}
              customLabelContent={
                <Text mt='-1' fontSize='xs'>
                  <Trans
                    i18nKey='dashboard.authentication.createAccountLabel'
                    components={{
                      TermsLink: (
                        <StyledLink
                          color='primaryButton.background'
                          textDecoration='underline'
                          link={{
                            to: '/imprint',
                          }}
                        >
                          Link
                        </StyledLink>
                      ),
                    }}
                  />
                </Text>
              }
            />
          </HStack>
          <Text fontSize='xs'>
            <Trans
              i18nKey='dashboard.authentication.createAccountLabelPrivacy'
              components={{
                PrivacyLink: (
                  <StyledLink
                    color='primaryButton.background'
                    textDecoration='underline'
                    link={{
                      to: '/privacy',
                    }}
                  >
                    Link
                  </StyledLink>
                ),
              }}
            />
          </Text>
        </Stack>

        <Stack gap='6' w='full'>
          <TrackedButton
            fontSize='sm'
            isDisabled={!formMethods.formState.isValid}
            type='submit'
            contentName='CloudAccount'
            contentPiece='Registration'
            contentTarget='NewAccount'
          >
            {t('dashboard.authentication.createAccountButton')}
          </TrackedButton>

          <Divider borderColor='border' />

          <HStack justifyContent='center' gap='1' fontSize='sm'>
            <Text>{t('dashboard.authentication.alreadyAccount')}</Text>
            <Link
              textColor={'primaryButton.background'}
              fontWeight='bold'
              onClick={() => setAuthenticationModalType('login')}
            >
              {t('dashboard.authentication.registerNow')}
            </Link>
          </HStack>
        </Stack>
      </VStack>
    </FormProvider>
  );
};
