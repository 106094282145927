import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Text,
  FormLabel,
  Input,
  Button,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { useProjectModal } from '$/pages/DashboardPages/pages/Projects/hooks/useProjectModal';
import { deleteProject, renameProject } from '$/services/usecases/projects';
import { useProjectStore } from '$/stores/useProjectStore';
import { removeSpecialCharacters } from '$/utils/stringUtils';

type ModalInfo = {
  header: string;
  body?: ReactNode;
  footer?: ReactNode;
};

export const ProjectModal = () => {
  const { t } = useTranslation();
  const isOpen = useProjectModal.useIsOpen();
  const onClose = useProjectModal.useOnClose();
  const project = useProjectModal.useProject();
  const updateProject = useProjectStore.useUpdateProject();
  const [nameInputValue, setNameInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (project) {
      setNameInputValue(project.name);
    }
  }, [project]);

  const onRenameProject = async () => {
    if (!project) return;
    setIsLoading(true);
    const response = await renameProject(project.id, nameInputValue);
    if (response.isSuccessful) {
      updateProject({ ...project, name: nameInputValue });
      onClose();
    }
    setIsLoading(false);
  };

  const onDeleteProject = async () => {
    if (!project) return;
    setIsLoading(true);
    const response = await deleteProject(project.id);
    if (response.isSuccessful) {
      useProjectStore.setState((state) => ({
        projects: state.projects.filter((p) => p.id !== project.id),
      }));
      onClose();
    }
    setIsLoading(false);
  };

  const { header, body, footer }: ModalInfo = (() => {
    switch (useProjectModal.useModalMode()) {
      case 'renameProject':
        return {
          header: t('dashboard.projects.renameProjectModal.header'),
          body: (
            <>
              <FormLabel fontSize='sm'>
                {t('dashboard.projects.renameProjectModal.projectName')}
              </FormLabel>
              <Input
                onChange={(event) =>
                  setNameInputValue(removeSpecialCharacters(event.target.value))
                }
                onKeyDown={async (event) => {
                  if (event.key === 'Enter' && nameInputValue.length > 0) {
                    await onRenameProject();
                  }
                }}
                value={nameInputValue}
              />
            </>
          ),
          footer: (
            <HStack justify='space-between' w='full'>
              <Button
                fontSize='sm'
                onClick={() => {
                  setNameInputValue('');
                  onClose();
                }}
                variant='text'
              >
                {t('dashboard.projects.renameProjectModal.cancelButton')}
              </Button>
              <Button
                fontSize='sm'
                isDisabled={!nameInputValue}
                isLoading={isLoading}
                onClick={onRenameProject}
              >
                {t('dashboard.projects.renameProjectModal.saveButton')}
              </Button>
            </HStack>
          ),
        };
      case 'deleteProject':
        return {
          header: t('dashboard.projects.deleteProjectModal.header'),
          body: (
            <Text>
              {
                <Trans
                  i18nKey='dashboard.projects.deleteProjectModal.deleteInformation'
                  values={{
                    projectName: project?.name,
                  }}
                />
              }
            </Text>
          ),
          footer: (
            <HStack justify='space-between' w='full'>
              <Button
                fontSize='sm'
                onClick={() => {
                  setNameInputValue('');
                  onClose();
                }}
                variant='text'
              >
                {t('dashboard.projects.deleteProjectModal.cancelButton')}
              </Button>
              <Button
                fontSize='sm'
                isLoading={isLoading}
                onClick={onDeleteProject}
                variant='danger'
              >
                {t('dashboard.projects.deleteProjectModal.deleteButton')}
              </Button>
            </HStack>
          ),
        };
      default:
        return {
          header: '',
          body: <></>,
          footer: <></>,
        };
    }
  })();

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        setNameInputValue('');
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent
        gap='4'
        w='full'
        maxW='498px'
        p='10'
        bg='background'
        borderRadius='12px'
      >
        <ModalHeader p='0' fontSize='h7'>
          <Text>{header}</Text>
          <ModalCloseButton mt='6' mr='6' />
        </ModalHeader>
        <ModalBody mb='6' p='0' fontSize='sm'>
          {body}
        </ModalBody>
        <ModalFooter p='0'>
          <HStack justifyContent='space-between' w='full'>
            {footer}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
