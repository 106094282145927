import { array, number, object, string, z } from 'zod';

export const FandeckInfoSchema = object({
  status: number(),
  collection: string(),
  page: number(),
  sort: string().optional(),
  page_list: array(string()),
}).transform((data) => {
  if (data.status === 0) return [];

  return data.page_list;
});

export const FandeckPageCountSchema = z
  .object({
    status: z.number(),
    collection: z.string(),
    page: z.number(),
    sort: z.string().optional(),
    page_list: z.array(z.string()),
    max_pages: z.coerce.number().int().nonnegative(),
  })
  .transform((data) => {
    if (data.status === 0) return 0;

    return data.max_pages;
  });
