import { HStack, Stack, Text } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { HookFormErrorList } from '$/components/core/Form/HookFormErrorList';
import { HookFormPasswordInput } from '$/components/core/Form/HookFormPasswordInput';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { changePassword } from '$/services/usecases/profile';
import { useProfileStore } from '$/stores/useProfileStore';
import { validatePassword } from '$/utils/stringUtils';

type ChangePasswordData = {
  password: string;
  newPassword: string;
  repeatedNewPassword: string;
};

export const ProfileModalChangePassword: FC = () => {
  const { t } = useTranslation();

  const toast = useToast();
  const formMethods = useForm<ChangePasswordData>({ mode: 'onTouched' });

  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { cloudMessageTranslation } = useCloudMessageTranslation();
  const setProfileModalType = useProfileStore.useSetModalType();

  const onValidatePassword = (value: string) => {
    const errors = validatePassword(value, t);
    setPasswordErrors(errors);
    return errors.length === 0;
  };

  const onChangePassword = async (data: ChangePasswordData) => {
    try {
      setLoading(true);
      const response = await changePassword(data.password, data.newPassword);
      if (response.isSuccessful) {
        toast(
          t('dashboard.authentication.changePassword.successTitle'),
          'success',
          t('dashboard.authentication.changePassword.successDescription'),
        );

        setProfileModalType('change_password_success');
      } else {
        toast(
          t('dashboard.authentication.changePassword.errorTitle'),
          'error',
          cloudMessageTranslation(response.response.message),
        );
      }
    } catch (_) {
      toast(
        t('dashboard.authentication.changePassword.errorTitle'),
        'error',
        cloudMessageTranslation('genericError'),
      );
    }

    setLoading(false);
  };

  return (
    <FormProvider {...formMethods}>
      <Stack
        as='form'
        gap='2rem'
        w='full'
        onSubmit={formMethods.handleSubmit(onChangePassword)}
      >
        <Text color='lighterText' fontSize='sm'>
          {t('dashboard.authentication.changePassword.currentInfo')}
        </Text>
        <HookFormPasswordInput<ChangePasswordData>
          accessor='password'
          label={t('dashboard.authentication.changePassword.currentPassword')}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
          }}
        />

        <Text color='lighterText' fontSize='sm'>
          {t('dashboard.authentication.changePassword.newInfo')}
        </Text>
        <HookFormPasswordInput<ChangePasswordData>
          accessor='newPassword'
          label={t('dashboard.authentication.changePassword.newPassword')}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
            validate: onValidatePassword,
          }}
          description={t(
            'dashboard.authentication.errorMessages.passwordRules',
          )}
        />
        <HookFormPasswordInput<ChangePasswordData>
          accessor='repeatedNewPassword'
          label={t('dashboard.authentication.changePassword.repeatPassword')}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
            validate: (value) =>
              value === formMethods.getValues('newPassword') ||
              t('dashboard.authentication.errorMessages.passwordMismatch'),
          }}
        />

        {passwordErrors.length !== 0 && (
          <HookFormErrorList
            errors={passwordErrors}
            description={t(
              'dashboard.authentication.changePassword.invalidRequirements',
            )}
          />
        )}

        <HStack justify='center' w='full'>
          <TrackedButton
            isDisabled={!formMethods.formState.isValid}
            isLoading={loading}
            type='submit'
            contentName='CloudAccount'
            contentPiece='EditPassword'
            contentTarget='Edit'
          >
            {t('dashboard.authentication.changePassword.action')}
          </TrackedButton>
        </HStack>
      </Stack>
    </FormProvider>
  );
};
