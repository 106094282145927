import {
  AbsoluteCenter,
  Checkbox,
  CheckboxProps,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends CheckboxProps {
  isLoading?: boolean;
}

export const LoadingCheckbox: FC<Props> = ({ isLoading, ...props }) => {
  return (
    <Flex pos='relative' align='center' gap='2'>
      <Checkbox
        borderColor='border'
        icon={isLoading ? <></> : undefined}
        {...props}
      />
      {isLoading && (
        <AbsoluteCenter>
          <Spinner w='10px' h='10px' color='secondaryBackground' />
        </AbsoluteCenter>
      )}
    </Flex>
  );
};
