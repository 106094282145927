import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  icon: {
    color: 'lighterText',
    height: '55%',
    strokeLinecap: 'round',
  },
  control: {
    borderWidth: '1px',
    borderColor: 'lighterText',
    borderRadius: '2px',
    _checked: {
      bg: 'transparent',
      borderColor: 'text',
      _hover: {
        bg: 'transparent',
        borderColor: 'lighterText',
      },
    },
    _disabled: {
      bg: 'border',
      borderColor: 'border',
      _checked: {
        bg: 'border',
        borderColor: 'border',
        color: 'neutralText',
      },
    },
    _focus: {
      boxShadow: 'none',
    },
  },
});

const editorVariant = definePartsStyle({
  container: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'fit-content',
  },
  icon: {
    color: 'white',
    height: '55%',
    strokeLinecap: 'round',
  },
  control: {
    borderWidth: '1px',
    borderColor: 'lighterText',
    borderRadius: '2px',
    marginLeft: '8px',
    _checked: {
      bg: 'primaryButton.background',
      borderColor: 'primaryButton.background',
      _hover: {
        bg: 'primaryButton.background',
        borderColor: 'primaryButton.background',
      },
    },
    _focus: {
      boxShadow: 'none',
    },
  },
  label: {
    marginLeft: 0,
    marginRight: 0,
  },
});

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  variants: {
    editor: editorVariant,
  },
});
