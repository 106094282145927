import {
  Menu,
  MenuButton,
  IconButton,
  Portal,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useProjectModal } from '$/pages/DashboardPages/pages/Projects/hooks/useProjectModal';
import { ProjectOverviewItem } from '$/services/usecases/projects/mapper/editorProject';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  project: ProjectOverviewItem;
}

export const ProjectCardContextMenu: FC<Props> = ({
  isOpen,
  onClose,
  onOpen,
  project,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onOpenModal = useProjectModal.useOnOpen();

  return (
    <Menu isOpen={isOpen} onClose={onClose} placement='bottom-end'>
      <MenuButton
        as={IconButton}
        layerStyle='floatCardButton'
        icon={<Icon boxSize='15px' color='text' icon='three_dots' />}
        onClick={(event) => {
          onOpen();
          event.preventDefault();
          event.stopPropagation();
        }}
        variant='text'
      />
      <Portal>
        <MenuList fontSize='sm'>
          <MenuItem
            onClick={async (event) => {
              event.stopPropagation();
              await navigate({
                to:
                  project.type === 'editor'
                    ? `/editor?projectId=${project.id}`
                    : `/inspirationProjects/${project.id}`,
              });
            }}
          >
            {t('dashboard.projects.open')}
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              onOpenModal('renameProject', project);
            }}
          >
            {t('dashboard.projects.rename')}
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              onOpenModal('deleteProject', project);
            }}
          >
            {t('dashboard.projects.delete')}
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
