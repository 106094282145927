import { Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorInput } from '$/pages/EditorPage/components/AttributesSection/components/ActiveColorOptions/components/ColorInput';
import { ColorPicker } from '$/pages/EditorPage/components/AttributesSection/components/ActiveColorOptions/components/ColorPicker';
import { RetouchColorModes } from '$/pages/EditorPage/components/AttributesSection/components/ActiveColorOptions/components/RetouchColorModes';
import { StructurePicker } from '$/pages/EditorPage/components/AttributesSection/components/ActiveColorOptions/components/StructurePicker';
import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const ActiveColorOptions = () => {
  const { t } = useTranslation();

  const workMode = useEditorStore.useWorkMode();
  const [color, setColor] = useState({ r: 180, g: 5, b: 250, a: 1 });

  const { setRetouchPaintColor } = useEditorActions();

  useEffect(() => {
    if (workMode !== 'retouchPaint') return;

    let timeoutId: number | null = null;
    if (timeoutId != null) clearTimeout(timeoutId);

    timeoutId = window.setTimeout(
      () => setRetouchPaintColor(color.r, color.g, color.b),
      100,
    );

    return () => {
      if (timeoutId != null) clearTimeout(timeoutId);
    };
  }, [color, workMode, setRetouchPaintColor]);

  return (
    <Stack gap='3' w='full' px='4'>
      <SectionTitle icon='color_palette' title={t('editor.activeColor')} />
      <Stack gap='2'>
        <RetouchColorModes />

        {workMode === 'retouchPaint' && (
          <Stack gap='5.75'>
            <ColorPicker color={color} setColor={setColor} />
            <ColorInput color={color} setColor={setColor} />
          </Stack>
        )}

        {workMode === 'retouchPattern' && <StructurePicker />}
      </Stack>
    </Stack>
  );
};
