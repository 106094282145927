import { Stack, Grid, StackProps, Box } from '@chakra-ui/react';
import { FC, RefObject } from 'react';

import { For } from '$/components/common/Flow/For';
import { Show } from '$/components/common/Flow/Show';
import { LoadingMaterialCard } from '$/components/core/Collection/LoadingMaterialCard';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { materialTemplateColumn } from '$/theme/utils/templateColumn';
import { repeat } from '$/utils/arrayUtils';

interface Props extends StackProps {
  observer: RefObject<HTMLDivElement>;
  setActiveMaterial: (material: Material) => void;
  materials: Material[];
  type: MaterialType;
  isLoading: boolean;
  hideFavoriteButton?: boolean;
  isFullscreenMode?: boolean;
}

export const GridPresentation: FC<Props> = ({
  observer,
  setActiveMaterial,
  materials,
  type,
  isLoading,
  isFullscreenMode = false,
  hideFavoriteButton = false,
  ...props
}) => {
  return (
    <Stack gap='3' {...props}>
      <Grid
        gap={{ base: '3', md: '6' }}
        templateColumns={materialTemplateColumn(isFullscreenMode)}
      >
        <For each={materials}>
          {(material, i) => (
            <Box key={material.id}>
              <MaterialCard
                material={material}
                onClick={() => setActiveMaterial(material)}
                materialType={type}
                editMode={hideFavoriteButton ? 'none' : 'favorite'}
              />
              {i === materials.length - 8 && <Box ref={observer} />}
            </Box>
          )}
        </For>
        <Show when={isLoading}>
          {repeat(50).map((i) => (
            <LoadingMaterialCard key={i} />
          ))}
        </Show>
      </Grid>
    </Stack>
  );
};
