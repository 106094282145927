import { HStack, Spacer, Switch, Text } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';

interface Props {
  initialValue: boolean;
  componentIndex: number;
}

export const TestPatternToggle: FC<Props> = ({
  initialValue,
  componentIndex,
}) => {
  const { t } = useTranslation();
  const { toggleTestPattern } = useEditorActions();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(initialValue);
  }, [initialValue]);

  const onToggle = () => {
    toggleTestPattern(componentIndex, !isVisible);
    setIsVisible((current) => !current);
  };

  return (
    <HStack as='button' w='full' px='4' py='1'>
      <Icon icon='testpattern' boxSize='4' />
      <Text
        color='lighterText'
        fontSize='sm'
        fontWeight='bold'
        onClick={onToggle}
      >
        {t('editor.testPattern')}
      </Text>
      <Spacer />
      <Switch isChecked={isVisible} onChange={onToggle} />
    </HStack>
  );
};
