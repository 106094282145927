import {
  Box,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useIsMobile } from '$/hooks/useIsMobile';
import { RenderedInspirationImage } from '$/pages/InspirationEditorPage/components/RenderedInspirationImage';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { repeat } from '$/utils/arrayUtils';

interface ComparisonModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ComparisonModal: FC<ComparisonModalProps> = ({
  isOpen,
  onClose,
}) => {
  const isMobile = useIsMobile();

  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();
  const scene = useInspirationEditorStore.useScene();
  const sceneVariantLabelOrder =
    useInspirationEditorStore.useSceneVariantLabelOrder();
  const { t } = useTranslation();

  const [fullscreenVariantIndex, setFullscreenVariantIndex] = useState<
    number | null
  >(null);

  const anyFullscreen = fullscreenVariantIndex !== null;

  const cols = repeat(sceneLayersVariants.length)
    .map((_, i) => {
      if (!anyFullscreen) return '1fr';
      return i === fullscreenVariantIndex ? '1fr' : '0fr';
    })
    .join(' ');

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        setFullscreenVariantIndex(null);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent
        w={{ base: 'full', mobile: 'auto' }}
        minW={{ base: 'unset', mobile: '75%' }}
        mx='4'
        p={{ base: '4', mobile: '10' }}
        onClick={() => setFullscreenVariantIndex(null)}
      >
        <HStack
          justify='space-between'
          overflow='hidden'
          h={anyFullscreen ? '0' : '55px'}
          pb={anyFullscreen ? '0' : '4'}
          transition='height 0.3s ease'
        >
          <Text
            p='0'
            fontSize={{ base: 'h10', mobile: 'h7' }}
            fontWeight='bold'
          >
            {t('editor.compare')}
          </Text>

          {!anyFullscreen && (
            <ModalCloseButton pos='static' onClick={onClose} />
          )}
        </HStack>

        <ModalBody p='0' onClick={(e) => e.stopPropagation()}>
          <Grid
            gap={anyFullscreen ? '0' : '6'}
            templateRows={{ base: cols, sm: 'none' }}
            templateColumns={{ base: 'none', sm: cols }}
            overflow='hidden'
            transition='500ms'
          >
            {sceneLayersVariants.map((variant, index) => {
              const isFullscreen = index === fullscreenVariantIndex;

              return (
                <GridItem
                  key={index}
                  pos='relative'
                  alignItems={{ base: 'flex-start', sm: 'center' }}
                  justifyContent='center'
                  flexDir='column'
                  display='flex'
                  overflow='hidden'
                >
                  <Box w='full'>
                    {fullscreenVariantIndex === null && (
                      <Text
                        fontSize={{ base: 'xs', mobile: 'sm' }}
                        borderRadius='8px'
                      >
                        {t('editor.variant', {
                          letter: sceneVariantLabelOrder[index],
                        })}
                      </Text>
                    )}
                  </Box>

                  <Box pos='relative'>
                    <RenderedInspirationImage
                      w='full'
                      layers={variant}
                      sceneId={scene?.id ?? '0'}
                      maxHeight={!isFullscreen ? undefined : '85vh'}
                      borderRadius='8px'
                      resolution={{ width: 2000, height: 2000 }}
                    />
                    {!isMobile && (
                      <IconButton
                        pos='absolute'
                        zIndex={100}
                        top='8px'
                        right='8px'
                        w={anyFullscreen ? '32px' : '24px'}
                        minW='0'
                        h={anyFullscreen ? '32px' : '24px'}
                        opacity={anyFullscreen && !isFullscreen ? '0' : '1'}
                        transition={
                          anyFullscreen ? '' : 'opacity 0.3s ease 0.5s'
                        }
                        aria-label=''
                        aspectRatio='1/1'
                        icon={
                          <Icon
                            icon={isFullscreen ? 'zoom_in_map' : 'zoom_out_map'}
                            width={anyFullscreen ? '18px' : '12px'}
                            height={anyFullscreen ? '18px' : '12px'}
                          />
                        }
                        onClick={() =>
                          setFullscreenVariantIndex(
                            fullscreenVariantIndex === index ? null : index,
                          )
                        }
                        variant='secondary'
                      />
                    )}
                  </Box>
                </GridItem>
              );
            })}
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
