import {
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  Grid,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ModalHeaderWithBackButton } from '$/components/common/Modals/ModalHeaderWithBackButton';
import { VersionCard } from '$/components/core/Authentication/components/VersionCard';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';

export const VersionComparisonModalContent = () => {
  const { t } = useTranslation();
  const onCloseAuthenticationModal = useAuthenticationStore.useOnModalClose();
  const setAuthenticationModalType = useAuthenticationStore.useSetModalType();

  return (
    <ModalContent maxW='875px' bg='background' borderRadius='12px'>
      <ModalHeaderWithBackButton
        headerText={t('dashboard.authentication.version.label')}
      />
      <ModalCloseButton top='0' right='0' mt='8' mr='8' />
      <ModalBody mt='8' px='10' pb='10'>
        <Stack gap='6'>
          <Text fontSize='sm'>
            {t('dashboard.authentication.version.description')}
          </Text>
          <Grid gap='6' templateColumns='1fr 1fr'>
            <VersionCard
              variant='default'
              type='free'
              onActionClick={onCloseAuthenticationModal}
            />
            <VersionCard
              variant='primary'
              type='pro'
              onActionClick={() => setAuthenticationModalType('register')}
            />
          </Grid>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
};
