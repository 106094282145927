import { createRoute } from '@tanstack/react-router';

import { ProjectPage } from '$/pages/DashboardPages/pages/Projects';
import { useProjectModal } from '$/pages/DashboardPages/pages/Projects/hooks/useProjectModal';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';
import { useProjectStore } from '$/stores/useProjectStore';

export const ProjectsRoute = createRoute({
  getParentRoute: () => BaseRouteLayout,
  path: '/projects',
  component: ProjectPage,
  onLeave: () => {
    useProjectModal.setState({ isOpen: false });
  },
  onEnter: async () => {
    await useProjectStore.getState().refetchProjects();
  },
});
