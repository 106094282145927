import { HStack, Stack, Box, ModalCloseButton } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ThemeSwitcher } from '$/components/common/ThemeSwitcher';
import { MaterialListItem } from '$/components/core/Collection/MaterialListItem';
import { FilterTags } from '$/components/core/Collection/MaterialPresentation/DataSortingRow/components/FilterTags';
import { AttributeHeader } from '$/components/core/Editor/AttributeHeader';
import { Material } from '$/services/usecases/materials';

interface Props {
  material?: Material | null;
}

export const FullscreenOverviewHeader: FC<Props> = ({ material }) => {
  const { t } = useTranslation();

  return (
    <Stack as={Stack} gap='6' mb='6' px='8' pt='8'>
      <HStack justify='space-between'>
        <Box as='span' fontSize='h7' fontWeight='bold'>
          {t('general_editor.search_collections')}
        </Box>
        <ModalCloseButton pos='static' />
      </HStack>

      <HStack justify='space-between'>
        <Stack gap='3'>
          {material && (
            <>
              <AttributeHeader
                icon={<Icon w='4' h='4' icon='inspiration_marker' />}
                label={t('inspirationEditor.attributes.usedColor')}
              />
              <MaterialListItem material={material} />
            </>
          )}
        </Stack>
        <Box h='8'>
          <ThemeSwitcher />
        </Box>
      </HStack>

      <FilterTags />
    </Stack>
  );
};
