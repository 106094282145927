import {
  Button,
  DrawerBody,
  DrawerHeader,
  HStack,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileDrawer } from '$/components/common/Drawer/MobileDrawer';
import { FloatingCard } from '$/components/common/FloatingCard';
import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { ShareModal } from '$/components/core/Editor/AttributeSidebar/components/Share/ShareModal';
import { MobileVariantSection } from '$/components/core/Editor/Mobile/components/MobileVariantSection';
import { useSwipeHook } from '$/hooks/useSwipeHook';
import { EditorMobileFilterDrawer } from '$/pages/EditorPage/components/EditorMobileFilterDrawer';
import { ExportEditorModal } from '$/pages/EditorPage/components/ExportModal';
import { useSaveEditorProject } from '$/pages/EditorPage/hooks/useSaveEditorProject';
import { ExportInspirationModal } from '$/pages/InspirationEditorPage/components/ExportModal';
import { useSaveInspirationProject } from '$/pages/InspirationEditorPage/hooks/useSaveInspirationProject';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { EditorRoute } from '$/routes/Editor/EditorRoute';

interface Props {
  mode: 'editor' | 'inspiration';
}

const ExportAction: FC<Props> = ({ mode }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const userRole = useAuthenticationStore.useUserRole();
  const onOpenAuthModal = useAuthenticationStore.useOnModalOpen();

  return (
    <>
      <IconButton
        w='full'
        h='48px'
        aria-label=''
        icon={<Icon icon='upload_file' />}
        onClick={() =>
          userRole === 'free' ? onOpenAuthModal('versionComparison') : onOpen()
        }
        variant='ghost'
      />
      {mode === 'inspiration' && (
        <ExportInspirationModal isOpen={isOpen} onClose={onClose} />
      )}
      {mode === 'editor' && (
        <ExportEditorModal isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
};

const ShareAction: FC<Props> = ({ mode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasPermission } = useAuthorization();
  const onOpenAuthModal = useAuthenticationStore.useOnModalOpen();

  const inspirationProjectId =
    mode === 'inspiration'
      ? useInspirationEditorStore.useCurrentProjectId()
      : null;

  const { projectId: editorProjectId } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    mode === 'editor' ? EditorRoute.useSearch() : { projectId: null };

  const projectId = inspirationProjectId ?? editorProjectId;

  return (
    <>
      <IconButton
        w='full'
        h='48px'
        aria-label=''
        icon={<Icon icon='share' />}
        isDisabled={!projectId}
        onClick={() =>
          hasPermission('Project_Share')
            ? onOpen()
            : onOpenAuthModal('versionComparison')
        }
        variant='share'
      />
      {projectId && (
        <ShareModal
          isOpen={isOpen}
          onClose={onClose}
          type={mode}
          projectId={projectId}
        />
      )}
    </>
  );
};

const ComparisonAction: FC<Props> = ({ mode }) => {
  const { t } = useTranslation();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const swipeEvents = useSwipeHook({
    onSwipeVertical: (direction) => direction === 'down' && onClose(),
  });

  if (mode !== 'inspiration') return null;

  return (
    <>
      <IconButton
        w='full'
        h='48px'
        aria-label=''
        icon={<Icon icon='compare' />}
        onClick={onToggle}
        variant='share'
      />

      <MobileDrawer isOpen={isOpen} onClose={onClose} h='fit-content'>
        <DrawerHeader px='3' pt='5' pb='0' {...swipeEvents}>
          <HStack justify='space-between'>
            <Text pl='3'>{t('editor.variants')}</Text>
            <Button
              color='lighterText'
              fontSize='sm'
              onClick={onClose}
              variant='ghost'
            >
              {t('general.close')}
            </Button>
          </HStack>
        </DrawerHeader>
        <DrawerBody px='3' pb='14'>
          <MobileVariantSection />
        </DrawerBody>
      </MobileDrawer>
    </>
  );
};

const SaveAction: FC<Props> = ({ mode }) => {
  const [isSavingEditor, onSaveEditorProject] = useSaveEditorProject();
  const [isSavingInspiration, onSaveInspirationProject] =
    useSaveInspirationProject();

  const userRole = useAuthenticationStore.useUserRole();
  const onOpenAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  const onSaveClick = async () => {
    if (userRole === 'free') {
      onOpenAuthenticationModal('versionComparison');
    } else {
      await (mode === 'inspiration'
        ? onSaveInspirationProject()
        : onSaveEditorProject());
    }
  };

  return (
    <IconButton
      w='full'
      h='48px'
      aria-label=''
      icon={<Icon icon='save_project' w='18px' />}
      isLoading={isSavingEditor || isSavingInspiration}
      onClick={onSaveClick}
      variant='share'
    />
  );
};

const LayerAction = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <IconButton
        w='full'
        h='48px'
        aria-label=''
        icon={<Icon icon='stack' />}
        onClick={onOpen}
        variant='share'
      />
      <EditorMobileFilterDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export const BottomActionBar: FC<Props> = ({ mode }) => {
  return (
    <FloatingCard wrapper={{ bottom: '0', p: '4' }} color='lighterText'>
      {mode === 'editor' && <LayerAction />}
      <ComparisonAction mode={mode} />
      <SaveAction mode={mode} />
      <ExportAction mode={mode} />
      <ShareAction mode={mode} />
    </FloatingCard>
  );
};
