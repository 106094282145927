import { Divider, HStack, Link, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';
import { HookFormPasswordInput } from '$/components/core/Form/HookFormPasswordInput';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { LoginData, signInUser } from '$/services/usecases/authentication';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { useProjectStore } from '$/stores/useProjectStore';
import { emailFormatRegex } from '$/utils/regexUtils';

const LoginInput = (props: HookFormInputProps<LoginData>) => (
  <HookFormInput<LoginData> {...props} />
);

export const LoginForm = () => {
  const { t } = useTranslation();

  const formMethods = useForm<LoginData>({
    mode: 'onTouched',
    delayError: 100,
  });

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const showToast = useToast();

  const closeModal = useAuthenticationStore.useOnModalClose();
  const setAuthenticationModalType = useAuthenticationStore.useSetModalType();
  const authenticate = useAuthenticationStore.useAuthenticate();
  const setOptEmail = useAuthenticationStore.useSetOtpEmail();
  const { cloudMessageTranslation } = useCloudMessageTranslation();

  const onLogin = async (data: LoginData) => {
    setLoading(true);

    try {
      const { isSuccessful, response } = await signInUser(data);

      if (
        isSuccessful &&
        response.user != null &&
        response.accessToken != null
      ) {
        authenticate(response.user, response.accessToken);
        await useProjectStore.getState().refetchProjects();
        await useFavoriteStore.getState().getFavorites();
        closeModal();
        showToast(
          t('dashboard.authentication.signInSuccessfulTitle'),
          'success',
          t('dashboard.authentication.signInSuccessfulDescription'),
        );
      } else if (response.message === 'emailNotVerified') {
        setOptEmail(data.email);
        setAuthenticationModalType('registerOtp');
      } else {
        setError(cloudMessageTranslation(response.message));
      }
    } catch (error) {
      setError(cloudMessageTranslation('genericError'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Stack as='form' gap='10' onSubmit={formMethods.handleSubmit(onLogin)}>
        <Stack gap='6'>
          <LoginInput
            type='email'
            accessor='email'
            paddingTop='2px'
            externalError={error !== null}
            onChange={() => setError(null)}
            label={t('dashboard.authentication.emailLabel')}
            placeholder={t('dashboard.authentication.defaultPlaceholder', {
              field: t('dashboard.authentication.emailLabel'),
            })}
            registerOptions={{
              required: t('dashboard.authentication.errorMessages.isRequired'),
              pattern: {
                value: emailFormatRegex,
                message: t(
                  'dashboard.authentication.errorMessages.wrongFormat',
                ),
              },
            }}
          />
          <HookFormPasswordInput<LoginData>
            accessor='password'
            label={t('dashboard.authentication.passwordLabel')}
            registerOptions={{
              required: t('dashboard.authentication.errorMessages.isRequired'),
            }}
          />
          {error && (
            <Text color='form.errorText' fontWeight='bold'>
              {error}
            </Text>
          )}
        </Stack>

        <Stack gap='6' w='full'>
          <Stack gap='4'>
            <TrackedButton
              isDisabled={!formMethods.formState.isValid}
              fontSize='sm'
              isLoading={loading}
              type='submit'
              variant='primary'
              contentName='CloudAccount'
              contentPiece='Login'
              contentTarget='Login'
            >
              {t('dashboard.header.signIn')}
            </TrackedButton>
            <TrackedButton
              fontSize='sm'
              variant='text'
              onClick={() => setAuthenticationModalType('passwordForgotten')}
              contentName='CloudAccount'
              contentPiece='Login'
              contentTarget='ForgetPassword'
            >
              {t('dashboard.authentication.forgotPassword')}
            </TrackedButton>
          </Stack>

          <Divider borderColor='border' />

          <HStack justifyContent='center' gap='1' fontSize='sm'>
            <Text>{t('dashboard.authentication.noAccount')}</Text>
            <Link
              textColor={'primaryButton.background'}
              fontWeight='bold'
              onClick={() => setAuthenticationModalType('register')}
              tabIndex={0}
            >
              {t('dashboard.authentication.signUpNow')}
            </Link>
          </HStack>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
