import { Button, HStack } from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useState } from 'react';

import { AvailableIcons, Icon } from '$/components/common/Icon';
import { queryClient } from '$/services/fetcher';
import { FilterType, MaterialType } from '$/services/mapper/uses';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface CategoryButtonProps {
  children: ReactNode;
  isActive: boolean;
  onClick: () => void;
}

const CategoryButton: FC<CategoryButtonProps> = ({
  children,
  isActive,
  onClick,
}) => {
  return (
    <Button
      boxSize='8'
      minW='0'
      p='0'
      color='lighterText'
      opacity={isActive ? '1' : '0.3'}
      onClick={onClick}
      variant='ghost'
    >
      {children}
    </Button>
  );
};

interface CategorySelectionProps {
  materialTypes?: MaterialType[];
  initialMode: MaterialType;
}

export const CategorySelection: FC<CategorySelectionProps> = ({
  materialTypes,
  initialMode,
}) => {
  const [activeType, setActiveType] = useState<FilterType>(
    initialMode as FilterType,
  );

  useEffect(() => setActiveType(initialMode as FilterType), [initialMode]);

  const selectGroup = (type: FilterType) => {
    setActiveType(type);

    useCollectionStore.setState({
      filter: undefined,
      activeType: type,
      filterGroups: [],
    });

    const { filterGroups } = useCollectionStore.getState();
    void queryClient
      .ensureQueryData(filterOptionsQuery({ type, filter: filterGroups }))
      .then((filter) => useCollectionStore.setState({ filter }));
  };

  const categories: [MaterialType, string, AvailableIcons][] = [
    ['uni', '5', 'color_palette'],
    ['wall', '6', 'wallpaper'],
    ['floor', '5', 'floor_material'],
    ['facade', '5', 'facade_material'],
  ];

  return (
    <HStack gap='0'>
      {categories.map(([type, size, icon]) => {
        if (materialTypes && !materialTypes.includes(type)) return null;

        return (
          <CategoryButton
            key={type}
            onClick={() => selectGroup(type as FilterType)}
            isActive={activeType === type}
          >
            <Icon icon={icon} boxSize={size} />
          </CategoryButton>
        );
      })}
    </HStack>
  );
};
