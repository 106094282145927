export const unsecureSeededRandom = (seed: number) => {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
};

export const unsecureIntRange = (min: number, max: number) =>
  unsecureSeededIntRange(Date.now(), min, max);

export const unsecureSeededIntRange = (
  seed: number,
  min: number,
  max: number,
) => {
  return Math.round(unsecureSeededRandom(seed) * (max - min) + min);
};

export const unsecureHashCode = (str: string) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};

export const indexToLetter = (index: number) => {
  return String.fromCharCode(65 + index);
};
