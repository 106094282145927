import { Box } from '@chakra-ui/react';
import {
  ComponentType,
  FC,
  PropsWithChildren,
  ReactNode,
  RefObject,
} from 'react';

import { Show } from '$/components/common/Flow/Show';
import { scrollable } from '$/components/common/Scrollbar';
import { Material } from '$/services/usecases/materials';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  ItemWrapper: ComponentType<PropsWithChildren>;
  item: (material: Material, index: number) => ReactNode;
  LoadingElement: ComponentType<{ index: number }>;
  materials: Material[];
  observer: RefObject<HTMLDivElement>;
  isLoading: boolean;
}

export const ColorGridPanel: FC<Props> = ({
  ItemWrapper,
  item,
  LoadingElement,
  materials,
  observer,
  isLoading,
}) => {
  return (
    <Box px='0' {...scrollable({ x: 'hidden', y: 'scroll' })}>
      <ItemWrapper>
        {materials?.map((material, i) => (
          <Box key={material.id}>
            {item(material, i)}
            <Show when={i === materials.length - 8}>
              <Box ref={observer} />
            </Show>
          </Box>
        ))}
        <Show when={isLoading}>
          {repeat(42).map((i) => (
            <LoadingElement key={i} index={i} />
          ))}
        </Show>
      </ItemWrapper>
    </Box>
  );
};
