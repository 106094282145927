import { Box, HStack, Input } from '@chakra-ui/react';
import { ClipboardEvent, FC, useEffect, useRef, useState } from 'react';

import { RGBA } from '$/utils/colorUtils';

type MultiValue = [number, number, number];

interface Props {
  color: RGBA;
  setColor: (color: RGBA) => void;
  rgbToValConverter: (color: RGBA) => MultiValue;
  valToRgbConverter: (value: MultiValue) => RGBA;
  isValidColor: (value: MultiValue) => boolean;
  normalizeValue: (val: number, i: number) => number;
}

export const MultiColorInput: FC<Props> = ({
  color,
  setColor,
  rgbToValConverter,
  valToRgbConverter,
  isValidColor,
  normalizeValue,
}) => {
  const [input, setInput] = useState(rgbToValConverter(color));

  useEffect(
    () => setInput(rgbToValConverter(color)),
    [color, rgbToValConverter],
  );

  const firstInputRef = useRef<HTMLInputElement>(null);
  const secondInputRef = useRef<HTMLInputElement>(null);
  const thirdInputRef = useRef<HTMLInputElement>(null);

  const refs = [firstInputRef, secondInputRef, thirdInputRef];

  const persist = (val: number, pos: number) => {
    const newInput: MultiValue = [...input];
    newInput[pos] = normalizeValue(val, pos);
    setInput(newInput);

    if (pos === 0 && String(val).length === 3) {
      secondInputRef.current?.select();
    }

    if (pos === 1 && String(val).length === 3) {
      thirdInputRef.current?.select();
    }

    if (isValidColor(newInput)) {
      setColor(valToRgbConverter(newInput));
    }
  };

  const paste = (e: ClipboardEvent<HTMLInputElement>) => {
    const paste = e.clipboardData.getData('Text').trim();
    if (!paste.includes(' ') && !paste.includes(',')) return;

    e.preventDefault();
    const [v1, v2, v3] = paste.includes(' ')
      ? paste.split(' ').map(Number)
      : paste.split(',').map(Number);

    const newInput: MultiValue = [
      normalizeValue(v1, 0),
      normalizeValue(v2, 1),
      normalizeValue(v3, 2),
    ];

    if (isValidColor(newInput)) {
      setColor(valToRgbConverter(newInput));
    }
  };

  const copy = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    e.clipboardData.setData(
      'text/plain',
      `${input[0]} ${input[1]} ${input[2]}`,
    );
  };

  return (
    <HStack
      align='center'
      justify='space-between'
      w='full'
      h='32px'
      px='6px'
      bg='form.inputBackground'
      border='1px solid'
      borderColor={
        isValidColor(input) ? 'form.inputBorder' : 'form.errorBorder '
      }
      borderRadius='4px'
      divider={<Box h='20px' mx='6px !important' borderColor='border' />}
    >
      {refs.map((ref, i) => (
        <Input
          key={`${ref.current?.name} ${i}`}
          ref={ref}
          textAlign='center'
          onChange={(e) => persist(Number(e.currentTarget.value), i)}
          onCopy={copy}
          onPaste={(e) => paste(e)}
          type='number'
          value={input[i]}
          variant='unstyled'
        />
      ))}
    </HStack>
  );
};
