import { FC, useEffect, useState } from 'react';

import { MobileDrawer } from '$/components/common/Drawer/MobileDrawer';
import { ComponentDisplayContent } from '$/components/core/Editor/Mobile/components/ComponentDisplayContent';
import { EditorFilterDrawerContent } from '$/components/core/Editor/Mobile/components/Filter/EditorFilterDrawerContent';
import { useActiveEditorMaterial } from '$/pages/EditorPage/hooks/useActiveEditorMaterial';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const EditorMobileFilterDrawer: FC<Props> = ({ isOpen, onClose }) => {
  const [view, setView] = useState<'layer' | 'filter'>('layer');

  const actions = useEditorActions();
  const material = useActiveEditorMaterial();

  const setComponentActive = useEditorStore.useSetComponentActive();

  useEffect(() => {
    if (!isOpen && view) setView('layer');
  }, [isOpen, view]);

  return (
    <MobileDrawer isOpen={isOpen} onClose={onClose}>
      {view === 'filter' && (
        <EditorFilterDrawerContent
          initialType='uni'
          onClose={onClose}
          isOpen={isOpen}
          onSelectMaterial={({ id }) => {
            actions.setMaterial(id);
            onClose();
          }}
          material={material}
          mode='editor'
          onBack={() => setView('layer')}
        />
      )}
      {view === 'layer' && (
        <ComponentDisplayContent
          closeFilterDrawer={onClose}
          onItemSelect={(index) => {
            setComponentActive(actions, index);
            setView('filter');
          }}
        />
      )}
    </MobileDrawer>
  );
};
