import { createRoute, defer } from '@tanstack/react-router';

import {
  hasPermissionFactory,
  Permission,
} from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { WelcomeDashboard } from '$/pages/DashboardPages/pages/Welcome';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';
import { STANDARD_STALE_TIME } from '$/services/fetcher';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { scenesQuery } from '$/services/usecases/scenes/queries';
import { useProjectStore } from '$/stores/useProjectStore';
import { pickRandomElement } from '$/utils/arrayUtils';

const hasPermission = (permission: Permission) =>
  hasPermissionFactory(useAuthenticationStore.getState().userRole)(permission);

export const DashboardRoute = createRoute({
  getParentRoute: () => BaseRouteLayout,
  path: '/',
  component: WelcomeDashboard,
  onEnter: async () => {
    await useProjectStore.getState().refetchProjects();
  },
  loader: ({ context: { client } }) => {
    const isDemoUser = !hasPermission('View_All_Collections');

    const getMaterials = async () => {
      const { materials: colorMaterials } = await client.ensureQueryData(
        materialsQuery({ limit: 30, type: ['uni'], demo: isDemoUser }),
      );
      const { materials: wallMaterials } = await client.ensureQueryData(
        materialsQuery({ limit: 10, type: ['wall'], demo: isDemoUser }),
      );
      const { materials: floorMaterials } = await client.ensureQueryData(
        materialsQuery({ limit: 10, type: ['floor'], demo: isDemoUser }),
      );
      const { materials: facadeMaterials } = await client.ensureQueryData(
        materialsQuery({ limit: 10, type: ['facade'], demo: isDemoUser }),
      );

      return {
        colorMaterial: pickRandomElement(colorMaterials),
        wallMaterial: pickRandomElement(wallMaterials),
        floorMaterial: pickRandomElement(floorMaterials),
        facadeMaterial: pickRandomElement(facadeMaterials),
      };
    };

    return {
      scenes: defer(client.ensureQueryData(scenesQuery)),
      materials: defer(getMaterials()),
    };
  },
  staleTime: STANDARD_STALE_TIME,
});
