import {
  Button,
  SimpleGrid,
  Skeleton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { AddVariantButton } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/AddVariantButton';
import { ComparisonModal } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/ComparisonModal';
import { VariantCard } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/VariantCard';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { indexToLetter } from '$/utils/numberUtils';

export const MobileVariantSection = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onToggle } = useDisclosure();

  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();

  return (
    <Stack gap='3'>
      <SimpleGrid gap='2' w='full' columns={3}>
        {sceneLayersVariants.length === 0 && (
          <Skeleton w='full' h='80px' borderRadius='4px' />
        )}
        {sceneLayersVariants.map((_, index) => (
          <VariantCard key={index} variantButtonIndex={index} />
        ))}
        {sceneLayersVariants.length < 3 && (
          <AddVariantButton
            variantName={indexToLetter(sceneLayersVariants.length)}
          />
        )}
      </SimpleGrid>

      <Button
        h='8'
        mt='2'
        fontSize='xs'
        fontWeight='normal'
        isDisabled={sceneLayersVariants.length < 2}
        leftIcon={<Icon icon='zoom_out_map' width='12px' />}
        onClick={onToggle}
        variant='secondary'
      >
        {t('inspirationEditor.compareVariants')}
      </Button>

      <ComparisonModal isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
};
