import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

import { MaterialCardContextMenu } from '$/components/core/Collection/MaterialCard/MaterialCardContextMenu';
import { Material } from '$/services/usecases/materials';

interface Props extends BoxProps {
  material: Material;
  image?: string;
}

export const ColorBox: FC<Props> = ({ material, ...props }) => {
  return (
    <Box
      pos='relative'
      bg={material.color ?? 'transparent'}
      bgImage={material.url()}
      bgSize='cover'
      border='1px'
      borderColor='border'
      aspectRatio='3/2'
      {...props}
    >
      <MaterialCardContextMenu
        editMode={'favorite'}
        material={material}
        materialType={material.type[0]}
        menuLayer='inspirationModal'
      />
    </Box>
  );
};
