import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { queryClient } from '$/services/fetcher';
import { ProjectOverviewItem } from '$/services/usecases/projects/mapper/editorProject';
import { projectsQuery } from '$/services/usecases/projects/queries';

type ProjectStoreProps = {
  projects: ProjectOverviewItem[];
  updateProject: (project: ProjectOverviewItem) => void;
  refetchProjects: () => Promise<void>;
};

const store = create<ProjectStoreProps>()((set) => ({
  projects: [],
  updateProject: (project) => {
    set((state) => ({
      projects: state.projects.map((p) => (p.id === project.id ? project : p)),
    }));
  },
  refetchProjects: async () => {
    await queryClient.invalidateQueries({ queryKey: ['projects'] });
    const data = await queryClient.ensureQueryData(projectsQuery());

    if (data.isSuccessful) {
      set({ projects: data.response.payload ?? [] });
    }
  },
}));

export const useProjectStore = createSelectorHooks(store);
