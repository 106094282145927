import { HStack } from '@chakra-ui/react';
import { FC } from 'react';

import { ActionBar } from '$/components/core/Editor/ActionBar';
import { EditorZoomAction } from '$/components/core/Editor/ActionBar/components/EditorZoomAction';
import {
  EditorActionGroup,
  useActionBarStore,
} from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props {
  groups: EditorActionGroup[];
}

export const ActionBarSection: FC<Props> = ({ groups }) => {
  const perspectiveFullscreen =
    useInspirationEditorStore.usePerspectiveFullscreen();

  const zoomIn = useActionBarStore.useZoomIn();
  const zoomOut = useActionBarStore.useZoomOut();
  const resetZoom = useActionBarStore.useResetZoom();
  const setZoomLevel = useActionBarStore.useSetZoomLevel();
  const zoomLevel = useActionBarStore.useZoomLevel();

  return (
    <HStack
      as='section'
      layerStyle='FloatingActionBar'
      top={perspectiveFullscreen ? '-50%' : '0'}
      transition='top 0.5s ease'
    >
      <ActionBar groups={groups}>
        <EditorZoomAction
          onResetZoom={resetZoom}
          onZoomIn={zoomIn}
          onZoomOut={zoomOut}
          setZoomLevel={setZoomLevel}
          zoomLevel={zoomLevel}
        />
      </ActionBar>
    </HStack>
  );
};
