import { Text } from '@chakra-ui/react';
import { FC } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { FilterGroup } from '$/services/usecases/filter';

interface Props {
  isExpanded: boolean;
  activeFilter: FilterGroup[];
  compactMode?: boolean;
}

export const ActiveFilterText: FC<Props> = ({
  isExpanded,
  activeFilter,
  compactMode = false,
}) => {
  return (
    <Show when={!isExpanded && activeFilter.length > 0}>
      <Text
        maxW={compactMode ? '165px' : undefined}
        mb={compactMode ? '0' : '2'}
        ml={compactMode ? '4' : '5'}
        fontSize='sm'
      >
        {activeFilter.map((i) => i.displayName).join(', ')}
      </Text>
    </Show>
  );
};
