import { FC } from 'react';

import { FullscreenFavoriteView } from '$/components/core/Editor/MaterialFullscreen/pages/FullscreenFavoritesModal/components/FullscreenFavoriteView';
import { FullscreenFolderView } from '$/components/core/Editor/MaterialFullscreen/pages/FullscreenFavoritesModal/components/FullscreenFolderView';
import { useMaterialFullscreenStore } from '$/components/core/Editor/MaterialFullscreen/store/useMaterialFullscreenStore';
import { Material } from '$/services/usecases/materials';

interface Props {
  onSelect: (material: Material) => void;
}

export const FullscreenFavoritesModal: FC<Props> = ({ onSelect }) => {
  const modalPayload = useMaterialFullscreenStore.useModalPayload();

  if (modalPayload.folder?.id) {
    return (
      <FullscreenFolderView
        folderId={modalPayload.folder.id}
        onSelect={onSelect}
      />
    );
  }

  return <FullscreenFavoriteView onSelect={onSelect} />;
};
