import { Box } from '@chakra-ui/react';
import { FC } from 'react';

import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';

interface Props {
  color?: string;
  materialId?: string;
}

export const VisualizationBox: FC<Props> = ({ color, materialId }) => {
  const { setMaterial } = useEditorActions();

  if (!color || !materialId) {
    return <Box layerStyle='VisualizationBox' />;
  }

  const onSetMaterial = () => {
    setMaterial(materialId);
  };

  return (
    <Box
      as='button'
      layerStyle='VisualizationBox'
      bg={color}
      onClick={onSetMaterial}
    />
  );
};
