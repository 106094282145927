import { endpointFactory } from '$/services/endpoints';
import { veeuzeFetcher } from '$/services/fetcher';
import { filterGroupsToQuery } from '$/services/mapper/filter';
import { MaterialType } from '$/services/mapper/uses';
import { validateToken } from '$/services/usecases/authentication';
import { FilterGroup } from '$/services/usecases/filter';
import {
  MaterialsSchema,
  Materials,
  Material,
} from '$/services/usecases/materials/mapper/materials';
import { useGeneralStore } from '$/stores/useGeneralStore';

interface GetMaterialsPayload {
  ids?: string[];
  query?: string;
  limit?: number;
  filter?: FilterGroup[];
  signal?: AbortSignal;
  page?: number;
  type?: MaterialType[];
  demo?: boolean;
}

export type { Materials, Material };

export const getMaterials = async (
  payload: GetMaterialsPayload,
  useUniqueKeys: boolean = false,
): Promise<Materials> => {
  const {
    ids,
    query,
    signal,
    filter,
    limit = 50,
    page = 0,
    type,
    demo = false,
  } = payload;

  const [filterKeys, filterValues] = filterGroupsToQuery(filter);

  const idKey = useUniqueKeys ? 'unique_key' : 'id';

  const keys = [
    ids ? idKey : undefined,
    query ? 'info' : undefined,
    ids !== null ? filterKeys : undefined,
  ]
    .filter((i) => i)
    .join(';');

  const values = [
    ids?.join('|'),
    query,
    ids !== null ? filterValues : undefined,
  ]
    .filter((i) => i)
    .join(';');

  const hasCollectionFilter = filter?.some(({ type }) => type === 'collection');

  const url = endpointFactory.inspirationMaterial({
    searchValues: values,
    searchKeys: keys,
    sortOrder: hasCollectionFilter ? 'SEARCH_CRITERIA_INT_7' : 'id',
    limit,
    page,
    type,
    language: useGeneralStore.getState().selectedLanguage,
    demo,
  });

  await validateToken();
  return veeuzeFetcher(url, {
    signal,
    msg: `could not fetch collections ${url}`,
    includeCredentials: true,
    parser: (data) => MaterialsSchema.parse(data),
  });
};
