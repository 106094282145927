import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { ParseKeys } from 'i18next';
import { create } from 'zustand';

import { Permission } from '$/components/core/Authentication/hooks/useAuthorization';
import { useToast } from '$/hooks/useToast';
import { EditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { UserRole } from '$/services/usecases/authentication/mapper/jwt';
import {
  EditorModuleStatus,
  WorkMode,
} from '$/services/usecases/editor/mapper/editorStatus';

const fixedZoomValues = [0.03125, 0.0625, 0.125, 0.25, 0.5, 1, 2, 4, 8];

export const getNextZoomValue = (
  currentValue: number,
  isIncreasing: boolean,
) => {
  const totalMax = Math.max(...fixedZoomValues);
  const totalMin = Math.min(...fixedZoomValues);
  if (totalMin >= currentValue && !isIncreasing) return totalMin;
  if (totalMax <= currentValue && isIncreasing) return totalMax;

  const stepList = [...fixedZoomValues];
  if (!stepList.includes(currentValue)) stepList.push(currentValue);
  stepList.sort((a, b) => a - b);
  const currentIndex = stepList.findIndex((val) => val === currentValue);
  return isIncreasing ? stepList[currentIndex + 1] : stepList[currentIndex - 1];
};

export type EditorAction = {
  key: string;
  mode?: WorkMode | WorkMode[];
  icon: JSX.Element;
  select: boolean;
  label?: string;
  disabledRoles?: UserRole[];
  isPolyOnly?: boolean;
  tooltipKey?: ParseKeys;
  isDisabled?: (
    editorStatus: EditorModuleStatus,
    hasPermission: (requestedPermission: Permission) => boolean,
  ) => boolean;
  onClick?: (
    actions: EditorActions,
    additional: {
      toast: ReturnType<typeof useToast>;
    },
  ) => void;
};

export type EditorActionGroup = {
  id: string;
  groupable: boolean;
  needComponent: boolean;
  actions: EditorAction[];
};

type ZoomControls = {
  zoomIn: VoidFunction;
  zoomOut: VoidFunction;
  resetZoom: VoidFunction;
  setTransform: (
    newPositionX: number,
    newPositionY: number,
    newScale: number,
  ) => void;
};

type State = {
  activeAction: EditorAction | null;
  zoomLevel: number;
  zoomControls: ZoomControls | null;
  positionX: number;
  positionY: number;
  viewCenterX: number;
  viewCenterY: number;
};

type Actions = {
  selectAction: (action: EditorAction | null) => void;
  zoomIn: () => void;
  zoomOut: () => void;
  resetZoom: () => void;
  setZoomControls: (controls: ZoomControls) => void;
  setZoomLevel: (zoomInput: number) => void;
  syncLibraryState: (
    x: number,
    y: number,
    scale: number,
    wrapperComponent: HTMLDivElement | null,
  ) => void;
};

const initial: State = {
  activeAction: null,
  zoomLevel: 1,
  zoomControls: null,
  positionX: 0,
  positionY: 0,
  viewCenterX: 0,
  viewCenterY: 0,
};

const store = create<State & Actions>()((set, get) => ({
  ...initial,
  selectAction: (action) => {
    const shouldPersist = action?.select && get().activeAction !== action;
    set({ activeAction: shouldPersist ? action : null });
  },
  setZoomControls: (zoomControls) => set({ zoomControls }),
  zoomIn: () => {
    get().setZoomLevel(getNextZoomValue(get().zoomLevel, true));
  },
  zoomOut: () => {
    get().setZoomLevel(getNextZoomValue(get().zoomLevel, false));
  },
  resetZoom: () => {
    get().zoomControls?.resetZoom();
  },
  syncLibraryState: (x, y, scale, wrapperComponent) => {
    // this logic has been extracted from the pinch library to allow us to zoom to a specific scale on the currently viewed section
    const wrapperWidth = wrapperComponent?.offsetWidth ?? 0;
    const wrapperHeight = wrapperComponent?.offsetHeight ?? 0;
    const viewCenterX = (wrapperWidth / 2 - x) / scale;
    const viewCenterY = (wrapperHeight / 2 - y) / scale;

    set({
      positionX: x,
      positionY: y,
      zoomLevel: scale,
      viewCenterX,
      viewCenterY,
    });
  },

  setZoomLevel: (zoomInput: number) => {
    // this logic has been extracted from the pinch library to allow us to zoom to a specific scale on the currently viewed section
    const scaleDifference = zoomInput - get().zoomLevel;

    const calcX = get().positionX - get().viewCenterX * scaleDifference;
    const calcY = get().positionY - get().viewCenterY * scaleDifference;

    get().zoomControls?.setTransform(calcX, calcY, zoomInput);
  },
}));

export const useActionBarStore = createSelectorHooks(store);
