import { HStack, Button, Box, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useNonFreeAction } from '$/hooks/useNonFreeAction';

interface Props {
  onSave: () => void;
  isSaving: boolean;
}

export const SaveSection: FC<Props> = ({ onSave, isSaving }) => {
  const { t } = useTranslation();
  const handleNonFreeAction = useNonFreeAction();

  return (
    <Box w='full' px='4' pb='2'>
      <HStack my='3'>
        <Icon icon='save_project' />
        <Text color='text' fontSize='sm' fontWeight='bold'>
          {t('editor.save')}
        </Text>
      </HStack>
      <Button
        w='full'
        h='8'
        fontSize='xs'
        fontWeight='normal'
        isLoading={isSaving}
        loadingText={t('editor.save')}
        onClick={() => handleNonFreeAction(() => onSave())}
      >
        {t('editor.saveProject')}
      </Button>
    </Box>
  );
};
