export const inspirationLayerStyles = {
  FilterButton: {
    height: 'fit-content',
    gap: '4',
    p: '1',
  },
  FilterImage: {
    aspectRatio: '1/1',
    borderRadius: '6px',
    border: '1px solid',
    borderColor: 'border',
  },
  SceneImage: {
    border: '1px solid',
    borderColor: 'border',
    borderRadius: '12px',
    objectFit: 'cover',
  },
  ExportRadioWrapper: {
    border: '1px solid',
    borderColor: 'border',
    borderRadius: '4px',
    fontWeight: 'bold',
  },
  ColorImage: {
    w: '20px',
    h: '20px',
    borderRadius: 'full',
  },
  ColorListTabIcon: {
    _selected: {
      color: 'text',
    },
    color: 'border',
    padding: '0.5',
  },
};
