import { FC } from 'react';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { NewProjectCard } from '$/components/core/Projects/components/Cards/NewProjectCard';
import { UpgradeAccountCard } from '$/components/core/Projects/components/Cards/UpgradeAccountCard';
import { ProjectCard } from '$/components/core/Projects/ProjectCard';
import { ProjectOverviewItem } from '$/services/usecases/projects/mapper/editorProject';

interface Props {
  items: ProjectOverviewItem[];
}

export const ProjectGridItems: FC<Props> = ({ items }) => {
  const { canCreateMoreProjects } = useAuthorization();

  const projects = items.map((project) => (
    <ProjectCard key={project.id} project={project} />
  ));

  if (items.length === 0) {
    return <NewProjectCard />;
  }

  if (!canCreateMoreProjects()) {
    return [...projects, <UpgradeAccountCard key='upgrade-card' />];
  }

  return projects;
};
