import { Accordion, Box } from '@chakra-ui/react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import { useEffect } from 'react';

import { ComponentListItem } from '$/pages/EditorPage/components/ComponentSection/ComponentListItem';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const ComponentList = () => {
  const components = useEditorStore.useComponents();
  const openedAccordionIndices = useEditorStore.useOpenedAccordionIndices();
  const setOpenedAccordionIndices =
    useEditorStore.useSetOpenAccordionOpenIndices();
  const moveAccordionItem = useEditorStore.useMoveAccordionItem();
  const activeComponentIndex = useEditorStore.useActiveComponentIndex();

  const { moveComponent, moveObject } = useEditorActions();

  useEffect(() => {
    if (activeComponentIndex == null) {
      return;
    }

    const newIndex = components.length - 1 - activeComponentIndex;

    if (openedAccordionIndices.includes(newIndex)) {
      return;
    }

    setOpenedAccordionIndices([...openedAccordionIndices, newIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeComponentIndex]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.type === 'editorComponent') {
      moveComponent(
        components.length - 1 - result.source.index,
        components.length - 1 - result.destination.index,
      );
      moveAccordionItem(result.source.index, result.destination.index);
      return;
    }

    if (result.type.includes('editorComponentObject')) {
      const parentIndex = result.type.split('-')[1];
      const objects = components[Number.parseInt(parentIndex)].layers;

      moveObject(
        Number.parseInt(parentIndex),
        objects.length - 1 - result.source.index,
        objects.length - 1 - result.destination.index,
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='componentList' type='editorComponent'>
        {(provided) => (
          <Accordion
            ref={provided.innerRef}
            w='full'
            allowMultiple={true}
            index={openedAccordionIndices}
            onChange={(newIndices) =>
              setOpenedAccordionIndices(newIndices as number[])
            }
          >
            {components
              .slice()
              .reverse()
              .map((component, index) => (
                <Draggable
                  key={`${component.name}++${component.index}`}
                  draggableId={`${component.name}++${component.index}`}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ComponentListItem
                        component={component}
                        dragHandleProps={provided.dragHandleProps}
                        index={component.index}
                        dragIndex={index}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </Accordion>
        )}
      </Droppable>
    </DragDropContext>
  );
};
