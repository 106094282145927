import {
  Button,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { ParseKeys } from 'i18next';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

const DECISION_TIMER_TIMEOUT = 5 * 60; // 5 minutes
const MODAL_TIMER_TIMEOUT = 15 * 60; // 15 minutes

export const InactivityModal = () => {
  const { t } = useTranslation();
  const [decisionTimer, setDecisionTimer] = useState(0);
  const [modalTimer, setModalTimer] = useState(0);
  const navigate = useNavigate();
  const [extensionCount, setExtensionCount] = useState(0);
  const onOpenAuthModal = useAuthenticationStore.useOnModalOpen();
  const isProjectLoading = useEditorStore.useIsProjectLoading();

  const components = useEditorStore.useComponents();

  const remainingDecisionTime = DECISION_TIMER_TIMEOUT - decisionTimer;

  const minutes = Math.floor(remainingDecisionTime / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (remainingDecisionTime % 60).toString().padStart(2, '0');

  useEffect(() => {
    if (isProjectLoading) {
      setModalTimer(0);
      return;
    }
    const timer = setInterval(() => setModalTimer((prev) => prev + 1), 1000);

    return () => clearInterval(timer);
  }, [components, isProjectLoading]);

  const isModalOpen = modalTimer >= MODAL_TIMER_TIMEOUT;

  useEffect(() => {
    if (!isModalOpen || extensionCount >= 3) {
      setDecisionTimer(0);
      return;
    }
    const timer = setInterval(() => setDecisionTimer((prev) => prev + 1), 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const isTimeoutReached = decisionTimer >= DECISION_TIMER_TIMEOUT;

  useEffect(() => {
    if (isTimeoutReached) {
      void navigate({ to: '/' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeoutReached]);

  const onExtendTimer = () => {
    setModalTimer(0);
    setExtensionCount((prev) => prev + 1);
  };

  const versions: {
    text: ParseKeys;
    actionBar: ReactNode;
  }[] = [
    {
      text: 'editor.inactivityModal.initialDescription',
      actionBar: (
        <>
          <Button fontSize='sm' onClick={onExtendTimer} variant='text'>
            {t('editor.inactivityModal.extendButton')}
          </Button>
          <Button
            fontSize='sm'
            onClick={() => {
              onOpenAuthModal('versionComparison');
            }}
          >
            {t('editor.inactivityModal.upgradeButton')}
          </Button>
        </>
      ),
    },
    {
      text: 'editor.inactivityModal.finalDescription',
      actionBar: (
        <>
          <Button
            fontSize='sm'
            onClick={() => {
              onOpenAuthModal('versionComparison');
            }}
          >
            {t('editor.inactivityModal.upgradeButton')}
          </Button>
          <Button
            fontSize='sm'
            onClick={() => navigate({ to: '/' })}
            variant='text'
          >
            {t('editor.inactivityModal.deleteDraftButton')}
          </Button>
        </>
      ),
    },
  ];

  const usedVersion = extensionCount < 3 ? versions[0] : versions[1];

  return (
    <Modal isOpen={isModalOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p='10'>
          <VStack>
            <Heading py='3.5' fontSize='h7'>
              Achtung
            </Heading>

            {extensionCount < 3 && (
              <Text py='4' fontSize='2xl' fontWeight='bold'>
                {minutes}:{seconds}
              </Text>
            )}
            <Text fontSize='sm'>{t(usedVersion.text)}</Text>
            <HStack justify='space-between' w='full' mt='10'>
              {usedVersion.actionBar}
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
