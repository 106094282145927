import { Grid } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const FullscreenSectionRow: FC<Props> = ({ children }) => {
  return (
    <Grid gap='4' templateColumns='repeat(4, minmax(1px, 1fr))'>
      {children}
    </Grid>
  );
};
