import { MaterialFullscreenModal } from '$/components/core/Editor/MaterialFullscreen/MaterialFullscreenModal';
import { useActiveEditorMaterial } from '$/pages/EditorPage/hooks/useActiveEditorMaterial';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';

export const EditorFullscreenModal = () => {
  const { setMaterial } = useEditorActions();
  const material = useActiveEditorMaterial();

  return (
    <MaterialFullscreenModal
      activeMaterial={material}
      selectMaterial={(m) => setMaterial(m.id)}
    />
  );
};
