export const editorColorTokens = {
  editor: {
    icon: {
      default: 'neutral.1000',
      _dark: 'neutral.50',
    },
    objectText: {
      default: 'neutral.800',
      _dark: 'neutral.200',
    },
    btnBackground: {
      default: 'neutral.100',
      _dark: 'neutral.950',
    },
    btnShareBg: {
      default: 'primaryBlue.700',
      _dark: 'primaryBlue.300',
    },
    txtExport: {
      default: 'neutral.800',
      _dark: 'neutral.200',
    },
    deletePopoverBg: {
      default: 'white',
      _dark: 'black',
    },
    deletePopoverText: {
      default: 'black',
      _dark: 'white',
    },
    borderVariants: {
      default: 'neutral.200',
      _dark: 'neutral.800',
    },
    actionBarDivider: {
      default: 'neutral.100',
      _dark: 'neutral.900',
    },
  },
};
