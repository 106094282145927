import { Flex, Image } from '@chakra-ui/react';

import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { buildRetouchPatternImageUrl } from '$/services/endpoints/imageEndpoints';

const structures = [
  buildRetouchPatternImageUrl({ pattern: 'asphalt' }),
  buildRetouchPatternImageUrl({ pattern: 'hecke2' }),
  buildRetouchPatternImageUrl({ pattern: 'himmel1' }),
  buildRetouchPatternImageUrl({ pattern: 'himmel2' }),
  buildRetouchPatternImageUrl({ pattern: 'kies' }),
  buildRetouchPatternImageUrl({ pattern: 'rasen1' }),
  buildRetouchPatternImageUrl({ pattern: 'rasen2' }),
  buildRetouchPatternImageUrl({ pattern: 'rasen3' }),
];

export const StructurePicker = () => {
  const { setRetouchPatternMaterial } = useEditorActions();

  return (
    <Flex wrap='wrap' gap='2'>
      {structures.map((structure, index) => (
        <Image
          key={structure}
          w='24px'
          h='24px'
          border='1px solid'
          borderColor='border'
          borderRadius='2px'
          cursor='pointer'
          onClick={() => setRetouchPatternMaterial(index)}
          src={structure}
        />
      ))}
    </Flex>
  );
};
