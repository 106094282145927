import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { trackEvent } from '$/logger';
import { Category } from '$/services/usecases/scenes';

type State = {
  activeCategory: Category | null;
};

type Actions = {
  selectCategory: (category: Category | null) => void;
};

export const store = create<State & Actions>()((set, get) => ({
  ...{ activeCategory: null },
  selectCategory: (category) => {
    if (!category) {
      set({ activeCategory: null });
    } else {
      trackEvent('FilterInspiration', 'Roomtype', category.name);
      const alreadyActive = get().activeCategory?.id === category.id;
      set({ activeCategory: alreadyActive ? null : category });
    }
  },
}));

export const useInspirationStore = createSelectorHooks(store);
