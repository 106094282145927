import {
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Popover,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextMenuButton } from '$/pages/EditorPage/components/ComponentSection/ContextMenuButton';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const ModuleSectionContextMenu = () => {
  const { t } = useTranslation();
  const moduleContextMenu = useEditorStore.useModuleContextMenu();
  const { deleteObject, selectObjects } = useEditorActions();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const shouldShowContextMenu =
    moduleContextMenu != null &&
    moduleContextMenu.x != null &&
    moduleContextMenu.y != null &&
    moduleContextMenu.elementIndex != null;

  useEffect(() => {
    if (shouldShowContextMenu) {
      onOpen();
    } else {
      onClose();
    }
  }, [onClose, onOpen, shouldShowContextMenu]);

  const onSelect = () => {
    if (
      moduleContextMenu?.elementIndex == null ||
      moduleContextMenu?.layerIndex == null
    )
      return;
    selectObjects([
      {
        componentIndex: moduleContextMenu.elementIndex,
        objectIndex: moduleContextMenu.layerIndex,
      },
    ]);
    onClose();
  };

  const onDelete = () => {
    if (
      moduleContextMenu?.elementIndex == null ||
      moduleContextMenu?.layerIndex == null
    )
      return;
    deleteObject(moduleContextMenu.elementIndex, moduleContextMenu.layerIndex);
    onClose();
  };

  return (
    <Popover
      closeOnBlur={true}
      isOpen={isOpen && shouldShowContextMenu}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Box
          pos='absolute'
          top={moduleContextMenu?.y ?? 0}
          left={moduleContextMenu?.x ?? 0}
        />
      </PopoverTrigger>
      <PopoverContent w='fit-content'>
        <PopoverArrow />
        <ContextMenuButton
          onClick={() => onSelect()}
          text={t('editor.select')}
        />
        <ContextMenuButton
          onClick={() => onDelete()}
          text={t('inspirationEditor.delete')}
        />
      </PopoverContent>
    </Popover>
  );
};
