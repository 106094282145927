import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import negate from 'lodash/negate';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { logError, trackEvent } from '$/logger';
import { queryClient } from '$/services/fetcher';
import { FilterType } from '$/services/mapper/uses';
import {
  BrightnessFilter,
  Filter,
  FilterGroup,
} from '$/services/usecases/filter';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { mapFilterGroupTypeToAction } from '$/utils/piwikUtils';

export const sortingOptions = [
  { key: 'subject_order', name: 'Fächerreihenfolge' },
  { key: 'popularity', name: 'Beliebtheit' },
  { key: 'brightness_value', name: 'Hellbezugswert' },
] as const;

export type SortingKey = (typeof sortingOptions)[number]['key'];

export type CollectionViewOptions = 'grid' | 'fan' | 'list';

export type State = {
  activeType: FilterType | undefined;
  filterGroups: FilterGroup[];
  brightnessFilter: BrightnessFilter;
  sorting: SortingKey;
  filter: Filter | undefined;
  loadingGroups: string[];
  selectedViewMode: CollectionViewOptions;
  hasUser: boolean;
};

type Actions = {
  isFilterActive: (type: string, option: string) => boolean;
  isAnyCategoryActive: (types: string[]) => boolean;
  setBrightnessFilter: (filter: BrightnessFilter) => void;
  setSortingOption: (option: SortingKey) => void;
  getActiveInCategory: (type: string) => FilterGroup[];
  resetFilter: () => Promise<void>;
  resetFilterGroup: (type: string[]) => Promise<void>;
  activateFilterGroup: (group: FilterGroup, multi?: boolean) => Promise<void>;
  persistFilterOptions: (
    newFilterGroups: FilterGroup[],
    brightnessFilter?: BrightnessFilter,
  ) => Promise<void>;
  setSelectedViewMode: (viewMode: CollectionViewOptions) => void;
  setHasUser: (hasUser: boolean) => Promise<void>;
  refetchFilterOptions: () => Promise<void>;
};

export const initial: State = {
  filterGroups: [],
  brightnessFilter: { from: 0, to: 100 },
  sorting: 'subject_order',
  filter: undefined,
  activeType: undefined,
  loadingGroups: [],
  selectedViewMode: 'grid',
  hasUser: false,
};

export const typeComparison = (type: string) => (filter: FilterGroup) =>
  filter.type === type;

export const filterComparison =
  (type: string, option: string) => (filter: FilterGroup) =>
    filter.type === type && filter.option === option;

const store = create<State & Actions>()(
  subscribeWithSelector((set, get) => ({
    ...initial,

    setBrightnessFilter: ({ from, to }) => {
      trackEvent(
        'FilterCollections',
        'LightReferenceValueMin',
        from.toString(),
      );

      trackEvent('FilterCollections', 'LightReferenceValueMax', to.toString());

      set({ brightnessFilter: { from, to } });
    },

    setSortingOption: (sorting) => {
      return set({ sorting });
    },

    getActiveInCategory: (type) => {
      return get().filterGroups.filter(typeComparison(type));
    },

    isFilterActive: (type, option) => {
      return get().filterGroups.some(filterComparison(type, option));
    },

    isAnyCategoryActive: (types) => {
      return types.some((type) =>
        get().filterGroups.some(typeComparison(type)),
      );
    },
    refetchFilterOptions: async () => {
      await get().persistFilterOptions(
        get().filterGroups,
        get().brightnessFilter,
      );
    },
    persistFilterOptions: async (newFilterGroups, brightnessFilter) => {
      const newFilter = await queryClient.ensureQueryData(
        filterOptionsQuery({
          type: get().activeType,
          filter: newFilterGroups,
          hasUser: get().hasUser,
        }),
      );

      const options = [...newFilter.additional, ...newFilter.general]
        .flatMap((option) => option.items)
        .map((option) => option.intName);

      const activeFilterGroups = newFilterGroups.filter((filterGroup) =>
        options.includes(filterGroup.option),
      );

      set({
        filterGroups: activeFilterGroups,
        filter: newFilter,
        brightnessFilter: brightnessFilter ?? get().brightnessFilter,
      });
    },

    resetFilter: async () => {
      await get().persistFilterOptions(
        initial.filterGroups,
        initial.brightnessFilter,
      );
    },

    resetFilterGroup: async (types) => {
      if (types.includes('brightness')) {
        return get().setBrightnessFilter(initial.brightnessFilter);
      }

      const newFilterGroups = get().filterGroups.filter(
        negate((filter: FilterGroup) => types.includes(filter.type)),
      );

      await get().persistFilterOptions(newFilterGroups);
    },

    activateFilterGroup: async (
      { type, option, displayName, payload },
      multi,
    ) => {
      const state = get();
      if (!state.filter) return;

      const action = mapFilterGroupTypeToAction(type);

      if (!action) {
        logError(new Error(`Could not map tracking action for: ${type}`));
      } else {
        trackEvent('FilterCollections', action, displayName);
      }

      const handleSingleSelectionFilter = () => {
        if (state.isFilterActive(type, option)) {
          const index = state.filterGroups.findIndex(
            filterComparison(type, option),
          );

          if (index !== -1) {
            return state.filterGroups.filter((_, i) => i !== index);
          }
        }

        let filter: FilterGroup[] = [...state.filterGroups];

        if (!multi) {
          const index = state.filterGroups.findIndex(typeComparison(type));

          if (index !== -1) {
            filter = filter.filter((_, i) => i !== index);
          }
        }

        return [...filter, { type, option, displayName, payload }];
      };

      await state.persistFilterOptions(handleSingleSelectionFilter());
    },
    setSelectedViewMode: (viewMode) => {
      set({ selectedViewMode: viewMode });
    },
    setHasUser: async (hasUser) => {
      set({ hasUser });
      await get().persistFilterOptions(get().filterGroups);
    },
  })),
);

export const useCollectionStore = createSelectorHooks(store) as typeof store &
  ZustandHookSelectors<State & Actions>;
