import { useCallback } from 'react';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';

export const useNonFreeAction = () => {
  const userRole = useAuthenticationStore.useUserRole();
  const onOpenAuthModal = useAuthenticationStore.useOnModalOpen();

  const handleNonFreeAction = useCallback(
    (action: () => void) => {
      if (userRole === 'free') {
        onOpenAuthModal('versionComparison');
      } else {
        action();
      }
    },
    [userRole, onOpenAuthModal],
  );

  return handleNonFreeAction;
};
