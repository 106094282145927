import { useInfiniteQuery } from '@tanstack/react-query';
import { FC, ForwardedRef } from 'react';

import { MaterialDetailsModal } from '$/components/core/Collection/MaterialDetailsModal';
import { DataSortingRow } from '$/components/core/Collection/MaterialPresentation/DataSortingRow';
import { FanPresentation } from '$/components/core/Collection/MaterialPresentation/FanPresentation';
import { GridPresentation } from '$/components/core/Collection/MaterialPresentation/GridPresentation';
import { ListPresentation } from '$/components/core/Collection/MaterialPresentation/ListPresentation';
import { useIntersectionObserver } from '$/hooks/useIntersectionObserver';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { infiniteMaterialsQuery } from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  type: MaterialType;
  scrollRefs?: ForwardedRef<HTMLDivElement>[];
  hideFavoriteButton?: boolean;
  isFullscreenMode?: boolean;
  hideFilterTags?: boolean;
  query?: string;
  onSelect: (material: Material) => void;
}

export const MaterialPresentation: FC<Props> = ({
  type,
  scrollRefs,
  onSelect,
  query,
  hideFavoriteButton = false,
  isFullscreenMode = false,
  hideFilterTags = false,
}) => {
  const filter = useCollectionStore.useFilterGroups();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();
  const selectedViewMode = useCollectionStore.useSelectedViewMode();

  const { data, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      infiniteMaterialsQuery({
        limit: 50,
        filter,
        brightnessFilter,
        type: [type],
        query,
      }),
    );

  const materials = data?.pages.flatMap((page) => page.materials) ?? [];
  const materialCount = data?.pages[0].materialCount;

  const observer = useIntersectionObserver(
    (intersecting) => intersecting && fetchNextPage(),
    { disabled: !data || isFetchingNextPage || materials?.length === 0 },
  );

  return (
    <>
      {!hideFilterTags && (
        <DataSortingRow
          amount={materialCount ?? 0}
          isLoading={isLoading}
          scrollRefs={scrollRefs}
        />
      )}

      {selectedViewMode === 'grid' && (
        <GridPresentation
          isLoading={isLoading || isFetchingNextPage}
          observer={observer}
          materials={materials}
          type={type}
          setActiveMaterial={onSelect}
          hideFavoriteButton={hideFavoriteButton}
          isFullscreenMode={isFullscreenMode}
        />
      )}

      {selectedViewMode === 'list' && (
        <ListPresentation
          isLoading={isLoading || isFetchingNextPage}
          observer={observer}
          materials={materials}
          type={type}
          setActiveMaterial={onSelect}
          hideFavoriteButton={hideFavoriteButton}
        />
      )}

      {selectedViewMode === 'fan' && (
        <FanPresentation
          isLoading={isLoading || isFetchingNextPage}
          observer={observer}
          materials={materials}
          type={type}
          setActiveMaterial={onSelect}
          hideFavoriteButton={hideFavoriteButton}
        />
      )}

      <MaterialDetailsModal />
    </>
  );
};
