import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';

let reactPlugin: ReactPlugin | undefined;
let appInsights: ApplicationInsights | undefined;

export const getApplicationInsights = () => {
  if (!import.meta.env.VITE_APPINSIGHTS_INSTRUMENTATIONKEY) {
    return null;
  }

  if (!reactPlugin) {
    reactPlugin = new ReactPlugin();
  }

  if (!appInsights) {
    appInsights = new ApplicationInsights({
      config: {
        connectionString: `instrumentationKey=${import.meta.env.VITE_APPINSIGHTS_INSTRUMENTATIONKEY}`,
        extensions: [reactPlugin],
        enableAutoRouteTracking: false,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: false,
        enableCorsCorrelation: false,
        enableRequestHeaderTracking: false,
        enableResponseHeaderTracking: false,
        enableUnhandledPromiseRejectionTracking: false,
        enableAjaxPerfTracking: false,
        disableCookiesUsage: true,
      },
    });

    appInsights.loadAppInsights();

    appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
      env.tags = env.tags || [];
      env.tags['ai.cloud.role'] = import.meta.env.VITE_NODE_ENV;
      env.data!['ms-appName'] = 'spectrum';
    });
  }

  return { appInsights, reactPlugin };
};
