import {
  Checkbox,
  CheckboxGroup,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Dispatch, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportFormData } from '$/pages/InspirationEditorPage/components/ExportModal';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

const perspectiveNumbers = [0, 1, 2];

interface Props {
  state: ExportFormData;
  dispatch: Dispatch<Partial<ExportFormData>>;
}

export const ExportOptionsSelection: FC<Props> = ({ state, dispatch }) => {
  const { t } = useTranslation();

  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();
  const scene = useInspirationEditorStore.useScene();
  const sceneVariantLabelOrder =
    useInspirationEditorStore.useSceneVariantLabelOrder();

  const perspectiveArray = perspectiveNumbers.slice(0, scene?.viewCount);

  return (
    <Stack gap={{ base: '4', mobile: '6' }}>
      <Text fontSize='sm' lineHeight='20px'>
        {t('inspirationEditor.chooseExportOptions')}
      </Text>

      <SimpleGrid columns={2}>
        <Stack gap='3'>
          <CheckboxGroup
            defaultValue={['0']}
            isDisabled={perspectiveArray.length === 1}
            onChange={(value) =>
              dispatch({
                perspectives: value.map((val) =>
                  Number.parseInt(val.toString()),
                ),
              })
            }
          >
            {perspectiveArray.map((_, index) => (
              <Checkbox
                key={index}
                fontWeight='bold'
                defaultChecked={state.perspectives.includes(index)}
                value={index.toString()}
              >
                <Text fontSize='sm' lineHeight='20px'>
                  {t('inspirationEditor.perspectiveName', {
                    number: index + 1,
                  })}
                </Text>
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Stack>

        <Stack alignItems='flex-start' gap='3'>
          <CheckboxGroup
            defaultValue={['0']}
            isDisabled={sceneLayersVariants.length === 1}
            onChange={(value) =>
              dispatch({
                variants: value.map((val) => Number.parseInt(val.toString())),
              })
            }
          >
            {sceneLayersVariants.map((_, index) => (
              <Checkbox
                key={index}
                fontWeight='bold'
                defaultChecked={state.variants.includes(index)}
                value={index.toString()}
              >
                {' '}
                <Text fontSize='sm' lineHeight='20px'>
                  {t('inspirationEditor.variantName', {
                    letter: sceneVariantLabelOrder[index],
                  })}{' '}
                </Text>
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Stack>
      </SimpleGrid>
    </Stack>
  );
};
