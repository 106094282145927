import { IconButton } from '@chakra-ui/react';

import { Icon } from '$/components/common/Icon';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const FullscreenButton = () => {
  const setIsFullscreen = useEditorStore.useSetIsFullscreen();
  const isFullscreen = useEditorStore.useIsFullScreen();

  return (
    <IconButton
      pos='absolute'
      top='10'
      right='10'
      opacity={isFullscreen ? '1' : '0'}
      transition='opacity 0.3s'
      aria-label=''
      icon={<Icon icon='zoom_in_map' />}
      onClick={() => setIsFullscreen(false)}
      variant='secondary'
    />
  );
};
