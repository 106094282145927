import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { FileInput } from '$/pages/EditorPage/components/LoadModal/FileInput';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { uploadProjectImage } from '$/services/usecases/editor';
import { createEditorProject } from '$/services/usecases/projects';
import { removeSpecialCharacters } from '$/utils/stringUtils';

export const LoadModal = () => {
  const { t } = useTranslation();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [projectName, setProjectName] = useState<string>('');
  const user = useAuthenticationStore.useUser();

  const navigate = useNavigate();

  const goBack = () => {
    onClose();
    void navigate({ from: '.', to: '../' });
  };

  const uploadDisabled = selectedFile == null || projectName.length < 2;

  const onHandleUpload = async () => {
    if (uploadDisabled) return;

    const token = await uploadProjectImage(selectedFile);

    if (token == null) return;

    if (user?.id != null) {
      const { response } = await createEditorProject(token, projectName);
      location.search = `?projectId=${response.payload.projectId}`;
    } else {
      useEditorStore.setState({ projectName, freeUserToken: token });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody w='32rem' h='46rem' bg='background' borderRadius='12px'>
          <VStack
            justifyContent='left'
            gap='6'
            w='full'
            h='full'
            m='4'
            pr='6'
            fontSize='sm'
            bg='background'
          >
            <Heading
              as='h3'
              w='full'
              my='4'
              fontSize='2xl'
              fontWeight='bold'
              textAlign='left'
            >
              {t('editor.new_project')}
            </Heading>
            <Box w='full'>{t('editor.modal_text')}</Box>
            <Box w='full'>
              <Text w='full' mb='2' fontSize='sm'>
                {t('editor.projectName')}
              </Text>
              <Input
                w='full'
                px='4'
                id='file_input'
                onChange={(e) =>
                  setProjectName(removeSpecialCharacters(e.target.value))
                }
                placeholder={t('dashboard.authentication.defaultPlaceholder', {
                  field: t('editor.projectName'),
                })}
                type='text'
                value={projectName}
              />
            </Box>
            <Box w='full'>
              <HStack justifyContent='space-between' w='full'>
                <Box w='full' mb='2'>
                  {t('editor.upload_picture')}
                </Box>
              </HStack>

              <FileInput
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
              />
            </Box>

            {t('editor.new_project')}
            <HStack justifyContent='space-between' w='full' mt='4'>
              <Button onClick={goBack} variant='text'>
                {t('editor.cancel')}
              </Button>
              <Button
                px='16'
                bg={uploadDisabled ? 'border' : 'editor.btnShareBg'}
                isDisabled={uploadDisabled}
                onClick={() => onHandleUpload()}
              >
                {t('editor.upload')}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
