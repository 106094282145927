import { z } from 'zod';

import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';
import { UserSchema } from '$/services/usecases/authentication/mapper/genericCloudResponse';

export const UpdateDataResponseSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: z
    .object({
      user: UserSchema,
    })
    .optional(),
});
