import { Flex, Tag, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericTagContent } from '$/components/core/Collection/MaterialPresentation/DataSortingRow/components/GenericTagContent';
import { ProTagItem } from '$/components/core/Editor/MaterialFullscreen/component/FilterSidebar/components/ProTagItem';
import { FilterGroupOption } from '$/services/usecases/filter';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  filter: FilterGroupOption;
}

export const TagFilter: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();
  const isFilterActive = useCollectionStore.useIsFilterActive();

  return (
    <Flex wrap='wrap' gap='2'>
      {filter.items.map((item) => {
        const key = item.intName;
        const isActive = isFilterActive(filter.searchName, item.intName);

        return (
          <Tag
            key={key}
            gap='1'
            w='fit-content'
            h='32px'
            px='3'
            py='1'
            color={isActive ? 'selectedText' : 'text'}
            bg={isActive ? 'primaryButton.lightBackground' : 'background'}
            border='solid 1px'
            borderColor={isActive ? 'form.activeBorder' : 'border'}
            borderRadius='full'
            cursor='pointer'
            transition='color 300ms ease, background 300ms ease, border-color 300ms ease'
            onClick={async () => {
              await activateFilterGroup(
                {
                  type: item.searchName,
                  option: item.intName,
                  displayName: item.displayName,
                  payload: item.payload,
                },
                filter.selectionFilter === 'multi',
              );
            }}
          >
            <GenericTagContent label={item.displayName} color={item.payload} />

            <Text color='lighterText' fontSize='xs'>
              {item.amount}
            </Text>
          </Tag>
        );
      })}
      {(filter.searchName === 'collection' ||
        filter.searchName === 'producer') && (
        <ProTagItem name={t(filter.displayNameKey)} />
      )}
    </Flex>
  );
};
