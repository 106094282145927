export const quickColors: string[][] = [
  ['prod_(caparol)___col_(3d-system-plus)___pacific 165 ___ao___g1'],
  [
    'prod_(caparol)___col_(3d-system-plus)___indeko-plus weiss ___ao___g1',
    'prod_(caparol)___col_(3d-system-plus)___jura 5 ___ao___g1',
  ],
  [
    'prod_(caparol)___col_(3d-system-plus)___pink 20 ___ao___g1',
    'prod_(caparol)___col_(3d-system-plus)___laser 25 ___ao___g1',
    'prod_(caparol)___col_(3d-system-plus)___laser 35 ___ao___g1',
    'prod_(caparol)___col_(3d-system-plus)___laser 60 ___ao___g1',
  ],
];
