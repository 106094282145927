import { array, literal, number, object, string, union, z } from 'zod';

import { AvailableIcons } from '$/components/common/Icon';
import { t } from '$/components/core/Localization/18n';

export const filterColors = new Map([
  ['white', '#FFFFFF'],
  ['black', '#000000'],
  ['yellow', '#FFE600'],
  ['orange', '#FF9900'],
  ['red', '#FF0000'],
  ['pink', '#FF00F5'],
  ['purple', '#8F00FF'],
  ['blue', '#0085FF'],
  ['green', '#00CC52'],
  ['beige', '#C7BEB3'],
  ['brown', '#804500'],
  ['grey', '#808080'],
]);

const order = [...filterColors.keys()];

const searchNameToFilterName = new Map([
  ['producer', 'producer'],
  ['collection', 'collection'],
  ['color', 'color'],
  ['productGroup', 'SEARCH_CRITERIA_20'],
  ['colorIntense', 'SEARCH_CRITERIA_39'],
  ['design', 'SEARCH_CRITERIA_36'],
  ['material', 'SEARCH_CRITERIA_37'],
  ['rangeOfUse', 'SEARCH_CRITERIA_38'],
]);

const searchNameToIcon = new Map<string, AvailableIcons>([
  ['producer', 'producer'],
  ['collection', 'collection'],
  ['color', 'color_palette'],
  ['productGroup', 'filter'],
  ['colorIntense', 'color_intensity'],
  ['design', 'design'],
  ['material', 'material'],
  ['rangeOfUse', 'field_of_use'],
]);

type TranslationKeyPathOptions =
  | 'prodgroup_filter'
  | 'colorint_filter'
  | 'design_filter'
  | 'decor_filter'
  | 'apparea_filter';

const genericFilterOptionFactory = (
  searchName: string,
  translationKeyPath?: TranslationKeyPathOptions,
) =>
  object({
    showName: string(),
    selectionFilter: union([literal('s'), literal('m')]),
    filterType: union([literal('s'), literal('a')]),
    items: array(
      object({ intName: string(), showName: string(), cnt: number() }),
    ),
  }).transform((data) => ({
    // TODO: Remove mapping when Backend provides correct searchName
    searchName: searchNameToFilterName.get(searchName)!,
    selectionFilter:
      data.selectionFilter === 'm' ? ('multi' as const) : ('single' as const),
    filterType:
      data.filterType === 'a' ? ('additional' as const) : ('standard' as const),
    displayNameKey: `dashboard.collection.filter.${searchName}`,
    items: data.items.map(({ intName, cnt, showName }) => ({
      intName,
      // TODO: Remove mapping when Backend provides correct searchName
      searchName: searchNameToFilterName.get(searchName)!,
      amount: cnt,
      displayName: translationKeyPath
        ? t(`dashboard.collection.filter.${translationKeyPath}.${intName}`)
        : showName,
      payload: undefined as undefined | string,
    })),
    iconName: searchNameToIcon.get(searchName) ?? 'filter',
  }));

export const FilterOptionsSchema = object({
  producer: genericFilterOptionFactory('producer').optional(),
  productGroup: genericFilterOptionFactory(
    'productGroup',
    'prodgroup_filter',
  ).optional(),
  collection: genericFilterOptionFactory('collection').optional(),
  color: genericFilterOptionFactory('color')
    .transform((data) => ({
      ...data,
      items: data.items
        .sort((a, b) => order.indexOf(a.intName) - order.indexOf(b.intName))
        .map((item) => ({
          ...item,
          displayName: t(
            `dashboard.collection.filter.colors_filter.${item.intName}`,
          ),
          payload: filterColors.get(item.intName),
        })),
    }))
    .optional(),
  colorIntense: genericFilterOptionFactory(
    'colorIntense',
    'colorint_filter',
  ).optional(),
  design: genericFilterOptionFactory('design', 'design_filter').optional(),
  material: genericFilterOptionFactory('material', 'decor_filter').optional(),
  rangeOfUse: genericFilterOptionFactory(
    'rangeOfUse',
    'apparea_filter',
  ).optional(),
});

export type GenericFilter = z.infer<
  ReturnType<typeof genericFilterOptionFactory>
>;

export type FilterOption = {
  payload: string | undefined;
  searchName: string;
  intName: string;
  displayName: string;
  amount: number;
}[];
