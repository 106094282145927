import { Box, useDisclosure } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { FC, useState } from 'react';

import { CardContent } from '$/components/common/Card/CardContent';
import { Show } from '$/components/common/Flow/Show';
import { ProjectCardContextMenu } from '$/components/core/Projects/ProjectCard/components/ProjectCardContextMenu';
import { ProjectOverviewItem } from '$/services/usecases/projects/mapper/editorProject';

interface Props {
  project: ProjectOverviewItem;
  disableContextMenu?: boolean;
}

export const ProjectCard: FC<Props> = ({
  project,
  disableContextMenu = false,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);
  const [imageHash] = useState(() => Date.now());

  return (
    <Link
      to={
        project.type === 'editor'
          ? `/editor?projectId=${project.id}`
          : '/inspirationProjects/$id'
      }
      params={{ id: project.id }}
    >
      <Box
        pos='relative'
        onContextMenu={(event) => {
          if (disableContextMenu) {
            return;
          }
          event.preventDefault();
          onOpen();
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Show when={!disableContextMenu && (isHovered || isOpen)}>
          <ProjectCardContextMenu
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            project={project}
          />
        </Show>
        <CardContent
          label={project.name}
          bg='transparent'
          image={`${project.thumbnailUrl}${project.type === 'inspiration' ? '?' + imageHash : ''}`}
        />
      </Box>
    </Link>
  );
};
