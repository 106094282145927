import { StackProps, Box, VStack } from '@chakra-ui/react';
import { FC, RefObject } from 'react';

import { For } from '$/components/common/Flow/For';
import { Show } from '$/components/common/Flow/Show';
import { ListPresentationItem } from '$/components/core/Collection/MaterialPresentation/ListPresentationItem';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { repeat } from '$/utils/arrayUtils';

interface Props extends StackProps {
  observer: RefObject<HTMLDivElement>;
  setActiveMaterial: (material: Material) => void;
  materials: Material[];
  type: MaterialType;
  isLoading: boolean;
  hideFavoriteButton?: boolean;
}

export const ListPresentation: FC<Props> = ({
  observer,
  setActiveMaterial,
  materials,
  type,
  isLoading,
  hideFavoriteButton = false,
}) => {
  return (
    <VStack gap='3'>
      <For each={materials}>
        {(material, i) => (
          <Box w='full'>
            <ListPresentationItem
              key={material.id}
              material={material}
              onClick={() => setActiveMaterial(material)}
              materialType={type}
              hideFavoriteButton={hideFavoriteButton}
            />

            {i === materials.length - 15 && <Box ref={observer} />}
          </Box>
        )}
      </For>
      <Show when={isLoading}>
        {repeat(20).map((i) => (
          <ListPresentationItem
            materialType={type}
            key={i}
            hideFavoriteButton={hideFavoriteButton}
          />
        ))}
      </Show>
    </VStack>
  );
};
