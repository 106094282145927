import { Stack } from '@chakra-ui/react';
import { FC } from 'react';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { MaterialDetails } from '$/components/core/Editor/ColorSelectionSidebar/AppliedMaterial/MaterialDetails';
import { FavoriteModal } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteModal';
import { Material } from '$/services/usecases/materials';

interface Props {
  material: Material;
}

export const AppliedColor: FC<Props> = ({ material }) => {
  const { onModalOpen } = useAuthenticationStore();
  const { hasPermission } = useAuthorization();

  const showAsProMaterial =
    !hasPermission('View_All_Collections') && material.isProMaterial;

  const onCardClick = showAsProMaterial
    ? () => onModalOpen('versionComparison')
    : undefined;

  return (
    <Stack gap='4' w='full'>
      <MaterialCard
        material={material}
        editMode='favorite'
        onClick={onCardClick}
      />

      <MaterialDetails material={material} />

      <FavoriteModal />
    </Stack>
  );
};
