import { projectEndpoints } from '$/services/endpoints';
import { fetcher } from '$/services/fetcher';
import { GeneralBackendResponseObjectSchema } from '$/services/mapper/authentication';
import { validateToken } from '$/services/usecases/authentication';
import {
  CreateEditorProjectResponseSchema,
  GetEditorProjectResponseSchema,
  GetProjectOverviewResponseSchema,
} from '$/services/usecases/projects/mapper/editorProject';
import {
  GetInspirationProjectResponseSchema,
  InspirationProjectLayer,
  UpsertInspirationProjectResponseSchema,
} from '$/services/usecases/projects/mapper/inspirationProjects';

export type EditorProject = {
  userId: string;
  isPublic: boolean;
  projectName: string;
  type: 'editor';
  projectIds: string[];
  token: string | null;
};

export const uploadProjectThumbnail = async (
  projectId: string,
  image: File,
) => {
  const data = new FormData();
  data.append('file', image);

  await validateToken();

  return await fetcher(
    projectEndpoints.UPLOAD_PROJECT_THUMBNAIL_URL(projectId),
    {
      method: 'PUT',
      body: data,
      includeCredentials: true,
      isFormData: true,
      parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
    },
  );
};

export const getAllProjects = async () => {
  await validateToken();
  return fetcher(projectEndpoints.PROJECT_OVERVIEW_URL, {
    method: 'GET',
    includeCredentials: true,
    parser: (data) => GetProjectOverviewResponseSchema.parse(data),
  });
};

export const createEditorProject = async (
  token: string,
  projectName: string,
  isProjectId?: boolean,
) => {
  await validateToken();
  return fetcher(projectEndpoints.SAVE_EDITOR_PROJECT_URL, {
    method: 'POST',
    includeCredentials: true,
    body: {
      token,
      projectName,
      isProjectId,
    },
    parser: (data) => CreateEditorProjectResponseSchema.parse(data),
  });
};

export const addProjectIdToEditorProject = async (
  internalProjectId: string,
  projectId: string,
) => {
  await validateToken();
  return fetcher(
    projectEndpoints.ADD_PROJECT_ID_TO_EDITOR_URL(internalProjectId),
    {
      method: 'PATCH',
      includeCredentials: true,
      body: {
        projectId,
      },
      parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
    },
  );
};

export const upsertInspirationProject = async (
  variants: InspirationProjectLayer[][],
  sceneId: string,
  projectName: string,
  projectId: string | null,
) => {
  await validateToken();
  return fetcher(projectEndpoints.UPSERT_INSPIRATION_EDITOR_PROJECT_URL, {
    method: 'PUT',
    includeCredentials: true,
    body: {
      projectId: projectId ?? undefined,
      projectName,
      sceneId,
      variants,
    },
    parser: (data) => UpsertInspirationProjectResponseSchema.parse(data),
  });
};

export const getEditorProject = async (projectId: string) => {
  await validateToken();
  return fetcher(projectEndpoints.GET_PROJECT_URL('editor', projectId), {
    method: 'GET',
    includeCredentials: true,
    parser: (data) => GetEditorProjectResponseSchema.parse(data),
  });
};

export const getInspirationProject = async (projectId: string) => {
  await validateToken();
  return fetcher(projectEndpoints.GET_PROJECT_URL('inspiration', projectId), {
    method: 'GET',
    includeCredentials: true,
    parser: (data) => GetInspirationProjectResponseSchema.parse(data),
  });
};

export const renameProject = async (projectId: string, name: string) => {
  await validateToken();
  return fetcher(projectEndpoints.RENAME_PROJECT_URL(projectId), {
    method: 'PATCH',
    includeCredentials: true,
    body: {
      name,
    },
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const deleteProject = async (projectId: string) => {
  await validateToken();
  return fetcher(projectEndpoints.DELETE_PROJECT_URL(projectId), {
    method: 'DELETE',
    includeCredentials: true,
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const changeProjectVisibility = async (
  projectId: string,
  isPublic: boolean,
) => {
  await validateToken();

  return await fetcher(
    projectEndpoints.CHANGE_PROJECT_VISIBILITY_URL(projectId),
    {
      body: { isPublic },
      method: 'PATCH',
      parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
      includeCredentials: true,
    },
  );
};
