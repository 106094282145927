import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledLink, TypedToOptions } from '$/components/core/StyledLink';

interface Props {
  headline: string;
  showAllUrl?: TypedToOptions;
  onShowMoreClick?: () => void;
  children: ReactNode;
  customActionElement?: ReactNode;
}

export const SectionHeadline: FC<Props> = ({
  showAllUrl,
  onShowMoreClick,
  headline,
  children,
  customActionElement,
}) => {
  const { t } = useTranslation();

  const generalShowMoreProps = {
    px: '0',
    color: 'lighterText',
    fontSize: 'sm',
    borderRadius: '4',
    _hover: { bg: 'bodyBackground' },
  };

  return (
    <Stack alignItems='flex-start' gap='3' w='full'>
      <HStack
        justify='space-between'
        flexWrap='wrap'
        w='full'
        fontWeight='bold'
      >
        <Text pr='8' fontSize='lg'>
          {headline}
        </Text>
        {showAllUrl && (
          <StyledLink
            link={{ to: showAllUrl, params: {}, search: {} }}
            {...generalShowMoreProps}
          >
            {t('dashboard.welcome.showAllButton')}
          </StyledLink>
        )}
        {onShowMoreClick && (
          <Button
            h='fit-content'
            py='1'
            onClick={onShowMoreClick}
            variant='ghost'
            {...generalShowMoreProps}
          >
            {t('dashboard.welcome.showAllButton')}
          </Button>
        )}
        {customActionElement}
      </HStack>
      <Box w='full'>{children}</Box>
    </Stack>
  );
};
