import { VStack, Divider, Spacer } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BrushOptions } from '$/pages/EditorPage/components/AttributesSection/components/BrushOptions';
import { TitleSection } from '$/pages/EditorPage/components/AttributesSection/components/TitleSection';
import {
  ModuleSendBrushShapeAction,
  ModuleSendBrushSizeAction,
} from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const RubberSidebar = () => {
  const { t } = useTranslation();

  const brushSize = useEditorStore.useRubberSize();
  const brushType = useEditorStore.useRubberType();

  const { setBrushSize } = useEditorActions();

  return (
    <VStack
      w='full'
      fontSize='sm'
      divider={<Divider pt='1' borderColor='border' />}
    >
      <TitleSection title={t('editor.rubber.title')} />

      <BrushOptions
        sizeAction={ModuleSendBrushSizeAction.RubberSize}
        shapeAction={ModuleSendBrushShapeAction.RubberShape}
        brushSize={brushSize}
        setBrushSize={setBrushSize}
        brushType={brushType}
        title={t('editor.rubber.size')}
        shapeTitle={t('editor.rubber.shape')}
      />

      <Spacer />
    </VStack>
  );
};
