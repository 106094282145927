import { Grid, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FC, useState } from 'react';

import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { MultiColorInput } from '$/pages/EditorPage/components/AttributesSection/components/ActiveColorOptions/components/ColorInput/components/MultiColorInput';
import { SingleColorInput } from '$/pages/EditorPage/components/AttributesSection/components/ActiveColorOptions/components/ColorInput/components/SingleColorInput';
import {
  hexToRgb,
  hsbToRgb,
  isValidHex,
  isValidHsb,
  isValidRgb,
  RGBA,
  rgbToHex,
  rgbToHsb,
} from '$/utils/colorUtils';

interface Props {
  color: RGBA;
  setColor: (color: RGBA) => void;
}

const colorModes = ['RGB', 'HSB', 'HEX'] as const;

export const ColorInput: FC<Props> = ({ color, setColor }) => {
  const [colorMode, setColorMode] =
    useState<(typeof colorModes)[number]>('RGB');

  return (
    <Grid
      alignItems='center'
      justifyContent='start'
      gap='0'
      templateColumns='52px auto'
      w='full'
    >
      <Menu>
        <MenuButton
          as={GhostButton}
          pr='7px'
          pl='0'
          fontSize='sm'
          fontWeight='normal'
          rightIcon={<Icon icon='chevron_down' w='10px' />}
        >
          {colorMode}
        </MenuButton>
        <MenuList>
          {colorModes.map((mode) => (
            <MenuItem
              key={mode}
              bg={mode === colorMode ? 'secondaryBackground' : 'transparent'}
              _hover={{
                bg:
                  mode === colorMode ? 'secondaryBackground' : 'bodyBackground',
              }}
              onClick={() => setColorMode(mode)}
            >
              {mode}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      {colorMode === 'HEX' && (
        <SingleColorInput
          color={color}
          setColor={setColor}
          isValidColor={isValidHex}
          rgbToValConverter={(color) => rgbToHex(color.r, color.g, color.b)}
          valToRgbConverter={(value) => {
            const [r, g, b] = hexToRgb(value);
            return { r, g, b, a: color.a };
          }}
        />
      )}
      {colorMode === 'RGB' && (
        <MultiColorInput
          color={color}
          setColor={setColor}
          isValidColor={([r, g, b]) => isValidRgb({ r, g, b })}
          rgbToValConverter={(color) => [color.r, color.g, color.b]}
          valToRgbConverter={([r, g, b]) => ({ r, g, b, a: color.a })}
          normalizeValue={(val) => Math.min(Math.max(val, 0), 255)}
        />
      )}
      {colorMode === 'HSB' && (
        <MultiColorInput
          color={color}
          setColor={setColor}
          isValidColor={([h, s, b]) => isValidHsb({ h, s, b })}
          rgbToValConverter={(color) => rgbToHsb(color.r, color.g, color.b)}
          valToRgbConverter={([v1, v2, v3]) => {
            const [r, g, b] = hsbToRgb(v1, v2, v3);
            return { r, g, b, a: color.a };
          }}
          normalizeValue={(val, i) =>
            Math.min(Math.max(val, 0), [360, 100, 100][i])
          }
        />
      )}
    </Grid>
  );
};
