import { object, z } from 'zod';

import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';

export const FavoriteSchema = z.object({
  materialId: z.string(),
  parentFolderIds: z.array(z.string()),
});

export const FavoriteFolderSchema = z.object({
  name: z.string(),
  id: z.string(),
});

export const GetFavoritesResponseSchema = object({
  ...GeneralBackendResponseSchema,
  payload: object({
    favoriteFolders: z.array(FavoriteFolderSchema),
    favoriteMaterials: z.array(FavoriteSchema),
  }).optional(),
});

export const CreateFavoriteFolderResponseSchema = object({
  ...GeneralBackendResponseSchema,
  payload: FavoriteFolderSchema.optional(),
});

export type GetFavoritesResponse = z.infer<typeof GetFavoritesResponseSchema>;
