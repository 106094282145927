import { Grid, ResponsiveValue } from '@chakra-ui/react';
import { FC, RefObject, useState } from 'react';

import { For } from '$/components/common/Flow/For';
import { FanPresentationItem } from '$/components/core/Collection/MaterialPresentation/FanPresentationItem';
import { Material } from '$/services/usecases/materials';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  fanPage: Material[];
  showObserver: boolean;
  observer: RefObject<HTMLDivElement>;
  setActiveMaterial: (material: Material) => void;
  disableHovering?: boolean;
  height?: ResponsiveValue<string>;
  borderRadius?: string;
  activeMaterialId?: string;
  hideFavoriteButton?: boolean;
}

export const FanPresentationRow: FC<Props> = ({
  fanPage,
  showObserver,
  observer,
  setActiveMaterial,
  disableHovering = false,
  height = '7.5rem',
  borderRadius = '8px',
  activeMaterialId,
  hideFavoriteButton = false,
}) => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const sortedItems = fanPage.sort(
    (a, b) => a.position.pagePosition - b.position.pagePosition,
  );

  const getColWidth = () => {
    if (hoveredItem === null || sortedItems.length <= 3) {
      return 'minmax(0, 1fr)';
    }

    return `minmax(0, ${sortedItems.length / 3}fr)`;
  };

  const cols = repeat(sortedItems.length)
    .map((_, i) => (i === hoveredItem ? getColWidth() : 'minmax(0, 1fr)'))
    .join(' ');

  return (
    <Grid
      gap='0'
      templateColumns={cols}
      overflow='hidden'
      w='full'
      minH={height}
      maxH={height}
      transition='300ms'
    >
      <For each={sortedItems}>
        {(material, i) => (
          <FanPresentationItem
            material={material}
            pageSize={sortedItems.length}
            isHovered={i === hoveredItem}
            setActiveMaterial={setActiveMaterial}
            onMouseLeave={() => !disableHovering && setHoveredItem(null)}
            onMouseOver={() => !disableHovering && setHoveredItem(i)}
            height={height}
            activeMaterialId={activeMaterialId}
            borderRadius={borderRadius}
            hideFavoriteButton={hideFavoriteButton}
          />
        )}
      </For>
      {showObserver && <div ref={observer} />}
    </Grid>
  );
};
