import { createRoute } from '@tanstack/react-router';

import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { CollectionCategoryPage } from '$/pages/DashboardPages/pages/Collection/pages/CollectionCategoryPage';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';
import { FilterType } from '$/services/mapper/uses';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

const type: FilterType = 'facade';

export const FacadeCollectionsRoute = createRoute({
  getParentRoute: () => BaseRouteLayout,
  path: '/collections/facades',
  component: () => (
    <CollectionCategoryPage filterType={type} materialType={type} />
  ),
  loader: ({ context: { client } }) => {
    useCollectionStore.setState({
      filter: undefined,
      filterGroups: [],
      activeType: type,
      selectedViewMode: 'grid',
    });
    useMaterialSearchStore.setState({
      query: '',
      dropdownVisible: false,
    });

    const { filterGroups } = useCollectionStore.getState();
    void client
      .ensureQueryData(filterOptionsQuery({ type, filter: filterGroups }))
      .then((filter) => useCollectionStore.setState({ filter }));
  },
  staleTime: 0,
});
