import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalHeaderWithBackButton } from '$/components/common/Modals/ModalHeaderWithBackButton';
import { ProfileModalChangePassword } from '$/components/core/Profile/ProfileModalChangePassword';
import { ProfileModalChangePasswordSuccess } from '$/components/core/Profile/ProfileModalChangePasswordSuccess';
import { ProfileModalDeleteAccount } from '$/components/core/Profile/ProfileModalDeleteAccount';
import { ProfileModalOverview } from '$/components/core/Profile/ProfileModalOverview';
import { trackContentImpression, trackContentInteraction } from '$/logger';
import { useProfileStore } from '$/stores/useProfileStore';
import {
  mapProfileModalToPiwikContentPiece,
  PiwikContentPiece,
} from '$/utils/piwikUtils';

export const ProfileModal: FC = () => {
  const { t } = useTranslation();

  const isProfileModalOpen = useProfileStore.useIsModalOpen();
  const profileModalType = useProfileStore.useModalType();
  const onCloseAuthenticationModal = useProfileStore.useOnModalClose();
  const setProfileModalType = useProfileStore.useSetModalType();

  useEffect(() => {
    const contentPiece = mapProfileModalToPiwikContentPiece(profileModalType);

    if (contentPiece && isProfileModalOpen) {
      trackContentImpression('CloudAccount', contentPiece, 'Open');
    }
  }, [profileModalType, isProfileModalOpen]);

  const onClose = () => {
    trackContentInteraction(
      'ClickButton',
      'CloudAccount',
      'ManageAccount',
      'Close',
    );

    onCloseAuthenticationModal();
  };

  let displayHeader;
  let displayCloseButton;
  let displayBackButton;

  let chosenModalHeader;
  let chosenModalContentPiece: PiwikContentPiece | undefined;
  let chosenModalBody;

  switch (profileModalType) {
    case 'profile':
      displayHeader = true;
      displayCloseButton = true;
      displayBackButton = false;

      chosenModalHeader = t('dashboard.authentication.profile.yourProfile');
      chosenModalBody = (
        <ProfileModalOverview
          onViewChange={(view) => setProfileModalType(view)}
        />
      );
      break;
    case 'change_password':
      displayHeader = true;
      displayCloseButton = true;
      displayBackButton = true;

      chosenModalHeader = t('dashboard.authentication.changePassword.action');
      chosenModalContentPiece = 'EditPassword';
      chosenModalBody = <ProfileModalChangePassword />;
      break;
    case 'change_password_success':
      displayHeader = false;
      displayCloseButton = false;
      displayBackButton = false;

      chosenModalHeader = null;
      chosenModalContentPiece = 'EditPassword';
      chosenModalBody = <ProfileModalChangePasswordSuccess onClose={onClose} />;
      break;
    case 'delete_account':
      displayHeader = true;
      displayCloseButton = true;
      displayBackButton = true;

      chosenModalHeader = t('dashboard.authentication.deleteAccount.action');
      chosenModalContentPiece = 'DeleteAccount';
      chosenModalBody = <ProfileModalDeleteAccount />;
      break;
  }

  return (
    <Modal isCentered isOpen={isProfileModalOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW='500px' bg='background'>
        {displayHeader && (
          <ModalHeaderWithBackButton
            headerText={chosenModalHeader}
            trackingContentName='CloudAccount'
            trackingContentPiece={chosenModalContentPiece}
            trackingContentTarget='Close'
            displayBackButton={displayBackButton}
            backButtonText={t('dashboard.authentication.profile.backToProfile')}
            onBackButtonClick={() => setProfileModalType('profile')}
          />
        )}
        {displayCloseButton && (
          <ModalCloseButton top='0' right='0' mt='10' mr='10' />
        )}
        <ModalBody p='10'>
          <VStack
            alignItems='flex-start'
            gap='2.5rem'
            overflowY='auto'
            maxH='100%'
          >
            {chosenModalBody}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
