import {
  Box,
  Button,
  Stack,
  Text,
  HStack,
  DrawerBody,
  DrawerHeader,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrightnessFilterMenuItem } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter/BrightnessFilterMenuItem';
import { MobileFilter } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileFilter';
import { FilterSkeleton } from '$/components/core/Collection/CollectionFilter/FilterSkeleton';
import { ColorRepresentationSelection } from '$/components/core/Editor/ColorSelectionSidebar/ColorRepresentationSelection';
import { FanPageSelector } from '$/components/core/Editor/ColorSelectionSidebar/FanPageSelector';
import { FavoriteFolderList } from '$/components/core/Editor/ColorSelectionSidebar/Favorites/FavoriteFolderList';
import { CategorySelection } from '$/components/core/Editor/ColorSelectionSidebar/Filter/CategorySelection';
import { AppliedMaterial } from '$/components/core/Editor/Mobile/components/AppliedMaterial';
import { SelectedComponent } from '$/components/core/Editor/Mobile/components/SelectedComponent';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { useSwipeHook } from '$/hooks/useSwipeHook';
import { FilterType, MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  type: FilterType;
  material?: Material | null;
  materialTypes?: MaterialType[];
  onSelectMaterial: (material: Material) => void;
  closeFilterDrawer: () => void;
  setSubFilter: (filterSearchName: string) => void;
  mode: 'inspiration' | 'editor';
  onBack?: () => void;
}

export const EditorFilterMainPage: FC<Props> = ({
  type,
  material,
  materialTypes,
  onSelectMaterial,
  closeFilterDrawer,
  setSubFilter,
  mode,
  onBack,
}) => {
  const { t } = useTranslation();

  const possibleFilter = useCollectionStore.useFilter();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();
  const filterGroups = useCollectionStore.useFilterGroups();
  const resetFilters = useCollectionStore.useResetFilter();

  const [isLoading, setIsLoading] = useState(false);
  const [brightness, setBrightness] = useState([
    brightnessFilter.from,
    brightnessFilter.to,
  ]);

  const isAnyFilterActive =
    filterGroups.length > 0 ||
    brightnessFilter.from !== 0 ||
    brightnessFilter.to !== 100;

  const swipeEvents = useSwipeHook({
    onSwipeVertical: (direction) => direction === 'down' && closeFilterDrawer(),
  });

  const getFilterPlaceholder = (filterSearchName: string) => {
    switch (filterSearchName) {
      case 'producer':
        return t('inspirationEditor.filter.producerDefault');
      case 'collection':
        return t('inspirationEditor.filter.collectionDefault');
      default:
        return undefined;
    }
  };

  const filters = [
    ...(possibleFilter?.general ?? []),
    ...(possibleFilter?.additional ?? []),
  ];

  return (
    <Box>
      <DrawerHeader px='3' pt='5' {...swipeEvents}>
        <HStack alignItems='baseline' justify='space-between'>
          <Text pl='3' fontSize='md'>
            {t('general_editor.colorSelection')}
          </Text>
          <Button
            h='36px'
            fontSize='sm'
            onClick={() => closeFilterDrawer()}
            variant='text'
          >
            {t('general.cancel')}
          </Button>
        </HStack>
      </DrawerHeader>

      <DrawerBody px='3'>
        <Stack gap='3'>
          {mode === 'editor' && onBack && (
            <>
              <Box px='3'>
                <SelectedComponent onClick={onBack} />
              </Box>
              <SidebarDivider />
            </>
          )}

          {material && (
            <Box px='3'>
              <AppliedMaterial material={material} />
            </Box>
          )}

          {material && material.type.includes('uni') && (
            <Box px='3' py='2'>
              <FanPageSelector
                material={material}
                onColorChange={onSelectMaterial}
              />
            </Box>
          )}

          <SidebarDivider pb='3' />

          <Box px='2'>
            <CategorySelection
              materialTypes={materialTypes}
              initialMode={type as MaterialType}
            />
          </Box>

          {filters.map((filter) => (
            <Stack key={filter.searchName}>
              <Box px='3' py='2'>
                <Text color='lighterText' fontSize='sm' fontWeight='bold'>
                  {t(filter.displayNameKey)}
                </Text>

                {!isLoading && (
                  <MobileFilter
                    filterSearchName={filter.searchName}
                    onSingleFilterSelected={setSubFilter}
                    setIsLoading={setIsLoading}
                    placeholder={getFilterPlaceholder(filter.searchName)}
                  />
                )}

                {isLoading && (
                  <FilterSkeleton title={t(filter.displayNameKey)} />
                )}
              </Box>
              <SidebarDivider />
            </Stack>
          ))}

          {type === 'uni' && (
            <>
              <Box w='full' px='3' py='2'>
                <Text color='lighterText' fontSize='sm' fontWeight='bold'>
                  {t('dashboard.collection.filter.brightness')}
                </Text>

                <BrightnessFilterMenuItem
                  value={brightness}
                  setValue={setBrightness}
                  p='0'
                />
              </Box>
              <SidebarDivider />
            </>
          )}

          <Box px='3'>
            <ColorRepresentationSelection
              material={material}
              onColorChange={onSelectMaterial}
              columnCount={11}
              columnHeight='150px'
              isCollapsible={false}
              materialTypes={material?.type}
            />
          </Box>

          <SidebarDivider />

          <Box px='3'>
            <FavoriteFolderList />
          </Box>

          <SidebarDivider />

          <Button
            h='32px'
            fontSize='sm'
            isDisabled={!isAnyFilterActive}
            isLoading={isLoading}
            onClick={resetFilters}
            variant='text'
          >
            {t('dashboard.collection.resetFilter')}
          </Button>
        </Stack>
      </DrawerBody>
    </Box>
  );
};
