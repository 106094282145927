import { Accordion, AccordionItem, Stack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccordionContent } from '$/components/core/Collection/CollectionFilter/components/AdditionalFilter/components/AccordionContent';
import { AccordionHeader } from '$/components/core/Collection/CollectionFilter/components/AdditionalFilter/components/AccordionHeader';
import { ActiveFilterText } from '$/components/core/Collection/CollectionFilter/components/AdditionalFilter/components/ActiveFilterText';
import { FilterActions } from '$/components/core/Collection/CollectionFilter/components/FilterActions';
import { FilterGroup, FilterGroupOption } from '$/services/usecases/filter';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  onMenuClose: VoidFunction;
  additionalFilter: FilterGroupOption[];
  hideActions?: boolean;
  compactMode?: boolean;
}

export const AdditionalFilter: FC<Props> = ({
  onMenuClose,
  additionalFilter,
  hideActions = false,
  compactMode = false,
}) => {
  const { t } = useTranslation();

  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();
  const resetFilterGroup = useCollectionStore.useResetFilterGroup();
  const getActiveInCategory = useCollectionStore.useGetActiveInCategory();
  const isAnyCategoryActive = useCollectionStore.useIsAnyCategoryActive();

  const [activeChanges, setActiveChanges] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [loadingItem, setLoadingItem] = useState('');

  const filterTypes = additionalFilter.map((filter) => filter.searchName);

  const handleCheckboxToggle = async (group: FilterGroup) => {
    setActiveChanges(true);
    setLoadingItem(group.option);
    await activateFilterGroup(group, true).finally(() => setLoadingItem(''));
  };

  const handleFilterReset = async () => {
    setIsActionLoading(true);
    await resetFilterGroup(filterTypes).finally(() =>
      setIsActionLoading(false),
    );
    onMenuClose();
  };

  const handleFilterSubmit = () => {
    onMenuClose();
  };

  return (
    <Stack pb={compactMode ? '0' : '5'}>
      <Accordion minW={compactMode ? '190px' : '23rem'} allowToggle>
        {additionalFilter.map((filter) => {
          const activeFilters = getActiveInCategory(filter.searchName);

          return (
            <AccordionItem
              key={filter.searchName}
              border={compactMode ? 'none' : undefined}
              borderColor='border'
            >
              {({ isExpanded }) => (
                <>
                  <AccordionHeader
                    isExpanded={isExpanded}
                    label={t(filter.displayNameKey)}
                    compactMode={compactMode}
                  />
                  <AccordionContent
                    filter={filter}
                    loadingItem={loadingItem}
                    onToggle={handleCheckboxToggle}
                    compactMode={compactMode}
                  />
                  <ActiveFilterText
                    isExpanded={isExpanded}
                    activeFilter={activeFilters}
                    compactMode={compactMode}
                  />
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>

      {!hideActions && (
        <FilterActions
          anyFilterActive={isAnyCategoryActive(filterTypes)}
          activeChanges={activeChanges}
          isLoading={isActionLoading}
          onSubmit={handleFilterSubmit}
          onReset={handleFilterReset}
          px='4'
          py='5'
        />
      )}
    </Stack>
  );
};
