import { FC, useState } from 'react';

import { FilterActions } from '$/components/core/Collection/CollectionFilter/components/FilterActions';
import { MultiSelectionMenuItem } from '$/components/core/Collection/CollectionFilter/components/MultiSelectionMenuItem';
import { FilterGroup } from '$/services/usecases/filter';
import { FilterOption } from '$/services/usecases/filter/mapper/searchValues';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  type: string;
  activeOptions: FilterOption;
  onMenuClose: () => void;
  hideAmounts?: boolean;
  hideActions?: boolean;
  compactMode?: boolean;
}

export const MultiFilter: FC<Props> = ({
  type,
  activeOptions,
  hideAmounts = false,
  hideActions = false,
  compactMode = false,
  onMenuClose,
}) => {
  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();
  const isFilterActive = useCollectionStore.useIsFilterActive();
  const getActiveInCategory = useCollectionStore.useGetActiveInCategory();
  const resetFilterGroup = useCollectionStore.useResetFilterGroup();

  const [activeChanges, setActiveChanges] = useState(false);
  const [loadingItem, setLoadingItem] = useState('');
  const [isActionLoading, setIsActionLoading] = useState(false);

  const handleCheckboxToggle = async (group: FilterGroup) => {
    setActiveChanges(true);
    setLoadingItem(group.option);
    await activateFilterGroup(group, true).finally(() => setLoadingItem(''));
  };

  const handleFilterReset = async () => {
    setIsActionLoading(true);
    await resetFilterGroup([type]).finally(() => setIsActionLoading(false));
  };

  return (
    <>
      {activeOptions.map((option) => (
        <MultiSelectionMenuItem
          key={option.intName}
          id={option.intName}
          color={option.payload}
          name={option.displayName}
          isChecked={isFilterActive(type, option.intName)}
          amount={hideAmounts ? undefined : option.amount}
          onChange={(id) =>
            handleCheckboxToggle({
              option: id,
              displayName: option.displayName,
              type: option.searchName,
              payload: option.payload,
            })
          }
          isLoading={loadingItem === option.intName}
          compactMode={compactMode}
        />
      ))}

      {!hideActions && (
        <FilterActions
          anyFilterActive={getActiveInCategory(type).length > 0}
          activeChanges={activeChanges}
          isLoading={isActionLoading}
          onSubmit={() => {
            onMenuClose();
          }}
          onReset={async () => {
            await handleFilterReset();
            onMenuClose();
          }}
          px='3'
          py='5'
        />
      )}
    </>
  );
};
