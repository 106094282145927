import { Heading, HStack } from '@chakra-ui/react';
import { FC } from 'react';

import { InfoTooltip } from '$/components/common/InfoTooltip';

interface Props {
  header: string;
  info?: string;
}

export const TooltipHeader: FC<Props> = ({ header, info }) => {
  return (
    <HStack gap='3'>
      <Heading as='h1' fontSize={{ base: 'lg', mobile: '3xl' }}>
        {header}
      </Heading>
      {info && <InfoTooltip info={info} />}
    </HStack>
  );
};
