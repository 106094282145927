import {
  AbsoluteCenter,
  Image,
  ImageProps,
  Skeleton,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';

import { Layer } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { buildRenderedInspirationImageUrl } from '$/services/endpoints';

interface Props extends Omit<ImageProps, 'src'> {
  sceneId: string;
  layers: Layer[];
  view?: number;
  resolution?: { width: number; height: number };
  setIsImageLoading?: (isLoading: boolean) => void;
  isImageLoading?: boolean;
  isAutoWidth?: boolean;
  maxFullscreenHeight?: string;
  maxFullscreenWidth?: string;
  skipLoadingIndicator?: boolean;
  onInitialLoad?: () => void;
}

export const RenderedInspirationImage: FC<Props> = ({
  sceneId,
  layers,
  view,
  resolution,
  setIsImageLoading,
  isImageLoading,
  onInitialLoad,
  isAutoWidth = false,
  skipLoadingIndicator = false,
  ...imageProps
}) => {
  const [hasLoadedInitialImage, setHasLoadedInitialImage] = useState(false);
  const [isImageLoadingLocal, setIsImageLoadingLocal] = useState(true);
  const [isVerticalImage, setIsVerticalImage] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  const imageUrl = buildRenderedInspirationImageUrl({
    sceneId,
    layers,
    view,
    resolution,
  });

  useEffect(() => {
    if (setIsImageLoading) setIsImageLoading(true);
    else setIsImageLoadingLocal(true);
  }, [imageUrl, setIsImageLoading]);

  const isLoading = isImageLoading ?? isImageLoadingLocal;

  return (
    <Flex pos='relative' zIndex='-1' align='center' justify='center'>
      {isLoading && (
        <AbsoluteCenter w='full'>
          {!hasLoadedInitialImage && (
            <Skeleton
              w={imageProps.w ?? imageProps.width ?? 'full'}
              opacity='1'
              borderRadius={imageProps.borderRadius}
              aspectRatio='303/220'
            />
          )}
          {!skipLoadingIndicator && hasLoadedInitialImage && (
            <AbsoluteCenter>
              <Spinner
                w='48px'
                h='48px'
                emptyColor='neutral.1000/30'
                thickness='4px'
              />
            </AbsoluteCenter>
          )}
        </AbsoluteCenter>
      )}
      <Image
        ref={imageRef}
        w={isAutoWidth || isVerticalImage ? 'auto' : '60vw'}
        h={isVerticalImage ? '90vh' : 'auto'}
        transition='width 0.5s ease, height 0.5s ease'
        onLoad={(e) => {
          const isLoaded = () => {
            (setIsImageLoading ?? setIsImageLoadingLocal)(false);
            if (!hasLoadedInitialImage) {
              onInitialLoad?.();
              setHasLoadedInitialImage(true);
            }
          };

          setIsVerticalImage(e.currentTarget.height > e.currentTarget.width);

          setTimeout(isLoaded, 300);
        }}
        src={imageUrl}
        {...imageProps}
      />
    </Flex>
  );
};
