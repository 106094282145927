import { Input } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';

import { RGBA } from '$/utils/colorUtils';

interface Props {
  color: RGBA;
  setColor: (color: RGBA) => void;
  rgbToValConverter: (color: RGBA) => string;
  valToRgbConverter: (value: string) => RGBA;
  isValidColor: (value: string) => boolean;
}

export const SingleColorInput: FC<Props> = ({
  color,
  setColor,
  rgbToValConverter,
  valToRgbConverter,
  isValidColor,
}) => {
  const [input, setInput] = useState<string>(rgbToValConverter(color));

  useEffect(
    () => setInput(rgbToValConverter(color)),
    [color, rgbToValConverter],
  );

  const persist = (value: string) => {
    setInput(value);
    if (isValidColor(value)) setColor(valToRgbConverter(value));
  };

  return (
    <Input
      w='full'
      h='32px'
      px='8px'
      borderColor={
        isValidColor(input) ? undefined : 'form.errorBorder !important'
      }
      _active={{}}
      _focus={{}}
      onChange={(e) => persist(e.currentTarget.value)}
      value={input}
    />
  );
};
