import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { queryClient, STANDARD_STALE_TIME } from '$/services/fetcher';
import {
  getFandeckInfo,
  getFandeckPageCount,
} from '$/services/usecases/fandeck';
import { materialsQuery } from '$/services/usecases/materials/queries';

export const fandeckMaterialsQuery = (collection: string, page: number) =>
  queryOptions({
    queryKey: ['fandeck', collection, page],
    queryFn: async ({ signal }) => {
      const ids = await getFandeckInfo({ collection, page, signal });
      return queryClient.fetchQuery(materialsQuery({ ids }));
    },
    staleTime: STANDARD_STALE_TIME,
  });

export const fandeckPageCountQuery = (collection: string) =>
  queryOptions({
    queryKey: ['fandeckPageCount', collection],
    queryFn: ({ signal }) => getFandeckPageCount({ collection, signal }),
    placeholderData: keepPreviousData,
    staleTime: STANDARD_STALE_TIME,
  });
