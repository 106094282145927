import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { DropdownItem } from '$/components/common/Dropdown/DropdownItem';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { Material } from '$/services/usecases/materials';
import { escapeStringForRegex } from '$/utils/regexUtils';

const highlightResult = (name: string, query: string) => {
  const regex = new RegExp(`(.*)(${escapeStringForRegex(query)})(.*)`, 'gmi');
  const match = RegExp(regex).exec(name);
  return (
    <>
      {match?.[1]}
      <Box as='span' fontWeight='bold'>
        {match?.[2]}
      </Box>
      {match?.[3]}
    </>
  );
};

interface Props {
  material: Material;
  onClick?: (material: Material) => void;
  isMobile?: boolean;
}

export const SearchResultItem: FC<Props> = ({
  material,
  onClick,
  isMobile = false,
}) => {
  const query = useMaterialSearchStore.useQuery();
  const toggleDropdown = useMaterialSearchStore.useToggleDropdown();

  const handleItemClick = () => {
    onClick && onClick(material);
    toggleDropdown(false);
  };

  const bg = !material.url() ? material.color ?? 'transparent' : 'transparent';

  if (isMobile) {
    return (
      <DropdownItem onClick={handleItemClick} py='1px' cursor='pointer'>
        <HStack gap='3' px='1'>
          <Box
            boxSize='10'
            bg={bg}
            bgImage={material.url({ width: '30', height: '30' })}
            borderRadius='2px'
          />
          <VStack align='start' justify='center' gap='0'>
            <Text fontSize='sm'>{highlightResult(material.info, query)}</Text>
            <Text color='lighterText' fontSize='xs'>
              {material.collection}
            </Text>
          </VStack>
        </HStack>
      </DropdownItem>
    );
  }

  return (
    <DropdownItem onClick={handleItemClick} px='5' py='1px' cursor='pointer'>
      <HStack px='1'>
        <Box
          w='20px'
          h='20px'
          bg={bg}
          bgImage={material.url({ width: '30', height: '30' })}
          borderRadius='4px'
        />
        <Text color='lighterText' fontSize='sm'>
          {highlightResult(material.info, query)}
        </Text>
      </HStack>
    </DropdownItem>
  );
};
