import { Stack } from '@chakra-ui/react';
import { FC } from 'react';

import { MaterialPresentation } from '$/components/core/Collection/MaterialPresentation';
import { useMaterialFullscreenStore } from '$/components/core/Editor/MaterialFullscreen/store/useMaterialFullscreenStore';
import { Material } from '$/services/usecases/materials';

interface Props {
  onSelect: (material: Material) => void;
}

export const MaterialSelectionModal: FC<Props> = ({ onSelect }) => {
  const query = useMaterialFullscreenStore.useQuery();
  const modalPayload = useMaterialFullscreenStore.useModalPayload();

  return (
    <Stack>
      <MaterialPresentation
        type={modalPayload.materialType!}
        onSelect={onSelect}
        query={query}
        hideFavoriteButton
        isFullscreenMode
        hideFilterTags
      />
    </Stack>
  );
};
