import {
  FormControl,
  FormControlProps,
  Checkbox,
  CheckboxProps,
  Text,
} from '@chakra-ui/react';
import get from 'lodash/get';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';

export interface HookFormCheckboxProps<T extends FieldValues>
  extends CheckboxProps {
  accessor: FieldPath<T>;
  label?: string;
  registerOptions?: RegisterOptions<T>;
  formControlProps?: FormControlProps;
  customLabelContent?: JSX.Element;
}

export const HookFormCheckbox = <T extends FieldValues>({
  label,
  accessor,
  registerOptions,
  formControlProps,
  customLabelContent,
  ...checkboxProps
}: HookFormCheckboxProps<T>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();

  return (
    <FormControl
      borderColor='form.inputBorder'
      isInvalid={get(errors, accessor) != null}
      {...formControlProps}
    >
      <Checkbox
        alignItems='flex-start'
        colorScheme='primaryBlue'
        iconColor='iconColor'
        id={accessor}
        {...register(accessor, registerOptions)}
        {...checkboxProps}
      >
        {customLabelContent ?? (
          <Text mt='-1' fontSize='sm'>
            {label}
          </Text>
        )}
      </Checkbox>
    </FormControl>
  );
};
