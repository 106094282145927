import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardContent,
  CardContentGridProps,
} from '$/components/common/Card/CardContent';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import {
  MaterialCardContextMenu,
  MaterialCardEditMode,
} from '$/components/core/Collection/MaterialCard/MaterialCardContextMenu';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';

interface Props extends CardContentGridProps {
  material: Material;
  editMode?: MaterialCardEditMode;
  materialType?: MaterialType;
  labelOverwrite?: string;
}

export const MaterialCard: FC<Props> = ({
  material,
  labelOverwrite,
  editMode = 'favorite',
  materialType,
  ...props
}) => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();

  const cursor = props.onClick != null ? 'pointer' : 'default';
  const bg = !material.url() ? material.color ?? 'transparent' : 'transparent';
  const label = labelOverwrite ?? material.info;

  const showProLabel =
    material.isProMaterial && !hasPermission('Use_Pro_Materials');

  return (
    <Box pos='relative' overflow='hidden' cursor={cursor}>
      <MaterialCardContextMenu
        editMode={editMode}
        material={material}
        materialType={materialType}
      />

      {showProLabel && (
        <Flex
          pos='absolute'
          top='7%'
          left='-40%'
          justify='center'
          w='100%'
          bg='primaryButton.background'
          transform='rotate(-45deg)'
          userSelect='none'
        >
          <Text color='reverseText' fontSize='xs' fontWeight='bold'>
            {t('roles.proLabel')}
          </Text>
        </Flex>
      )}

      <CardContent
        bg={bg}
        label={label}
        image={material.url({ width: '300', height: '300' })}
        brightness={material.brightness}
        {...props}
      />
    </Box>
  );
};
