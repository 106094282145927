import { FC } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { MobileDrawer } from '$/components/common/Drawer/MobileDrawer';
import { EditorFilterDrawerContent } from '$/components/core/Editor/Mobile/components/Filter/EditorFilterDrawerContent';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { FilterType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { intersect } from '$/utils/arrayUtils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const InspirationMobileFilterDrawer: FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const changeComponentMaterial =
    useInspirationEditorStore.useChangeComponentMaterial();

  const { activeComponent } = useInspirationEditorStore(
    useShallow((s) => ({ activeComponent: s.getActiveComponent() })),
  );

  const material = activeComponent?.material;
  const componentTypes = activeComponent?.materialTypes ?? [];
  const initialMode = intersect(componentTypes, material?.type ?? [])[0];

  const onSelectMaterial = (material: Material) => {
    changeComponentMaterial(material);
    onClose();
  };

  return (
    <MobileDrawer isOpen={isOpen} onClose={onClose}>
      <EditorFilterDrawerContent
        initialType={initialMode as FilterType}
        onClose={onClose}
        isOpen={isOpen}
        onSelectMaterial={onSelectMaterial}
        material={material}
        materialTypes={componentTypes}
        mode='inspiration'
      />
    </MobileDrawer>
  );
};
