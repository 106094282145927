import { z } from 'zod';

import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';
import { InspirationProjectLayerSchema } from '$/services/usecases/projects/mapper/inspirationProjects';

const ProjectOverviewItemSchema = z
  .discriminatedUnion('type', [
    z.object({
      type: z.literal('editor'),
      id: z.string(),
      projectName: z.string(),
      isPublic: z.boolean(),
      token: z.string().nullable(),
      projectIds: z.string().array(),
      projectThumbnail: z.string().url().optional(),
    }),
    z.object({
      type: z.literal('inspiration'),
      id: z.string(),
      projectName: z.string(),
      isPublic: z.boolean(),
      sceneId: z.string(),
      variants: InspirationProjectLayerSchema.array().array(),
      projectThumbnail: z.string().url().optional(),
    }),
  ])
  .transform((data) => {
    return {
      id: data.id,
      name: data.projectName,
      isPublic: data.isPublic,
      thumbnailUrl: data.projectThumbnail,
      type: data.type,
    };
  });

export type ProjectOverviewItem = z.infer<typeof ProjectOverviewItemSchema>;

export const GetProjectOverviewResponseSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: ProjectOverviewItemSchema.array(),
});

export const CreateEditorProjectResponseSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: z.object({
    projectId: z.string(),
  }),
});

export const EditorProjectItemSchema = z.object({
  id: z.string(),
  userId: z.string(),
  projectIds: z.string().array(),
  projectName: z.string(),
  isPublic: z.boolean(),
  token: z.string().nullable(),
  type: z.literal('editor'),
});

export type EditorProject = z.infer<typeof EditorProjectItemSchema>;

export const GetEditorProjectResponseSchema = z.object({
  ...GeneralBackendResponseSchema,
  payload: EditorProjectItemSchema.optional(),
});
