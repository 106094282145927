import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const standard = definePartsStyle({
  field: {
    bg: 'form.inputBackground',
    border: '1px solid',
    borderColor: 'form.inputBorder',
    borderRadius: '4px',
    shadow: 'none !important',
    _focus: {
      bg: 'secondaryBackground',
      border: '2px solid',
      borderColor: 'form.activeBorder',
    },
    _active: {
      bg: 'secondaryBackground',
      border: '2px solid',
      borderColor: 'form.activeBorder',
    },
    _placeholder: { color: 'placeholderText', opacity: 1 },
    _readOnly: { background: 'transparent' },
  },
});

export const Input = defineMultiStyleConfig({
  defaultProps: {
    variant: 'standard',
  },
  variants: { standard },
});
