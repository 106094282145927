import { Button, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProListItem } from '$/components/core/Editor/MaterialFullscreen/component/FilterSidebar/components/ProListItem';
import { FilterGroupOption } from '$/services/usecases/filter';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  filter: FilterGroupOption;
}

export const ListFilter: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();
  const isFilterActive = useCollectionStore.useIsFilterActive();

  return (
    <Stack align='flex-start' gap='3' overflowY='auto' maxH='200px'>
      {filter.items.map((item) => {
        const key = item.intName;
        const isActive = isFilterActive(filter.searchName, item.intName);

        return (
          <Button
            key={key}
            justifyContent='flex-start'
            w='full'
            h='4'
            p='0'
            onClick={async () => {
              await activateFilterGroup(
                {
                  type: item.searchName,
                  option: item.intName,
                  displayName: item.displayName,
                  payload: item.payload,
                },
                filter.selectionFilter === 'multi',
              );
            }}
            variant='ghost'
          >
            <Text fontSize='xs' fontWeight={isActive ? 'bold' : 'normal'}>
              {item.displayName}
            </Text>
          </Button>
        );
      })}
      {(filter.searchName === 'collection' ||
        filter.searchName === 'producer') && (
        <ProListItem name={t(filter.displayNameKey)} />
      )}
    </Stack>
  );
};
