import { Box, Button, Text } from '@chakra-ui/react';

import { t } from '$/components/core/Localization/18n';
import { useToast } from '$/hooks/useToast';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { EditorActions } from '$/pages/EditorPage/hooks/useEditorActions';

export const rasteringToast = (
  toolName: string,
  selectAction: EditorActions['selectAction'],
  toast: ReturnType<typeof useToast>,
) => {
  toast(t('editor.warning'), 'warning', '', {
    customContent: (
      <Box>
        <Text fontSize='xs' lineHeight='1.2'>
          {t('editor.vectorWarning', { tool: toolName })}
        </Text>
        <Button
          mt='2'
          px='0'
          fontSize='sm'
          onClick={() => selectAction(ModuleSendSelectAction.SelectPoly)}
          variant='text'
        >
          {t('editor.cancel')}
        </Button>
      </Box>
    ),
  });
};

// const newElementToast = (
//   toast: ReturnType<typeof useToast>,
//   t: ReturnType<typeof useTranslation>['t'],
// ) => {
//   toast(
//     t('editor.newElementCreatedToastTitle'),
//     'info',
//     t('editor.newElementCreatedToastContent'),
//   );
// };
