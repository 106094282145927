import { AttributeSidebar } from '$/components/core/Editor/AttributeSidebar';
import { AttributeSidebarContent } from '$/pages/EditorPage/components/AttributesSection/components/AttributeSidebarContent';

export const AttributesSection = () => {
  return (
    <AttributeSidebar>
      <AttributeSidebarContent />
    </AttributeSidebar>
  );
};
