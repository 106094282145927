import { ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ModalHeaderWithBackButton } from '$/components/common/Modals/ModalHeaderWithBackButton';
import { RegisterForm } from '$/components/core/Authentication/components/RegisterForm';

export const RegisterModalContent = () => {
  const { t } = useTranslation();

  return (
    <ModalContent maxW='500px' bg='background' borderRadius='12px'>
      <ModalHeaderWithBackButton
        headerText={t('dashboard.header.createAccount')}
      />
      <ModalCloseButton top='0' right='0' mt='8' mr='8' />
      <ModalBody mt='8' px='10' pb='10'>
        <RegisterForm />
      </ModalBody>
    </ModalContent>
  );
};
