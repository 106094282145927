import {
  Button,
  HStack,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useKeyPress } from '$/hooks/useKeyPress';
import { useToast } from '$/hooks/useToast';
import {
  resendVerificationOtpMail,
  verifyUser,
} from '$/services/usecases/authentication';

export const RegisterOtpForm = () => {
  const { t } = useTranslation();
  const otpEmail = useAuthenticationStore.useOptEmail();
  const setModalType = useAuthenticationStore.useSetModalType();
  const toast = useToast();
  const [otp, setOtp] = useState<string>('');
  const { cloudMessageTranslation } = useCloudMessageTranslation();

  const onSubmit = async () => {
    if (otp.length < 6) return;
    const { isSuccessful } = await verifyUser(otpEmail, otp);
    if (isSuccessful) {
      setModalType('registerSuccess');
    } else {
      toast(
        t('dashboard.authentication.verifyEmail.errorToastHeader'),
        'error',
        t('dashboard.authentication.verifyEmail.errorToastDescription'),
      );
    }
  };

  const onResendCode = async () => {
    const { isSuccessful, response } =
      await resendVerificationOtpMail(otpEmail);

    if (isSuccessful) {
      toast(
        t('dashboard.authentication.verifyEmail.resendToastHeader'),
        'success',
        t('dashboard.authentication.verifyEmail.resendToastDescription'),
      );
    } else {
      toast(
        t('dashboard.authentication.verifyEmail.errorToastHeader'),
        'error',
        cloudMessageTranslation(response.message),
      );
    }
  };

  useKeyPress(({ key }) => {
    if (key === 'Enter' && otp.length === 6) {
      void onSubmit();
    }
  });

  return (
    <VStack gap='8'>
      <Text fontSize='sm'>
        {t('dashboard.authentication.verifyEmail.description')}
      </Text>
      <HStack>
        <PinInput onChange={setOtp} value={otp}>
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      <HStack justify='space-between' w='full'>
        <Button onClick={onResendCode} variant='text'>
          {t('dashboard.authentication.verifyEmail.resendButton')}
        </Button>
        <Button isDisabled={otp.length < 6} onClick={onSubmit}>
          {t('dashboard.authentication.verifyEmail.submitButton')}
        </Button>
      </HStack>
    </VStack>
  );
};
