import { Button, HStack, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

interface Props {
  label: string;
  onClick: () => void;
  drawerMode?: boolean;
}

export const SidebarBackButton: FC<Props> = ({
  onClick,
  label,
  drawerMode = false,
}) => {
  const { t } = useTranslation();

  return (
    <HStack justify='space-between' w='full'>
      <HStack
        justifyContent='flex-start'
        gap='3'
        px='4'
        py='2'
        cursor='pointer'
        onClick={onClick}
      >
        <Icon
          icon='arrow_right'
          transform='rotate(180deg)'
          color='text'
          w='14px'
          h='32px'
        />
        <Heading as='h2' fontSize={drawerMode ? 'md' : 'xs'} noOfLines={1}>
          {label}
        </Heading>
      </HStack>
      {drawerMode && (
        <Button
          h='36px'
          fontSize='sm'
          onClick={() => useFavoriteStore.getState().setActiveFolderId(null)}
          variant='text'
        >
          {t('general.cancel')}
        </Button>
      )}
    </HStack>
  );
};
