import { MaterialFullscreenModal } from '$/components/core/Editor/MaterialFullscreen/MaterialFullscreenModal';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export const InspirationFullscreenModal = () => {
  const changeComponentMaterial =
    useInspirationEditorStore.useChangeComponentMaterial();

  const activeComponent = useInspirationEditorStore((state) =>
    state.getActiveComponent(),
  );

  return (
    <MaterialFullscreenModal
      activeMaterial={activeComponent?.material}
      selectMaterial={(m) => changeComponentMaterial(m)}
      materialTypes={activeComponent?.materialTypes}
    />
  );
};
