import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

export type LanguageOptions = 'de' | 'en';

export type State = {
  selectedLanguage: LanguageOptions;
};

type Actions = {
  setSelectedLanguage: (selectedLanguage: LanguageOptions) => void;
};

export const initial: State = {
  selectedLanguage:
    (localStorage.getItem('userLanguage') as LanguageOptions) ?? 'de',
};

export const store = create<State & Actions>()((set) => ({
  ...initial,
  setSelectedLanguage: (selectedLanguage) => set({ selectedLanguage }),
}));

export const useGeneralStore = createSelectorHooks(store) as typeof store &
  ZustandHookSelectors<State & Actions>;
