import { Flex, useBreakpointValue, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { OverflowText } from '$/components/common/OverflowText';

interface Props {
  label: string;
  brightness?: number;
}

export const CardTextArea: FC<Props> = ({ label, brightness }) => {
  const textPaddingX = useBreakpointValue(
    {
      base: '0.375rem',
      lg: '0.5rem',
    },
    { ssr: false },
  );

  const textPaddingY = useBreakpointValue(
    {
      base: '4px',
      lg: '0.5rem',
    },
    { ssr: false },
  );

  return (
    <Flex
      align='center'
      justify='center'
      gap='3'
      px={textPaddingX}
      py={textPaddingY}
      bg='background'
      borderTop='1px solid'
      borderTopColor='border'
    >
      <OverflowText color='text' fontSize='sm'>
        {label}
      </OverflowText>
      {brightness && brightness != -1 && (
        <Flex align='center' gap='1' color='text'>
          <Icon w='14px' icon='brightness' />
          <Text fontSize='sm'>{brightness}</Text>
        </Flex>
      )}
    </Flex>
  );
};
