import { Box, HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SliderInput } from '$/pages/EditorPage/components/AttributesSection/components/BrushSizeInput';
import { CurrentColorSection } from '$/pages/EditorPage/components/AttributesSection/components/CurrentColorSection';
import { MagicWandModeOptionButton } from '$/pages/EditorPage/components/AttributesSection/components/MagicWandModeOptionButton';
import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { SidebarDivider } from '$/pages/EditorPage/components/AttributesSection/components/SidebarDivider';
import {
  Tip,
  TipSection,
} from '$/pages/EditorPage/components/AttributesSection/components/TipSection';
import { TitleSection } from '$/pages/EditorPage/components/AttributesSection/components/TitleSection';
import { VisibilityToggle } from '$/pages/EditorPage/components/AttributesSection/components/VisibilityToggle';
import { VisualizationSection } from '$/pages/EditorPage/components/AttributesSection/components/VisualizationSection';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const MagicWandSidebar = () => {
  const { t } = useTranslation();
  const magicWandTolerance = useEditorStore.useMagicWandTolerance();
  const magicWandCircumference = useEditorStore.useMagicWandCircumference();
  const { setMagicWandCircumference, setMagicWandTolerance } =
    useEditorActions();

  const tips: Tip[] = [
    {
      tipHeader: t('editor.tips.magicWandUseCase.title'),
      tipContent: t('editor.tips.magicWandUseCase.content'),
    },
    {
      tipHeader: t('editor.tips.magicWandQuickHelp.title'),
      tipContent: t('editor.tips.magicWandQuickHelp.content'),
    },
  ];

  return (
    <VStack w='full' fontSize='sm'>
      <TitleSection title={t('editor.magicWand.title')} />
      <SidebarDivider />
      <CurrentColorSection />
      <VisualizationSection />
      <SidebarDivider />
      <VStack align='flex-start' w='full' px='4'>
        <SectionTitle icon='magic_wand' title={t('editor.magicWand.mode')} />
        <HStack w='full' my='1'>
          <MagicWandModeOptionButton mode='new' iconBoxSize='22px' />
          <MagicWandModeOptionButton mode='add' />
          <MagicWandModeOptionButton mode='sub' />
        </HStack>
      </VStack>
      <SidebarDivider />
      <VStack align='flex-start' w='full' px='4'>
        <SectionTitle title={t('editor.magicWand.tolerance')} />
        <SliderInput
          initialValue={magicWandTolerance}
          minValue={0}
          maxValue={100}
          onChange={(val) => setMagicWandTolerance(val)}
        />
      </VStack>
      <SidebarDivider />
      <VStack align='flex-start' w='full' px='4'>
        <SectionTitle icon='magic_wand' title={t('editor.magicWand.size')} />
        <SliderInput
          initialValue={magicWandCircumference}
          minValue={1}
          maxValue={10}
          onChange={(val) => setMagicWandCircumference(val)}
        />
      </VStack>
      <SidebarDivider />
      <Box mx='4' mt='4' mb='1'>
        <TipSection tips={tips} />
      </Box>
      <VisibilityToggle />
    </VStack>
  );
};
