import { Box, Stack } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { FavoriteFolder } from '$/stores/useFavoriteStore';
import { shortenText } from '$/utils/stringUtils';

interface Props {
  folder: FavoriteFolder;
  onClick?: () => void;
}

export const ReadOnlyFolderCard: FC<Props> = ({ folder, onClick }) => {
  return (
    <Stack
      key={folder.id}
      layerStyle='WelcomeCard'
      pos='relative'
      gap='4'
      bg='bodyBackground'
      cursor='pointer'
      onClick={onClick}
    >
      <Icon icon='folder' boxSize='8' />
      <Box
        maxW='full'
        px='4'
        fontWeight='bold'
        textOverflow='ellipsis'
        title={folder.name}
      >
        {shortenText(folder.name, 45)}
      </Box>
    </Stack>
  );
};
