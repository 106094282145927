import { object, z } from 'zod';

import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';
import { UserSchema } from '$/services/usecases/authentication/mapper/genericCloudResponse';

export const SignInSchema = object({
  ...GeneralBackendResponseSchema,
  payload: object({
    user: UserSchema,
    accessToken: z.string(),
  }).optional(),
}).transform((data) => ({
  message: data.message,
  user: data.payload?.user,
  accessToken: data.payload?.accessToken,
}));

export type SignInResponse = z.infer<typeof SignInSchema>;
