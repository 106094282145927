import { dataEndpointFactory } from '$/services/endpoints/dataEndpoints';
import { imageEndpointFactory } from '$/services/endpoints/imageEndpoints';
import { useGeneralStore } from '$/stores/useGeneralStore';

export type NullableUrlSearchParams = Record<string, string | undefined>;

export const cnf = () => 'spectrum-default';
const getLanguage = () => useGeneralStore.getState().selectedLanguage;

export const config = {
  INTEGRATE_BASE_URL: 'https://integrate.materialo.com/v2.1',
  DAW_INTEGRATE_BASE_URL: 'https://daw_integrate_own_db.aocluster.com/v2.1',
  EDITOR_BASE_URL: 'https://emodule.aocluster.com',
  BACKEND_URL: import.meta.env.VITE_BACKEND_URL,
};

export const editorEndpoints = {
  EDITOR_SRC: `${config.EDITOR_BASE_URL}/emodule_v1_12`,
  EDITOR_IMAGE_UPLOAD: `${config.EDITOR_BASE_URL}/token1`,
};

export const dataEndpoints = {
  SCENES: `${config.DAW_INTEGRATE_BASE_URL}/cust/caparol/get_datalist_scenes.php`,
  SCENE_CATEGORIES: `${config.DAW_INTEGRATE_BASE_URL}/get_datalist_scenes_extinfo.php`,
  MATERIALS: `${config.BACKEND_URL}/veeuze/materials`,
  FANDECK_INFO: `${config.DAW_INTEGRATE_BASE_URL}/cust/daw/get_fandeck_info.php`,
  MATERIAL_SEARCH_VALUES: `${config.BACKEND_URL}/veeuze/filter`,
};

export const imageEndpoints = {
  SCENE_IMAGE_URL: `${config.DAW_INTEGRATE_BASE_URL}/get_img_scene.php`,
  MATERIAL_IMAGE_URL: `${config.DAW_INTEGRATE_BASE_URL}/get_img_material.php`,
  INSPIRATION_RENDER_SCENE: `${config.DAW_INTEGRATE_BASE_URL}/render_img_scene.php`,
  PROJECT_THUMBNAIL: `${config.DAW_INTEGRATE_BASE_URL}/get_img_project.php`,
  RETOUCH_PATTERN: `${config.DAW_INTEGRATE_BASE_URL}/cust/caparol/get_pattern_img.php`,
};

export const backendEndpoints = {
  REGISTER_URL: `${config.BACKEND_URL}/signup`,
  VERIFY_USER_URL: `${config.BACKEND_URL}/signup/verify`,
  RESEND_VERIFICATION_URL: `${config.BACKEND_URL}/signup/verify/resend`,
  SIGN_IN_URL: `${config.BACKEND_URL}/auth/signin`,
  SIGN_IN_REFRESH_URL: `${config.BACKEND_URL}/auth/refresh`,
  UPLOAD_PROFILE_PICTURE: `${config.BACKEND_URL}/account/profilePicture`,
  SIGN_OUT_URL: `${config.BACKEND_URL}/auth/signout`,
  DELETE_ACCOUNT: `${config.BACKEND_URL}/account`,
  RESET_PASSWORD_INITIATE_URL: `${config.BACKEND_URL}/account/resetPassword/initiate`,
  RESET_PASSWORD_FULLFILL_URL: `${config.BACKEND_URL}/account/resetPassword/fullfill`,
  CHANGE_NAME_URL: `${config.BACKEND_URL}/account/name`,
  CHANGE_PASSWORD_URL: `${config.BACKEND_URL}/account/password`,
  CHANGE_EMAIL_URL: `${config.BACKEND_URL}/account/email`,
};

export const projectEndpoints = {
  PROJECT_OVERVIEW_URL: `${config.BACKEND_URL}/projects`,
  SAVE_EDITOR_PROJECT_URL: `${config.BACKEND_URL}/projects/editor`,
  GET_PROJECT_URL: (type: 'editor' | 'inspiration', projectId: string) =>
    `${config.BACKEND_URL}/projects/${type}/${projectId}`,
  ADD_PROJECT_ID_TO_EDITOR_URL: (projectId: string) =>
    `${config.BACKEND_URL}/projects/editor/${projectId}`,
  UPSERT_INSPIRATION_EDITOR_PROJECT_URL: `${config.BACKEND_URL}/projects/inspiration`,
  UPLOAD_PROJECT_THUMBNAIL_URL: (projectId: string) =>
    `${config.BACKEND_URL}/projects/${projectId}/thumbnail`,
  RENAME_PROJECT_URL: (projectId: string) =>
    `${config.BACKEND_URL}/projects/${projectId}/name`,
  DELETE_PROJECT_URL: (projectId: string) =>
    `${config.BACKEND_URL}/projects/${projectId}`,
  CHANGE_PROJECT_VISIBILITY_URL: (projectId: string) =>
    `${config.BACKEND_URL}/projects/${projectId}/visibility`,
};

export const favoritesEndpoints = {
  GET_FAVORITES_URL: `${config.BACKEND_URL}/favorites/material`,
  ADD_FAVORITE_URL: (materialId: string) =>
    `${config.BACKEND_URL}/favorites/material/${materialId}`,
  REMOVE_FAVORITE_URL: (materialId: string) =>
    `${config.BACKEND_URL}/favorites/material/${materialId}`,
  CHANGE_FAVORITE_FOLDERS_URL: (materialId: string) =>
    `${config.BACKEND_URL}/favorites/material/${materialId}`,
  ADD_FAVORITE_FOLDER_URL: `${config.BACKEND_URL}/favorites/folder`,
  REMOVE_FAVORITE_FOLDER_URL: (folderId: string) =>
    `${config.BACKEND_URL}/favorites/folder/${folderId}`,
  UPDATE_FAVORITE_FOLDER_URL: (folderId: string) =>
    `${config.BACKEND_URL}/favorites/folder/${folderId}`,
};

export const legalEndpoints = {
  IMPRINT_URL: `${config.BACKEND_URL}/legal/imprint?lng=${getLanguage()}`,
  PRIVACY_URL: `${config.BACKEND_URL}/legal/privacy?lng=${getLanguage()}`,
  INTEGRITY_URL: `${config.BACKEND_URL}/legal/integrity?lng=${getLanguage()}`,
};

export const endpointFactory = {
  ...dataEndpointFactory,
  ...imageEndpointFactory,
  ...backendEndpoints,
};

export { buildRenderedInspirationImageUrl } from '$/services/endpoints/imageEndpoints';

export const buildUrl = (baseUrl: string, params: NullableUrlSearchParams) => {
  const url = new URL(baseUrl);
  url.search = createUrlSearchParams(params).toString();
  return url.href;
};

const createUrlSearchParams = (
  params: NullableUrlSearchParams,
): URLSearchParams => {
  const response = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value != null) response.append(key, value);
  });

  return response;
};
