import { Box, Fade } from '@chakra-ui/react';
import { FC } from 'react';
import { KeepScale } from 'react-zoom-pan-pinch';

import { useIdleUserHook } from '$/hooks/useIdleUserHook';
import { useIsMobile } from '$/hooks/useIsMobile';
import { ImageMarkerButton } from '$/pages/InspirationEditorPage/components/ImageSection/components/ImageMarkerButton';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props {
  isImageLoading: boolean;
}

export const ImageMarkers: FC<Props> = ({ isImageLoading }) => {
  const layerMarkers = useInspirationEditorStore.useLayerMarkers();
  const perspectiveFullscreen =
    useInspirationEditorStore.usePerspectiveFullscreen();

  const isMobile = useIsMobile();
  const isUserActive = useIdleUserHook();

  return (
    <Fade
      transition={{ enter: { duration: 0.2 }, exit: { duration: 0.5 } }}
      in={isMobile || !isUserActive}
      unmountOnExit
    >
      {layerMarkers.map((marker) => (
        <Box
          key={`${marker.id}`}
          as={KeepScale}
          pos='absolute'
          top={`calc(${marker.position.y}% - 16px)`}
          left={`calc(${marker.position.x}% - 16px)`}
          display={isImageLoading ? 'none' : 'block'}
          opacity={perspectiveFullscreen ? '0' : '1'}
          pointerEvents={perspectiveFullscreen ? 'none' : 'auto'}
          transition={
            perspectiveFullscreen
              ? 'opacity 0.3s ease'
              : 'opacity 0.3s ease 0.5s'
          }
        >
          <ImageMarkerButton markerId={marker.id} />
        </Box>
      ))}
    </Fade>
  );
};
