import { ResponsiveValue } from '@chakra-ui/react';
import { Property } from 'csstype';

export const materialTemplateColumn = (
  isFullscreenMode: boolean = false,
  limit: number = 100,
): ResponsiveValue<Property.GridTemplateColumns> => {
  if (isFullscreenMode) {
    return {
      base: 'repeat(2, minmax(1px, 1fr))',
      md: 'repeat(3, minmax(1px, 1fr))',
    };
  }

  return {
    base: `repeat(${Math.min(2, limit)}, minmax(1px, 1fr))`,
    sm: `repeat(${Math.min(2, limit)}, minmax(1px, 1fr))`,
    md: `repeat(${Math.min(3, limit)}, minmax(1px, 1fr))`,
    lg: `repeat(${Math.min(4, limit)}, minmax(1px, 1fr))`,
    xl: `repeat(${Math.min(5, limit)}, minmax(1px, 1fr))`,
    '2xl': `repeat(${Math.min(6, limit)}, minmax(1px, 1fr))`,
  };
};

export const buttonTemplateColumn = (
  isFullscreenMode: boolean = false,
  limit: number = 100,
): ResponsiveValue<Property.GridTemplateColumns> => {
  if (isFullscreenMode) {
    return {
      base: 'repeat(1, minmax(1px, 1fr))',
      md: 'repeat(3, minmax(1px, 1fr))',
    };
  }

  return {
    base: `repeat(${Math.min(1, limit)}, minmax(1px, 1fr))`,
    mobile: `repeat(${Math.min(2, limit)}, minmax(1px, 1fr))`,
    md: `repeat(${Math.min(3, limit)}, minmax(1px, 1fr))`,
    lg: `repeat(${Math.min(4, limit)}, minmax(1px, 1fr))`,
    xl: `repeat(${Math.min(5, limit)}, minmax(1px, 1fr))`,
    '2xl': `repeat(${Math.min(6, limit)}, minmax(1px, 1fr))`,
  };
};
