import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useMaterialFullscreenStore } from '$/components/core/Editor/MaterialFullscreen/store/useMaterialFullscreenStore';

export const FullscreenBackLink = () => {
  const { t } = useTranslation();

  const modalType = useMaterialFullscreenStore.useModalType();
  const modalFrom = useMaterialFullscreenStore.useModalFrom();
  const modalPayload = useMaterialFullscreenStore.useModalPayload();

  const setModalType = useMaterialFullscreenStore.useSetModalType();

  const backToFavorites =
    modalType === 'favorites' &&
    modalFrom === 'favorites' &&
    modalPayload.folder;

  const back = () => {
    if (backToFavorites) return setModalType('favorites', 'favorites');
    useMaterialFullscreenStore.getState().reset();
  };

  return (
    <Button
      px='0'
      color='lighterText'
      fontSize='sm'
      leftIcon={
        <Icon icon='arrow_right' transform='rotate(180deg)' boxSize='16px' />
      }
      onClick={back}
      variant='ghost'
    >
      {backToFavorites
        ? t('general_editor.back_to_favorites')
        : t('general_editor.back_to_overview')}
    </Button>
  );
};
