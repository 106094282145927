import {
  ZustandHookSelectors,
  createSelectorHooks,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { TypedToOptions } from '$/components/core/StyledLink';
import { MaterialType } from '$/services/mapper/uses';
import { mapMaterialTypeToCategory } from '$/utils/piwikUtils';

export type PiwikDesignOption =
  | 'ColorTone'
  | 'FloorCovering'
  | 'WallCovering'
  | 'FacadeCovering';

const getDesignOrigin = (pathname: TypedToOptions): State['designOrigin'] => {
  switch (pathname) {
    case '/collections':
    case '/collections/colors':
    case '/collections/floors':
    case '/collections/walls':
      return 'Colorwall';
    case '/favorites':
      return 'Favorite';
    default:
      return null;
  }
};

export const getOriginCategory = (pathname: TypedToOptions) => {
  switch (true) {
    case pathname === '/collections':
      return 'All';
    case pathname === '/collections/colors':
      return 'ColorWall';
    case pathname === '/collections/floors':
      return 'FloorCovering';
    case pathname === '/collections/walls':
      return 'WallCovering';
    case pathname === '/favorites':
      return 'Favorite';
    case pathname?.includes('/inspirations/'):
      return 'InspirationsEditor';
    case pathname?.includes('/editor/'):
      return 'Editor';
    default:
      return null;
  }
};

type State = {
  designOrigin: 'Colorwall' | 'Favorite' | null;
  design: PiwikDesignOption | null;
  designName: string | null;
};

type Actions = {
  setDesign: (
    pathname: TypedToOptions,
    firstMaterialType: MaterialType,
    name: string,
  ) => void;
  resetDesign: () => void;
};

export const store = create<State & Actions>()((set, get) => ({
  design: null,
  designOrigin: null,
  designName: null,
  setDesign: (pathname, firstMaterialType, designName) => {
    const origin = getDesignOrigin(pathname);
    const design = mapMaterialTypeToCategory(
      firstMaterialType,
    ) as PiwikDesignOption;

    if (origin == null) {
      get().resetDesign();
      return;
    }

    set({ designOrigin: origin, design, designName });
  },
  resetDesign: () =>
    set({ design: null, designOrigin: null, designName: null }),
}));

export const usePiwikStore = createSelectorHooks(store) as typeof store &
  ZustandHookSelectors<State & Actions>;
