import { Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReadOnlyFavoriteFolderRow } from '$/components/core/Collection/Favorites/ReadOnlyFavoriteFolderRow';
import { ReadOnlyFavoriteMaterialRow } from '$/components/core/Collection/Favorites/ReadOnlyFavoriteMaterialRow';
import { useMaterialFullscreenStore } from '$/components/core/Editor/MaterialFullscreen/store/useMaterialFullscreenStore';
import { useGroupedFavorites } from '$/pages/DashboardPages/pages/Favorite/hooks/useGroupedFavorites';
import { Material } from '$/services/usecases/materials';
import { DEFAULTFOLDERNAME } from '$/stores/useFavoriteStore';

interface Props {
  onSelect: (material: Material) => void;
}

export const FullscreenFavoriteView: FC<Props> = ({ onSelect }) => {
  const { t } = useTranslation();

  const query = useMaterialFullscreenStore.useQuery();
  const setModalType = useMaterialFullscreenStore.useSetModalType();

  const {
    colorFavorites,
    floorFavorites,
    wallFavorites,
    facadeFavorites,
    isLoading,
  } = useGroupedFavorites(DEFAULTFOLDERNAME, undefined, false, query);

  return (
    <Stack gap='6'>
      <ReadOnlyFavoriteFolderRow
        onShowMoreClick={() => setModalType('favorites', 'favorites')}
        onFolderClick={(folder) =>
          setModalType('favorites', 'favorites', { folder })
        }
        label={t('general_editor.yourFolders')}
        query={query}
        includeDefaultFolder={false}
      />

      <ReadOnlyFavoriteMaterialRow
        isLoading={isLoading}
        type='uni'
        headline={t('general_editor.materialTypes.uni')}
        materials={colorFavorites.map(({ material }) => material)}
        onSelect={onSelect}
      />

      <ReadOnlyFavoriteMaterialRow
        isLoading={isLoading}
        type='wall'
        headline={t('general_editor.materialTypes.wall')}
        materials={floorFavorites.map(({ material }) => material)}
        onSelect={onSelect}
      />

      <ReadOnlyFavoriteMaterialRow
        isLoading={isLoading}
        type='floor'
        headline={t('general_editor.materialTypes.floor')}
        materials={wallFavorites.map(({ material }) => material)}
        onSelect={onSelect}
      />

      <ReadOnlyFavoriteMaterialRow
        isLoading={isLoading}
        type='facade'
        headline={t('general_editor.materialTypes.facade')}
        materials={facadeFavorites.map(({ material }) => material)}
        onSelect={onSelect}
      />
    </Stack>
  );
};
