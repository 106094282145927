import { Button, IconButton, Tooltip } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { EditorAction } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useToast } from '$/hooks/useToast';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

interface Props {
  action: EditorAction;
}

export const ActionButton: FC<Props> = ({ action }) => {
  const { t } = useTranslation();
  const userRole = useAuthenticationStore.useUserRole();
  const status = useEditorStore.useStatusObject();
  const { hasPermission } = useAuthorization();
  const openAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  const actions = useEditorActions();
  const toast = useToast();

  const workMode = useEditorStore.useWorkMode();

  const isActive =
    (action.mode != null && action.mode === workMode) ||
    action.mode?.includes(workMode);

  const isNotAllowed = action.disabledRoles?.includes(userRole);

  const handleActionBarClick = (action: EditorAction) => {
    if (!isActive) action.onClick?.(actions, { toast });
  };

  const buttonProps = {
    key: action.key,
    variant: isActive ? 'primary' : 'text',
    onClick: () =>
      isNotAllowed
        ? openAuthenticationModal('versionComparison')
        : handleActionBarClick(action),
    isDisabled: action.isDisabled?.(status, hasPermission) ?? false,
    layerStyle: 'ActionIconButton',
    'aria-label': t('editor.action', { key: action.key }),
    borderRadius: '2px',
  };

  if (isNotAllowed) {
    return (
      <Tooltip
        color='text'
        fontSize='xs'
        bg='background'
        border='1px solid'
        borderColor='border'
        borderRadius='2px'
        label={t('editor.loginForFeature')}
      >
        <IconButton
          icon={action.icon}
          {...buttonProps}
          color='textButton.disabledColor'
          opacity='30%'
        />
      </Tooltip>
    );
  }

  const buttonElement = action.label ? (
    <Button px='1' rightIcon={action.icon} {...buttonProps}>
      {action.label}
    </Button>
  ) : (
    <IconButton icon={action.icon} {...buttonProps} />
  );

  if (action.tooltipKey) {
    return (
      <Tooltip
        color='text'
        fontSize='xs'
        bg='background'
        border='1px solid'
        borderColor='border'
        borderRadius='2px'
        label={t(action.tooltipKey)}
      >
        {buttonElement}
      </Tooltip>
    );
  } else {
    return buttonElement;
  }
};
