import { HStack, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Icon } from '$/components/common/Icon';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

interface Props {
  onClick: () => void;
}

export const SelectedComponent: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  const { activeComponent } = useEditorStore(
    useShallow((s) => ({
      activeComponent: s.components.filter(
        ({ index }) => index === s.activeComponentIndex,
      )[0],
    })),
  );

  if (!activeComponent) return null;

  return (
    <Stack gap='3'>
      <Text
        color='lighterText'
        fontSize='sm'
        fontWeight='bold'
        lineHeight='16px'
      >
        {t('general_editor.selectedComponent')}
      </Text>
      <HStack
        justify='space-between'
        my='2'
        px='4'
        py='2'
        bg='background'
        border='1px solid'
        borderColor='border'
        borderRadius='8px'
        cursor='pointer'
        onClick={onClick}
      >
        <Text fontSize='sm' fontWeight='bold'>
          {activeComponent.name}
        </Text>
        <Icon flex='flex-end' w='12px' icon='arrow_right' />
      </HStack>
    </Stack>
  );
};
