import { Box, HStack } from '@chakra-ui/react';
import { FC } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { Icon } from '$/components/common/Icon';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';
import { ProfileData } from '$/services/usecases/profile';
import { TrackedProfileInputField } from '$/utils/piwikUtils';
import { isSafari } from '$/utils/userAgent';

export type ProfileFields = HookFormInputProps<ProfileData>['accessor'];

const ProfileInput = (props: HookFormInputProps<ProfileData>) => (
  <HookFormInput<ProfileData> {...props} />
);

interface Props {
  inEditMode: boolean;
  value: string;
  accessor: ProfileFields;
  setActive: (accessor: ProfileFields | null) => void;
  locked: boolean;
  label: string;
  isLoading?: boolean;
  registerOptions?: RegisterOptions<ProfileData>;
  trackedProfileInputField: TrackedProfileInputField;
}

export const ProfileInputField: FC<Props> = ({
  inEditMode,
  value,
  accessor,
  setActive,
  locked,
  label,
  registerOptions,
  isLoading = false,
  trackedProfileInputField,
}) => {
  const { t } = useTranslation();

  const formMethods = useFormContext();

  return (
    <ProfileInput
      ignoreBlur={inEditMode}
      showDefaultBorder={!inEditMode}
      accessor={accessor}
      isReadOnly={!inEditMode}
      label={label}
      defaultValue={value}
      registerOptions={
        registerOptions ?? {
          required: t('dashboard.authentication.errorMessages.isRequired'),
        }
      }
      inputRightElement={
        <HStack gap='0'>
          {!isLoading && !inEditMode && (
            <TrackedButton
              px='0'
              contentName='CloudAccount'
              contentPiece='ManageAccount'
              contentTarget={trackedProfileInputField}
              isLoading={isLoading}
              onClick={() => {
                if (locked) return;

                setActive(accessor);
                if (!isSafari()) formMethods.setFocus(accessor);
                formMethods.setValue(accessor, value);
              }}
              variant='text'
            >
              <Icon icon='edit_pen' />
            </TrackedButton>
          )}

          {inEditMode && (
            <Box>
              {!isLoading && (
                <TrackedButton
                  px='0'
                  isLoading={isLoading}
                  contentName='CloudAccount'
                  contentPiece={trackedProfileInputField}
                  contentTarget='Cancel'
                  onClick={() => {
                    setActive(null);
                    formMethods.setFocus(accessor);
                    formMethods.setValue(accessor, value);
                  }}
                  variant='text'
                >
                  <Icon icon='cancel' />
                </TrackedButton>
              )}
              <TrackedButton
                px='0'
                isLoading={isLoading}
                type='submit'
                variant='text'
                contentName='CloudAccount'
                contentPiece={trackedProfileInputField}
                contentTarget='Submit'
              >
                <Icon icon='submit' />
              </TrackedButton>
            </Box>
          )}
        </HStack>
      }
    />
  );
};
